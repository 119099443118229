<template>
  <div v-if="smartyStreetsWebsiteKey">
    <smarty-streets
      :vuetifyProps="{ disabled }"
      :required="required"
      :websiteKey="smartyStreetsWebsiteKey"
      :showCounties="showCounties"
      :smartyStreetsConfig="smartyConfig"
      :uppercase="uppercase"
      :counties="showCounties ? counties : {}"
      :readonly="readonly"
      :verifyCounty="verifyCounty"
      :showVerifyErrors="showVerifyErrors"
      :states="states"
      :street="addressObj.address1"
      :useVerify="useVerify"
      @streetInput="addressObj.address1 = $event"
      :street2="addressObj.address2"
      @street2Input="addressObj.address2 = $event"
      :city="addressObj.city"
      @cityInput="addressObj.city = $event"
      :state="addressObj.state"
      @stateInput="addressObj.state = $event"
      :zipCode="addressObj.postalCode"
      @zipCodeInput="addressObj.postalCode = $event"
      :countyId="addressObj.countyID"
      @countyIdInput="addressObj.countyID = $event"
    >
    </smarty-streets>
  </div>
</template>

<script>
import smartyStreets from "@web-components/smarty-streets";
import { mapGetters } from "vuex";
import { states } from "@/assets/js/constants";

export default {
  components: { "smarty-streets": smartyStreets },
  props: {
    uppercase: { type: Boolean, default: false },
    showStreet2: { type: Boolean, default: true },
    verifyCounty: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    showVerifyErrors: { type: Boolean, default: false },
    useVerify: { type: Boolean, default: true },
    addressObj: { type: Object, required: true },
    showCounties: { type: Boolean, default: false },
    lookup: { type: Object, default: undefined },
    required: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters({
      counties: "counties",
      smartyStreetsWebsiteKey: "smartyStreetsWebsiteKey"
    }),
    smartyConfig() {
      return this.lookup
        ? this.lookup
        : this.configPromise
        ? this.configPromise.smartyConfig
        : {};
    }
  },
  data() {
    return {
      configPromise: {},
      states: states
    };
  },
  async beforeCreate() {
    this.configPromise = await this.$store.dispatch("setConfig");
  }
};
</script>

<style scoped lang="scss">
::v-deep input:hover {
  cursor: default;
}
::v-deep .row {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

::v-deep .col {
  padding: 1px;
}

::v-deep .street {
  flex-grow: 1;
  flex-basis: 15%;
}

::v-deep .city {
  flex-grow: 1;
  flex-basis: 10%;
}
::v-deep .state {
  flex-grow: 1;
  flex-basis: 9%;
}
::v-deep .zip {
  flex-grow: 1;
  flex-basis: 10%;
}

::v-deep .county {
  flex-grow: 1;
  flex-basis: 5%;
  max-width: 200px;
}
</style>
