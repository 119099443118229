<template>
  <div id="prevTrans">
    <div class="header">
      <h2>
        <v-icon color="primary" class="icon">mdi-image-off-outline</v-icon>
        Missing Images
      </h2>
    </div>
    <div class="table">
      <MissingImages :results="results" />
    </div>
  </div>
</template>

<script>
import MissingImages from "@/components/nonPageComponents/missingImagesTable";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      invoiceNo: this.$store.getters.invoiceNo,
      results: null
    };
  },
  components: {
    MissingImages
  },
  computed: {
    ...mapGetters({
      currentInvoiceNo: "invoiceNo"
    })
  },
  async mounted() {
    this.results = await this.$api.missingDocuments(this.invoiceNo, {
      404: () => {}
    });
  }
};
</script>

<style lang="scss" scoped>
$header-height: 45px;
#prevTrans {
  height: calc(100% - #{$header-height});
}

.header {
  height: $header-height;
}

.table {
  height: 100%;
}
.bootstrap {
  height: calc(100% - 15px);
}

::v-deep .bootstrap .b-table-sticky-header {
  max-height: 100%;
}
.icon {
  font-size: 48px !important;
}
</style>
