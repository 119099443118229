<template>
  <div class="navigation">
    <div v-for="(links, index) in linksList" :key="index">
      <h2>{{ links.header }}</h2>
      <ul>
        <li
          v-for="(link, index) in links.options"
          :key="index"
          @click="window.open(link.link, link.target)"
        >
          {{ link.disp }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      window: window
    };
  },
  props: {
    linksList: {}
  }
};
</script>
<style scoped lang="scss">
h2 {
  background-color: #1976d2;
  padding: 5px;
  color: var(--primary) !important;
  border-radius: 5px;
  margin: 0px;
}
</style>
