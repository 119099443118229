<template>
  <div>
    <h2>
      <v-icon color="primary" size="48px">mdi-delta</v-icon>
      I Want To...
    </h2>
    <div>
      <ul>
        <li @click="newPlacard">New Placard</li>
        <v-menu :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on }">
            <li v-on="on">Renew Placard</li>
          </template>
          <v-list>
            <v-list-item>
              <v-text-field
                clearable
                v-model="placard"
                autofocus
                @keyup.enter="renewPlacard"
                label="Placard #"
              >
                <template slot="append">
                  <v-icon @click="renewPlacard">mdi-magnify</v-icon>
                </template>
              </v-text-field>
            </v-list-item>
          </v-list>
        </v-menu>
        <li @click="newTitleandRegistration">New Title and Registration</li>
      </ul>
    </div>
  </div>
</template>
<script>
import vehicleFunctions from "@/mixins/vehicleFunctions.js";
import searchFunctions from "@/mixins/searchFunctions.js";
import newPlacardScaffold from "@/assets/jsonScaffolds/newPlacardScaffold.js";

export default {
  data() {
    return {
      placard: ""
    };
  },
  mixins: [vehicleFunctions, searchFunctions],
  methods: {
    async renewPlacard() {
      this.$store.commit("transactionType", "Placard Renewal");
      this.doPlacardInquiry();
    },
    newPlacard() {
      this.$store.commit("transactionType", "New Placard");
      const transScaffold = newPlacardScaffold();
      this.$store.commit(
        "processObj",
        JSON.parse(JSON.stringify(transScaffold))
      );
      this.$store.commit("resultsArray", null);
      this.$router.push({ name: "NewDirectTransactionChange" });
    },
    newTitleandRegistration() {
      this.addVehicle("Title And Registration");
      this.$store.commit("simpleSearch", "");
      this.$store.commit("isAdvancedSearch", false);
    }
  }
};
</script>
