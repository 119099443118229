export default function () {
  return {
    lienID: 0,
    vehicleID: 0,
    lienHolderID: 0,
    lienDate: null,
    dischargeDate: null,
    dischargeNo: null,
    dischargeReasonCode: null,
    lienPrecedenceNo: null,
    lastUpdateUserID: 0,
    lienholder: null,
    isRemoved: false
  };
}
