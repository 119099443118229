export default function () {
  return {
    transactionID: 0,
    transactionTypeID: 0,
    vehicleID: null,
    customerID: null,
    issueYearID: null,
    serviceMediumCode: null,
    transactionTimestamp: "0001-01-01T00:00:00Z",
    fleetID: null,
    statusCode: "",
    statusDate: null,
    recordedDate: null,
    printTitleInd: null,
    instantPrintInd: null,
    printFleetCardInd: null,
    printReceiptQty: 0,
    countyID: 0,
    countyTransactionID: null,
    invoiceNo: null,
    registrationOnlyNo: null,
    cityID: null,
    workUnitID: null,
    overrideInd: null,
    imageConnectedInd: null,
    portalConfirmationNo: null,
    lastUpdateDate: null,
    lastUpdateUserID: 0,
    transactionType: "",
    comments: [],
    isVerify: false,
    fees: null,
    isRebuild: false,
    newestTransactionID: 0,
    newestTransactionStatus: "",
    ignoreValidations: false
  };
}
