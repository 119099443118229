import _ from "lodash";

import store from "../../store";

let badWordList;

async function redactSensitiveData(input) {
  if (!badWordList) {
    if (store.state.BaseInfo.badWordList.length > 0) {
      badWordList = store.state.BaseInfo.badWordList.map(
        badWord => new RegExp(badWord, "i")
      );
    }
  }

  if (!badWordList) {
    return { loggingError: "NO BAD WORD LIST FOUND" };
  }

  if (typeof input === "object" && input !== null) {
    for (const key in input) {
      input[key] = badWordList.some(badWord => badWord.test(key))
        ? `[REDACTED ${input[key] ? input[key].length : 0}]`
        : await redactSensitiveData(input[key]);
    }
  }
  return input;
}

export async function createLogMessage(data) {
  let message = [
    "Fetch:\nResource:",
    `${data.config.baseURL}${data.config.url}`,
    "\nMethod:",
    data.config.method
  ];

  if (data.status) {
    message = message.concat(["\nStatus:", data.status]);
  }

  let requestBody = _.cloneDeep(data.config.data);
  const responseBody = await redactSensitiveData(_.cloneDeep(data.data));

  if (typeof requestBody === "string" && requestBody !== "") {
    requestBody = JSON.parse(requestBody);
    requestBody = await redactSensitiveData(requestBody).catch(() => {});
  }
  if (typeof requestBody === "object" && requestBody !== null) {
    delete requestBody.documentLocator;
    delete requestBody.document;
  }
  if (typeof responseBody === "object" && responseBody !== null) {
    delete responseBody.documentLocator;
    delete responseBody.document;
  }
  message = message.concat([
    "\nrequestBody:",
    JSON.stringify(requestBody, null, 2),
    "\nresponseBody:",
    JSON.stringify(responseBody, null, 2)
  ]);
  return message;
}
