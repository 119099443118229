<template>
  <div class="bootstrap">
    <b-table
      :sticky-header="tableSize"
      :no-border-collapse="true"
      :fields="tableFields"
      :show-empty="true"
      head-variant="light"
      sort-icon-left
      responsive="sm"
      selectable
      select-mode="single"
      hover
      @row-clicked="item => loadDetails(item)"
      :items="results"
    >
      <template v-slot:cell(transactionTypeCode)="data">
        {{
          transactionTypes[data.item.transactionTypeCode] ||
          data.item.transactionTypeCode
        }}
      </template>
      <template v-slot:cell(transactionDate)="data">
        {{ new Date(data.item.transactionDate).toLocaleString() }}
      </template>
    </b-table>
  </div>
</template>

<script>
const transactionTypes = require("@/assets/jsonScaffolds/transactionTypes.json");
import searchFunctions from "@/mixins/searchFunctions.js";
export default {
  mixins: [searchFunctions],
  props: {
    tableSize: {
      type: String,
      default: () => {
        return "";
      }
    },
    results: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      vin: "",
      placard: "",
      advancedSearch: true,
      transactionTypes: transactionTypes,
      tableFields: [
        {
          key: "transactionTypeCode",
          label: "Transaction",
          sortable: true
        },
        {
          key: "plateNo",
          label: "Plate"
        },
        {
          key: "plateClassCode",
          label: "Plate Class",
          sortable: true
        },
        {
          key: "vin"
        },
        {
          key: "makeCode",
          label: "Make",
          sortable: true
        },
        {
          key: "modelCode",
          label: "Model",
          sortable: true
        },
        {
          key: "modelYear",
          label: "Year",
          sortable: true
        },
        {
          key: "transactionDate",
          sortable: true
        }
      ]
    };
  },
  methods: {
    loadDetails(item) {
      try {
        if (![undefined, "", null].includes(item.vin)) {
          this.vin = item.vin;
          this.$store.commit("vin", item.vin);
          this.doInquiry();
        } else if (
          this._.has(JSON.parse(item.transactionJSON), "placard.controlNo")
        ) {
          this.placard = JSON.parse(item.transactionJSON).placard.controlNo;
          this.doPlacardInquiry();
        }
      } catch (e) {
        console.error(e);
        this.$root.$emit("push-alert", "Could not load transaction", {
          color: "error"
        });
      }
    }
  }
};
</script>
