const templates = require("@/assets/jsonScaffolds/DefaultCSSTemplates.json");

export default {
  data() {
    return {
      colorTemplates: JSON.parse(JSON.stringify(templates))
    };
  },
  methods: {
    //the customCSS can be passed by reference and edited here
    applyCustomCSS(customCSS) {
      let colorObj = {};
      if (customCSS["templateName"] != undefined) {
        if (customCSS["templateName"] == "Custom") {
          colorObj = JSON.parse(JSON.stringify(customCSS["colors"]));
        } else {
          colorObj = JSON.parse(
            JSON.stringify(
              this.colorTemplates[customCSS["templateName"]].scheme
            )
          );
        }
      } else {
        //if nothing is set here then default
        colorObj = JSON.parse(
          JSON.stringify(this.colorTemplates["Default"].scheme)
        );
      }

      if (this._.has(customCSS, "invert") && customCSS.invert) {
        colorObj.forEach(function (color, key) {
          if (
            [
              "--header-color",
              "--borders-backgrounds",
              "--primary",
              "--text"
            ].includes(color["varName"])
          ) {
            let value = color["value"];
            if (typeof value == "object") {
              value = value["hex8"];
            }
            const r = value.substring(1, 3);
            const g = value.substring(3, 5);
            const b = value.substring(5, 7);

            colorObj[key]["value"] = "#" + r + g + b;
          }
        });
      }

      this.$nextTick(() => {
        colorObj.forEach(function (colorObj, index) {
          let value = colorObj["value"];
          if (typeof value == "object") {
            //if color picker object
            value = value["hex8"];
          }
          if (customCSS["colors"][index] != undefined) {
            customCSS["colors"][index]["value"] = value;
          }
          document.documentElement.style.setProperty(
            colorObj["varName"],
            value
          ); // todo> remove instances of direct dom manipluation like this in this file
        });

        customCSS["fonts"]["fontSizes"].forEach(function (colorObj) {
          let value = colorObj["value"];
          if (/^[0-9]+$/.test(value)) {
            //if this is a font
            value = value + "px";
          }

          document.documentElement.style.setProperty(
            colorObj["varName"],
            value
          );
        });

        const fontFamily = customCSS["fonts"]["fontFamily"]["value"];
        const fontVarName = customCSS["fonts"]["fontFamily"]["varName"];
        document.documentElement.style.setProperty(fontVarName, fontFamily);
      });
    },
    undo(option, callback) {
      this.customColors = JSON.parse(
        JSON.stringify(this.$store.getters.oldCustomCSS[option])
      );
      callback();
    },
    resetToDefault(option, callback) {
      const emptyConfig = require("@/assets/jsonScaffolds/MVConfigurationScaffold.json");

      this.customColors = JSON.parse(
        JSON.stringify(emptyConfig.customCSS[option])
      );
      callback();
    }
  }
};
