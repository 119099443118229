/*global _*/
import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import starInitialize from "@/mixins/starInitialize.mixin.js";
import { version } from "../../package.json";

Vue.use(Router);

const router = new Router(require("@/router/config.js").default);

// route error checking
for (let i = 0; i < router.options.routes.length; i++) {
  const route = router.options.routes[i];

  if (
    !_.has(route, "meta") ||
    !_.has(route.meta, "permission") ||
    !Array.isArray(route.meta.permission)
  ) {
    const errorMessage =
      "The route " +
      route.path +
      " is missing the required property meta.permission.";
    console.error(errorMessage);
    if (window.location.hostname === "localhost") {
      alert(errorMessage);
    }
  }
}

router.beforeEach(async (to, from, next) => {
  let loginInfo = localStorage.getItem("loginInfo");
  if (loginInfo != undefined) {
    loginInfo = JSON.parse(loginInfo);
    store.commit("countyId", loginInfo.countyId);
    store.commit("officeId", loginInfo.officeId);
    store.commit("siteNo", loginInfo.siteNo);
    store.commit("drawerNo", loginInfo.drawerNo);
    store.commit("workstationId", loginInfo.workstationId);
    store.commit("invoiceNo", loginInfo.invoiceNo);
    if (Object.prototype.hasOwnProperty.call(loginInfo, "featureFlags")) {
      store.dispatch("setFeatureFlags", loginInfo.featureFlags);
    }
    if (Object.prototype.hasOwnProperty.call(loginInfo, "countyConfig")) {
      store.dispatch("mergeCountyConfig", loginInfo.countyConfig);
    }
    if (loginInfo.officeConfig)
      store.dispatch("mergeOfficeConfig", loginInfo.officeConfig);
  }

  if (to.name !== "Login") {
    const tokenFromLocalStorage = localStorage.getItem("starApiToken");
    if (tokenFromLocalStorage != undefined) {
      //setting from localstorage on all routes in case we lose it for some reason
      store.dispatch("setApiToken", tokenFromLocalStorage);
    }
    const tokenTimeLeft =
      store.getters.tokenExpiration - Math.round(new Date().getTime());
    //token is expiring soon (1hr) so refresh it
    if (
      !["", undefined].includes(store.getters.apiHostAndPort) &&
      !["", undefined].includes(tokenFromLocalStorage) &&
      to.name !== "Login" &&
      tokenTimeLeft <= 36 * 100 * 1000
    ) {
      try {
        const newToken = await Vue.prototype.$api.refreshAuth();
        store.dispatch("setApiToken", newToken.token);
        localStorage.setItem("starApiToken", newToken.token);
      } catch (e) {
        console.error(e);
        store.dispatch("setApiToken", "");
        localStorage.removeItem("starApiToken");
        next({ name: "Login" });
        return;
      }
    }
    if (
      tokenFromLocalStorage != undefined &&
      ["", undefined].includes(store.getters.userObject.id)
    ) {
      try {
        await store.dispatch("setConfig");
        await starInitialize.methods.doStarInit(tokenFromLocalStorage, store);
      } catch (error) {
        console.error(error);
        store.dispatch("setApiToken", "");
        localStorage.removeItem("starApiToken");
        next({ name: "Login" });
        return;
      }
    } else if (["", undefined].includes(store.getters.apiToken)) {
      if (to.name !== "Login" && to.name !== "devTests") {
        next({ name: "Login" });
        return;
      }
    }
  }

  store.commit("showAdvancedSearch", false);

  if (!store.getters.updateNotification && Vue.prototype.$api) {
    Vue.prototype.$api.getVersion().then(externalVersion => {
      if (
        version !== externalVersion &&
        process.env.NODE_ENV !== "development"
      ) {
        store.commit("updateNotification", true);
      }
    });
  }
  next();
});

export default router;
