export default function () {
  return {
    vehicleID: 0,
    vin: "",
    vinActiveFlag: 0,
    forcedVINInd: false,
    vinLast8: null,
    vinVerifiedInd: false,
    ncicCheckInd: 0,
    modelYear: 0,
    makeCode: "",
    modelCode: "",
    bodyTypeCode: "",
    allTitleBrands: "",
    titanBodyCode: "",
    majorColorCode: "",
    minorColorCode: null,
    fuelTypeCode: "",
    vehicleTypeCode: "T",
    unladenedWeight: null,
    grossVehicleWeight: null,
    declaredVehicleWeight: null,
    axleCount: 0,
    weightClassSeatCode: null,
    leasedInd: false,
    newUsedCode: "U",
    lastUpdateDate: null,
    lastUpdateUserID: 0,
    stops: null,
    overrideStops: false,
    vehicleDetailID: 0,
    vehicleUseCode: "P",
    vehicleLength: null,
    vehicleWidth: null,
    usdotNo: null,
    damageReasonCode: null,
    regstForHireInd: 0,
    vinaOverrideInd: 0,
    irpNo: 0,
    emergencySaleInd: 0,
    replicaVehicleInd: 0,
    hovInd: 0,
    hovControlNo: null,
    tdrControlNo: null,
    companyVehicleNo: null,
    odometerID: 0,
    odometerTypeCode: "0",
    odometerReading: "",
    titleID: null,
    odometerExemptionReasonCode: null,
    insurance: null,
    newVIN: null
  };
}
