/* This class provides an instance of an object that can be reduced to it's base "model". The model is simply a set of keys that represent the base model of the object. When a Reducable is constructed, it is passed an initial object that represents the initial model to be used. Keys can be added or removed from the model as necessary. The primary use case for this class is for a data object that will be returned to an api so that the frontend can add meta data to the object for it's own purposes that can then easily be stripped before returning the data object in a request.*/
import { has } from "lodash";
export class Reducable {
  constructor(model) {
    this.modelKeys = Object.keys(model);
    Object.assign(this, model);
  }

  // returns a copy of the object's model
  getReduced() {
    const reduced = {};

    for (let i = 0; i < this.modelKeys.length; i++) {
      const key = this.modelKeys[i];
      if (has(this, key)) {
        reduced[key] = this[key];
      }
    }

    return reduced;
  }

  // adds a key to the model
  modelAdd(key, value) {
    this[key] = value;
    this.modelKeys.push(key);
  }

  // removes a key from the model
  modelRemove(key) {
    const index = this.modelKeys.indexOf(key);

    if (index > -1) this.modelKeys.splice(index, 1);
    delete this.key;
  }

  // provides a deep copy of the Reducable instance
  clone() {
    let clone = Object.create(Object.getPrototypeOf(this));
    clone = this._.cloneDeep(this);
    return clone;
  }
}
