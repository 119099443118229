var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"lock"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){_vm.locked = !_vm.locked}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.locked ? "lock" : "mdi-lock-open-outline")+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.locked ? "Edit Dashboard Items" : "Lock Dashboard"))])])],1),(_vm.localWidgets.length > 0)?_c('grid-layout',{attrs:{"layout":_vm.localWidgets,"col-num":20,"row-height":30,"autoSize":false,"is-resizable":!_vm.locked,"is-mirrored":false,"responsive":false,"vertical-compact":true,"use-css-transforms":true,"is-draggable":!_vm.locked},on:{"update:layout":function($event){_vm.localWidgets=$event}}},_vm._l((_vm.localWidgets),function(item,index){return _c('grid-item',{key:index,staticClass:"panel ma-0",attrs:{"static":item.islocked,"x":item.x,"y":item.y,"w":item.w,"h":item.h,"i":item.i,"dragIgnoreFrom":'ul, i'},on:{"moved":function (newX, newY) { return _vm.movedEvent(item, newX, newY); },"resized":function (newH, newW) { return _vm.resizedEvent(item, newH, newW); }}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.locked),expression:"!locked"}],staticClass:"widget-close"},[_c('v-btn',{attrs:{"fab":"","small":"","color":"white","elevation":"0"},on:{"click":function($event){return _vm.removeWidget(index)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"widget-content"},[_vm._l((_vm.widgetTypesList),function(comp,index){return [(item.type == comp.type)?_c(comp.type,{key:index,tag:"component",attrs:{"linksList":_vm.linksList}}):_vm._e()]})],2)])}),1):_vm._e(),(!_vm.locked)?_c('div',{staticClass:"reset"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","small":"","color":"error"},on:{"click":_vm.reloadDashboardDefaults}},on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-delete-sweep")])],1)]}}],null,false,435335271)},[_c('span',[_vm._v(" Reset dashboard back to default layout")])])],1):_vm._e(),(!_vm.locked)?_c('div',{staticClass:"add-widget"},[_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","light":"","small":"","color":"primary"}},Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v("Add a new widget to the screen")])])]}}],null,false,2166841876)},[_c('v-list',_vm._l((_vm.widgetTypesList),function(widget,index){return _c('v-list-item',{key:index,staticClass:"select-menu",on:{"click":function($event){return _vm.addWidget(widget)}}},[_c('v-list-item-title',[_vm._v(_vm._s(widget.type))])],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }