import plate from "./plate";

export default function () {
  return {
    registrationID: 0,
    vehicleID: 0,
    countyID: 0,
    registrationTypeCode: "",
    emissionsTestInd: false,
    estimatedVehicleWorth: null,
    fleetCertificateNo: null,
    fleetPrimaryDomicileCountyID: null,
    registrationBeginDate: null,
    registrationExpiresDate: null,
    cityWheelTaxInd: false,
    wheelTaxCityID: null,
    operatingZone: null,
    outOfStateCode: null,
    statusCode: "",
    statusDate: null,
    undercoverInd: 0,
    blindPlateInd: false,
    renewalNoteDate: null,
    exemptCertificate: null,
    transferredFrom: null,
    registrationYears: 0,
    lastUpdateUserID: 0,
    newPlate: plate(),
    plateTransfer: false,
    currentMake: "",
    currentYear: 0,
    isSurvivingSpouse: false,
    wheelTaxTypeCode: null,
    firefighterInd: false,
    rescueInd: false,
    isLostStolen: false,
    isReregistration: false
  };
}
