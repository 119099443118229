const state = {
  configPromise: null,
  config: {
    apiUrl: {
      host: "",
      port: "",
      basePath: ""
    },
    monetraUrl: {
      host: "",
      port: "",
      basePath: ""
    },
    hubDownloadBaseLocation: "",
    paymentFrameHost: "",
    smartyStreetsWebsiteKey: "",
    dynamsoftKey: "",
    dynamsoftBarcodeKey: ""
  },
  badWordList: [],
  logRequests: true,
  smartyConfig: {
    maxSuggestions: 10,
    prefer: [],
    preferRatio: 1,
    geolocate: true
  }
};

const getters = {
  apiUrl: state => {
    return state.config.apiUrl.host;
  },
  apiHostAndPort: state => {
    let api = state.config.apiUrl.host;
    if (
      state.config.apiUrl.port != undefined &&
      state.config.apiUrl.port != ""
    ) {
      api = api + ":" + state.config.apiUrl.port;
    }
    return api;
  },
  smartyStreetsWebsiteKey: state => state.config.smartyStreetsWebsiteKey,
  hubDownloadBaseLocation: state => state.config.hubDownloadBaseLocation
};

const mutations = {
  SET_CONFIG(state, n) {
    Object.assign(state, n);
  },
  apiUrl(state, n) {
    state.config.apiUrl = n;
  },
  monetraUrl(state, n) {
    state.config.monetraUrl = n;
  }
};

const actions = {
  async setConfig({ getters, commit, state }) {
    if (getters.apiUrl !== "") {
      return;
    }

    if (state.configPromise !== null) return state.configPromise;

    let resolve;
    state.configPromise = new Promise(r => (resolve = r));

    const config = await this._vm.$api.getConfig();
    commit("SET_CONFIG", config);
    resolve();
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
