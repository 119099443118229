import { Reducable } from "@/classes/Reducable";
import { Transaction } from "@/classes/Transaction";
import { has } from "lodash";

// Transaction Request (specific to STAR)

export class TransactionRequest extends Reducable {
  constructor(request) {
    super(request);

    this.transaction = new Transaction(this.transaction);
  }

  getReduced() {
    const reduced = super.getReduced();

    if (has(reduced, "transaction")) {
      reduced.transaction = reduced.transaction.getReduced();
    }

    return reduced;
  }
}
