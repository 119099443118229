import { Reducable } from "@/classes/Reducable";
import { Fee } from "@/classes/Fee";
import { has } from "lodash";

export class Transaction extends Reducable {
  constructor(transaction) {
    super(transaction);

    if (has(this, "fees")) {
      for (let i = 0; i < this.fees.length; i++) {
        this.fees[i] = new Fee(this.fees[i]);
      }
    }
    this.underCoverFees = [];
  }

  convertToUndercover() {
    const tempFees = [];
    for (let i = 0; i < this.fees.length; i++) {
      if (this.fees[i].feeGroup != "Undercover" && !this.fees[i].isOptional) {
        this.underCoverFees.push(this.fees[i]);
      } else {
        tempFees.push(this.fees[i]);
      }
    }
    this.fees = tempFees;
  }

  getUndercoverPrintFees() {
    return this.underCoverFees;
  }

  getReduced() {
    const reduced = super.getReduced();

    if (has(reduced, "fees")) {
      for (let i = 0; i < reduced.fees.length; i++) {
        reduced.fees[i] = reduced.fees[i].getReduced();
      }
    }

    return reduced;
  }
}
