<template>
  <div v-if="isFeatureEnabled('mvDashboard')" id="dashboard">
    <div class="main-content">
      <widgetWrapper @widget="widgetChange" :widgets="widgets"></widgetWrapper>
    </div>

    <v-snackbar
      v-model="showSnack"
      :bottom="true"
      color=""
      :center="true"
      :timeout="3000"
    >
      <div class="snackDiv">
        <div>
          <h2>Success!</h2>
          <div>Your new dashboard layout was saved.</div>
        </div>
      </div>
    </v-snackbar>

    <v-snackbar
      v-model="invoiceDiff"
      :bottom="true"
      color="info"
      :center="true"
      :timeout="6000"
    >
      {{ invoiceDifferenceMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import widgetWrapper from "@/components/nonPageComponents/widgetWrapper";
import { mapGetters } from "vuex";
import transaction from "@/mixins/transaction.mixin.js";

const defaultActionMessage =
  "You're not using todays invoice. If you wish to change, logout and update invoice.";

export default {
  mixins: [transaction],
  data() {
    return {
      showSnack: false,
      invoiceDiff: false,
      invoiceDifferenceMessage: defaultActionMessage
    };
  },
  computed: {
    ...mapGetters({
      widgets: "dashboardSettings",
      isFeatureEnabled: "isFeatureEnabled"
    })
  },
  components: {
    widgetWrapper
  },
  methods: {
    widgetChange(items) {
       
     this.$store.commit("dashboardSettings", items);
      this.$api
        .postDashboardSettings(
          { dash:this.widgets },
         this.$store.getters.userObject.id
        )
        .then(() => {
         this.showSnack = true;
          //this call is being used to set the cache with the newly saved data, if this isnt done, if the user refreshes it will load the previous
          //cache data and not the new data that they saved.
          this.$api.dashboardSettings(
           this.$store.getters.userObject.id,
            false,

            { 404: () => {} }
          );
        });
    }
  },
  created() {
    if ([null, undefined, ""].includes(this.$store.getters.todaysInvoiceNo)) {
      this.$store.commit("todaysInvoiceNo", this.getTodaysInvoice());
    }
    if (this.$store.getters.todaysInvoiceNo !== this.$store.getters.invoiceNo) {
      this.invoiceDiff = true;
    }
    if (
      !this.isFeatureEnabled("mvDashboard") &&
      this.isFeatureEnabled("inDashboard")
    )
      this.$router.replace({ name: "InventoryDashboard" });
  }
};
</script>

<style scoped lang="scss">
.main-content {
  margin-top: 10px;
  margin-right: 25px;
}

.vue-grid-item {
  cursor: move;
}

::v-deep ul {
  list-style-type: none;
  padding-left: 20px;
}

::v-deep ul li {
  padding: 5px 5px 5px 0px;
  border-bottom: 1px solid var(--borders-backgrounds);
  cursor: pointer;
}

::v-deep .snackDiv {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

::v-deep .snackDiv h2 {
  color: white !important;
  font-size: 16pt;
  width: auto;
  margin: 5px;
}

::v-deep .snackDiv div {
  font-size: 12pt;
  text-align: center;
}

.chart {
  width: 400px;
}

.vue-grid-item h2 {
  margin: 0;
}

.vue-grid-item h2 i {
  margin-bottom: 20px;
}
</style>
