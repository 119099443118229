import customer from "../js/defaultTransactionRequestObjects/customer";
import transaction from "../js/defaultTransactionRequestObjects/transaction";

export default function () {
  return {
    transaction: transaction(),
    owners: [customer()],
    placard: {
      placardTypeCode: "",
      issueYear: 0,
      controlNo: "",
      customerID: 0,
      placardQualificationID: 0,
      beginDate: "0001-01-01T00:00:00Z",
      endDate: "0001-01-01T00:00:00Z",
      statusCode: null,
      statusDate: null,
      lastUpdateDate: "0001-01-01T00:00:00Z",
      lastUpdateUserID: 0,
      placardClass: "",
      oldControlNo: ""
    }
  };
}
