<template>
  <div>
    <div class="lock">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon @click="locked = !locked" v-bind="attrs" v-on="on">
            {{ locked ? "lock" : "mdi-lock-open-outline" }}
          </v-icon>
        </template>
        <span> {{ locked ? "Edit Dashboard Items" : "Lock Dashboard" }}</span>
      </v-tooltip>
    </div>
    <grid-layout
      v-if="localWidgets.length > 0"
      :layout.sync="localWidgets"
      :col-num="20"
      :row-height="30"
      :autoSize="false"
      :is-resizable="!locked"
      :is-mirrored="false"
      :responsive="false"
      :vertical-compact="true"
      :use-css-transforms="true"
      :is-draggable="!locked"
    >
      <grid-item
        v-for="(item, index) in localWidgets"
        :static="item.islocked"
        :key="index"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        @moved="(newX, newY) => movedEvent(item, newX, newY)"
        @resized="(newH, newW) => resizedEvent(item, newH, newW)"
        class="panel ma-0"
        :dragIgnoreFrom="'ul, i'"
      >
        <div class="widget-close" v-show="!locked">
          <v-btn
            fab
            small
            color="white"
            elevation="0"
            @click="removeWidget(index)"
          >
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="widget-content">
          <template v-for="(comp, index) in widgetTypesList">
            <component
              v-if="item.type == comp.type"
              :linksList="linksList"
              :key="index"
              :is="comp.type"
            />
          </template>
        </div>
      </grid-item>
    </grid-layout>
    <div v-if="!locked" class="reset">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            fab
            small
            color="error"
            v-on="on"
            @click="reloadDashboardDefaults"
          >
            <v-icon color="black">mdi-delete-sweep</v-icon></v-btn
          >
        </template>
        <span> Reset dashboard back to default layout</span>
      </v-tooltip>
    </div>
    <div v-if="!locked" class="add-widget">
      <v-menu top>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                class="mx-2"
                fab
                light
                small
                color="primary"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon dark>add</v-icon>
              </v-btn>
            </template>
            <span>Add a new widget to the screen</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item
            class="select-menu"
            v-for="(widget, index) in widgetTypesList"
            @click="addWidget(widget)"
            :key="index"
          >
            <v-list-item-title>{{ widget.type }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import { dashBoardDefault } from "@/assets/js/constants";
import VueGridLayout from "vue-grid-layout";
import Bookmarks from "@/components/nonPageComponents/widgetBookmarks";
import Actions from "@/components/nonPageComponents/widgetActions";
import Navigation from "@/components/nonPageComponents/widgetNavigation";
import Drafts from "@/components/nonPageComponents/widgetDrafts";
import Previous from "@/components/nonPageComponents/widgetPrevTrans";
import MissingImages from "@/components/nonPageComponents/widgetMissingImages";
export default {
  props: {
    widgets: {}
  },
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Bookmarks,
    Actions,
    Navigation,
    Drafts,
    Previous,
    MissingImages
  },
  data() {
    return {
      localWidgets: JSON.parse(JSON.stringify(this.widgets)),
      locked: true,
      responsive: true,
      prevLimit: 10,
      widgetTypesList: [
        {
          type: "Bookmarks",
          defaultW: 7,
          defaultH: 7
        },
        {
          type: "Actions",
          defaultW: 7,
          defaultH: 7
        },
        {
          type: "Navigation",
          defaultW: 5,
          defaultH: 14
        },
        {
          type: "Drafts",
          defaultW: 14,
          defaultH: 7
        },
        {
          type: "Previous",
          defaultW: 14,
          defaultH: 7
        },
        {
          type: "MissingImages",
          defaultW: 14,
          defaultH: 7
        }
      ],
      linksList: [
        {
          header: "Quick Links",
          options: [
            {
              disp: "T&R Reference Guide",
              link: "https://tnclerks.zendesk.com/hc/en-us"
            },
            {
              disp: "VTRS",
              link: "https://vehiclelookup.revenue.tn.gov/index.html#/login"
            },
            {
              disp: "T&R Site",
              link: "https://www.tn.gov/revenue/title-and-registration.html"
            }
          ]
        },
        {
          header: "Forms",
          target: "",
          options: [
            {
              disp: "Certificate of Ownership",
              link: "https://www.tn.gov/content/dam/tn/revenue/documents/forms/titlereg/f1310401Fill-in.pdf"
            },
            {
              disp: "Affidavit of Transfers",
              link: "https://www.tn.gov/content/dam/tn/revenue/documents/forms/sales/f1301201Fill-in.pdf"
            },
            {
              disp: "Affidavit of Inheritance",
              link: "https://www.tn.gov/content/dam/tn/revenue/documents/forms/titlereg/f1310501Fill-in.pdf"
            },
            {
              disp: "Application for TOP",
              link: "https://www.tn.gov/content/dam/tn/revenue/documents/forms/titlereg/f1314401Fill-in.pdf"
            }
          ]
        }
      ]
    };
  },
  watch: {
    localWidgets() {
      this.$emit("widget", this.localWidgets);
    }
  },
  methods: {
    reloadDashboardDefaults() {
      this.localWidgets = JSON.parse(dashBoardDefault);
      this.$emit("widget", this.localWidgets);
    },
    updateWidget(item) {
      for (let i = 0; i < this.localWidgets.length; i++) {
        if (this.localWidgets[i].i == item.i) {
          Object.assign(this.localWidgets[i], {
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h
          });
          break;
        }
      }
      this.$emit("widget", this.localWidgets);
    },
    addWidget(widget) {
      this.responsive = false;
      this.localWidgets.push({
        x: 0,
        y: 0,
        w: widget.defaultW,
        h: widget.defaultH,
        i: +new Date(),
        type: widget.type
      });
    },
    removeWidget(index) {
      this.localWidgets.splice(index, 1);
    },
    movedEvent(item) {
      this.updateWidget(item);
    },
    resizedEvent(item) {
      this.updateWidget(item);
    }
  }
};
</script>

<style scoped lang="scss">
.select-menu {
  cursor: pointer;
}

.select-menu:hover {
  background-color: var(--borders-backgrounds);
}

.widget-content {
  height: 100%;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.lock {
  top: 90px;
  position: fixed;
  right: 10px;
  z-index: 6;
}

.add-widget {
  position: fixed;
  bottom: 25px;
  right: 50%;
  z-index: 6;
}

.reset {
  position: fixed;
  bottom: 25px;
  left: 15px;
  z-index: 6;
}

.widget-close button {
  margin: 0;
  position: fixed;
  right: 5px;
  top: 5px;
}

::v-deep .panel h2 .lock {
  float: right;
  cursor: pointer;
}
</style>
