<template>
  <v-autocomplete
    label="Make"
    :dark="dark"
    :readonly="readonly"
    :outlined="outlined"
    :clearable="!readonly"
    :disabled="disabled"
    :items="computedMakes"
    item-text="text"
    item-value="makeCode"
    v-model="make"
    :search-input.sync="searchString"
    @input="updateValue"
    @blur="updateValue"
    :error-messages="errorMessages"
    :error="error"
  />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    outlined: { type: Boolean, default: false },
    dark: { type: Boolean, deafult: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    value: { type: String, required: true },
    error: { type: Boolean, required: false },
    errorMessages: { type: Array, required: false }
  },
  data: () => ({
    make: "",
    searchString: ""
  }),
  mounted() {
    this.make = this.value;
  },
  computed: {
    computedMakes() {
      return this.makes.map(make => {
        let text = "";
        if (make.makeCode === make.makeDescription) {
          text += make.makeCode;
        } else {
          if (!this._.isEmpty(make.makeCode)) {
            text += make.makeCode;
          }
          if (!this._.isEmpty(make.makeDescription)) {
            if (text !== "") {
              text += " - ";
            }
            text += make.makeDescription;
          }
        }
        return {
          ...make,
          text
        };
      });
    },
    ...mapGetters(["makes"])
  },
  methods: {
    updateValue() {
      if (this.make === null) {
        this.make = "";
      }
      this.$emit("input", this.make);
    }
  },
  watch: {
    value() {
      this.make = this.value;
    },
    searchString() {
      if (this.searchString?.length > 0) {
        this.searchString = this.searchString.toUpperCase();
      }
    }
  }
};
</script>
