export default {
  routes: [
    {
      path: "/",
      name: "Home",
      component: require("@/components/Home.vue").default,
      meta: {
        permission: [],
        name: "Home"
      }
    },
    {
      path: "/results",
      name: "Results",
      component: () => import("@/components/Results"),
      meta: {
        permission: [],
        name: "Results"
      }
    },
    {
      path: "/dealerPlates",
      name: "DealerPlates",
      component: () => import("@/components/DealerPlateTransaction"),
      meta: {
        permission: [],
        name: "Dealer Plates"
      }
    },
    {
      path: "/login",
      name: "Login",
      component: require("@/components/Login.vue").default,
      meta: {
        permission: [],
        name: "Login"
      }
    },
    {
      path: "/login/:tnClerkKey",
      name: "LoginTNClerk",
      props: true,
      component: () => import("@/components/Login"),
      meta: {
        permission: [],
        name: "Login"
      }
    },
    {
      path: "/details/:vin/:make/:year",
      name: "Details",
      props: true,
      component: () => import("@/components/Details"),
      meta: {
        permission: [],
        name: "Details"
      }
    },
    {
      path: "/details/placard/:placard",
      name: "PlacardDetails",
      props: true,
      component: () => import("@/components/Details"),
      meta: {
        permission: [],
        name: "Details"
      }
    },
    {
      path: "/transaction/:vin/:make/:year",
      name: "Transaction",
      props: true,
      component: () => import("@/components/Transaction"),
      meta: {
        permission: [],
        name: "Transaction"
      }
    },
    {
      path: "/transaction/placard/:placard",
      name: "PlacardTransaction",
      props: true,
      component: () => import("@/components/Transaction"),
      meta: {
        permission: [],
        name: "Transaction"
      }
    },
    {
      path: "/new/transaction",
      name: "NewTransaction",
      component: () => import("@/components/Transaction"),
      meta: {
        permission: [],
        name: "New Transaction"
      }
    },
    {
      path: "/transactionChange/:vin/:make/:year",
      props: true,
      name: "DirectTransactionChange",
      component: () =>
        import("@/components/routerComponents/DirectTransactionChange"),
      meta: {
        permission: [],
        name: "Transaction"
      }
    },
    {
      path: "/new/transactionChange",
      name: "NewDirectTransactionChange",
      component: () =>
        import("@/components/routerComponents/DirectTransactionChange"),
      meta: {
        permission: [],
        name: "Transaction"
      }
    },
    {
      path: "/checkOut",
      name: "CheckOut",
      component: () => import("@/components/CheckOut"),
      meta: {
        permission: [],
        name: "Checkout"
      }
    },
    {
      path: "/documentation",
      props: true,
      name: "Documentation",
      component: () => import("@/components/Documentation"),
      meta: {
        permission: [],
        name: "Documentation"
      }
    },
    {
      path: "/devTests",
      name: "devTests",
      component: () => import("@/components/testComponents/devTests"),
      meta: {
        permission: []
      }
    },
    {
      path: "/barcodeSearch",
      name: "BarcodeSearch",
      component: () => import("@/components/routerComponents/BarcodeSearch"),
      meta: {
        permission: []
      }
    },
    {
      path: "/demo/configuration",
      name: "DevConfiguration",
      component: () => import("@/components/testComponents/Configuration"),
      meta: {
        permission: []
      }
    },
    {
      path: "/configuration",
      name: "Configuration",
      component: () => import("@/components/Configuration"),
      meta: {
        permission: []
      }
    },
    {
      path: "/configuration/mvConfigs",
      name: "mvConfiguration",
      component: () => import("@/components/Configuration"),
      meta: {
        permission: []
      }
    },
    {
      path: "/configuration/generalLedger",
      name: "ConfigurationGL",
      component: () => import("@/components/Configuration"),
      meta: {
        permission: []
      }
    },
    {
      path: "/configuration/hardware",
      name: "ConfigurationHardware",
      component: () => import("@/components/Configuration"),
      meta: {
        permission: []
      }
    },
    {
      path: "/configuration/users",
      name: "Users",
      component: () => import("@/components/Configuration"),
      meta: {
        permission: []
      }
    },
    {
      path: "/reportList",
      name: "ReportList",
      component: () => import("@/components/reportList"),
      meta: {
        permission: []
      }
    },
    {
      path: "/purgeDetails",
      name: "PurgeDetails",
      component: () => import("@/components/PurgeDetails"),
      meta: {
        permission: [],
        name: "Details"
      }
    },
    {
      path: "/previousTransactions",
      name: "PreviousTransactions",
      component: () => import("@/components/PreviousTransactions"),
      meta: {
        permission: [],
        name: "Prev Transactions"
      }
    },
    {
      path: "/missingImages",
      name: "MissingImages",
      component: () => import("@/components/MissingImages"),
      meta: {
        permission: [],
        name: "Missing Images"
      }
    },
    {
      path: "/inventory",
      name: "Inventory",
      component: () => import("@/components/Inventory"),
      meta: {
        permission: [],
        name: "Inventory"
      }
    },
    {
      path: "/eztag",
      name: "EZTag",
      component: () => import("@/components/EZTag"),
      meta: {
        permission: [],
        name: "EZ Tag"
      }
    },
    {
      path: "/inventory/order",
      name: "InventoryOrder",
      component: () => import("@/components/Inventory"),
      meta: {
        permission: [],
        name: "Inventory"
      }
    },
    {
      path: "/pendingTitles",
      name: "PendingTitles",
      component: () => import("@/components/PendingTitles"),
      meta: {
        permission: [],
        name: "Pending Titles"
      }
    },
    {
      path: "/onlineRenewals",
      name: "OnlineRenewals",
      component: () => import("@/components/OnlineRenewals"),
      meta: {
        permission: [],
        name: "Online Renewals"
      }
    },
    {
      path: "/titlePrinting",
      name: "TitlePrinting",
      component: () => import("@/components/TitlePrinting"),
      meta: {
        permission: [],
        name: "Title Print"
      }
    },
    {
      path: "/bulkScanning",
      name: "BulkScanning",
      component: () => import("@/components/BulkScanning"),
      meta: {
        permission: [],
        name: "Bulk Scanning"
      }
    },
    {
      path: "/deficientTransactions",
      name: "DeficientTransactions",
      component: () => import("@/components/DeficientTransactions"),
      meta: {
        permission: [],
        name: "Deficients"
      }
    },
    {
      path: "/controlNumbers",
      redirect: {
        name: "ControlNumberScanning"
      },
      name: "ControlNumbers",
      meta: {
        permission: [],
        name: "Control #'s"
      }
    },
    {
      path: "/controlNumbers/scan",
      name: "ControlNumberScanning",
      component: () => import("@/components/ControlNumbers"),
      meta: {
        permission: [],
        name: "Control #'s"
      }
    },
    {
      path: "/controlNumbers/manual",
      name: "ControlNumberScanningManual",
      component: () => import("@/components/ControlNumbers"),
      meta: {
        permission: []
      }
    },
    {
      path: "/configuration/user-info",
      name: "UserInfo",
      component: () => import("@/components/Configuration"),
      meta: {
        permission: []
      }
    },
    {
      path: "/configuration/color-scheme",
      name: "ColorScheme",
      component: () => import("@/components/Configuration"),
      meta: {
        permission: []
      }
    },
    {
      path: "/configuration/fonts",
      name: "Fonts",
      component: () => import("@/components/Configuration"),
      meta: {
        permission: [],
        name: "Drafts"
      }
    },
    {
      path: "/unfinishedDrafts",
      name: "UnfinishedDrafts",
      component: () => import("@/components/UnfinishedDrafts"),
      meta: {
        permission: [],
        name: "Lienholders"
      }
    },
    {
      path: "/lienholderMaint",
      name: "LienholderMaint",
      component: () => import("@/components/LienholderMaint"),
      meta: {
        permission: []
      }
    },
    {
      path: "/cashCheckReport",
      name: "CashCheck",
      component: () => import("@/components/CashCheck"),
      meta: {
        permission: [],
        name: "Fee Maint"
      }
    },
    {
      path: "/countyFeeMaintenance",
      name: "CountyFeeMaintenance",
      component: () => import("@/components/CountyFeeMaintenance"),
      meta: {
        permission: [],
        name: "Fee Maint"
      }
    }
  ]
};
