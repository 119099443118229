<template>
  <div id="drafts">
    <div class="header">
      <div v-if="draftList.length > 0">
        <v-icon color="primary" size="48px">create</v-icon>
        <v-badge :content="draftList.length">
          <h2 slot="prepend">Drafts</h2>
        </v-badge>
      </div>
      <h2 v-else>
        <v-icon color="primary" size="48px">create</v-icon>
        Drafts
      </h2>
    </div>
    <div class="table">
      <DraftsTable />
    </div>
  </div>
</template>

<script>
import DraftsTable from "@/components/nonPageComponents/DraftsTable";
import { mapGetters } from "vuex";
export default {
  components: {
    DraftsTable
  },
  computed: {
    ...mapGetters({
      draftList: "unfinishedDrafts"
    })
  }
};
</script>
<style scoped lang="scss">
$header-height: 45px;
#drafts {
  height: calc(100% - #{$header-height});
}

.header {
  height: $header-height;
}

.table {
  height: 100%;
}
.bootstrap {
  height: calc(100% - 15px);
}

::v-deep .bootstrap .b-table-sticky-header {
  max-height: 100%;
}
</style>
