<template>
  <div @keyup.enter="doAdvancedSearch" class="modal" id="advanced-search">
    <div v-if="plateClassArray" class="outer">
      <div class="inner">
        <v-text-field
          v-bind="fieldAttrs.vin"
          @focus="purgeClearFields('vin')"
          v-model="vin"
          id="vin"
          label="VIN"
        />
        <v-text-field
          v-bind="fieldAttrs.vinLast8"
          v-model="VINLast"
          id="vinLast"
          label="VIN Last 8"
        />

        <makeSelect
          :disabled="fieldAttrs.make.disabled"
          v-model="makeCode"
          :data="$store.getters.makes"
        />

        <v-text-field
          label="Model"
          v-bind="fieldAttrs.model"
          v-model="modelCode"
          id="model"
        />

        <v-text-field
          label="Year"
          v-bind="fieldAttrs.year"
          v-model="year"
          type="number"
          @wheel="$event.target.blur()"
          min="0"
          @blur="removeShadow"
          id="year"
        />
      </div>
      <div class="inner">
        <v-text-field
          v-bind="fieldAttrs.plate"
          @focus="purgeClearFields('plate')"
          v-model="plateNo"
          @input="plateNo = plateNo.toUpperCase()"
          id="plateNum"
          label="Plate Number"
        />

        <v-select
          menu-props="auto"
          label="Class"
          id="class"
          v-model="plateClassIndex"
          @change="classSelect"
          :items="classSelectMap"
        />

        <v-select
          menu-props="auto"
          :items="issueYearSelectMap"
          label="Issue Year"
          v-bind="fieldAttrs.issueYear"
          v-model="PlateClassIssueYear"
          id="issueYear"
        />

        <v-text-field
          v-bind="fieldAttrs.placard"
          v-model="placard"
          id="placard"
          label="Placard"
        />
      </div>
      <div class="inner">
        <v-text-field
          label="Title Number"
          v-bind="fieldAttrs.title"
          @focus="purgeClearFields('title')"
          v-model="titleNo"
          id="title"
        />

        <v-text-field
          v-bind="fieldAttrs.name"
          @focus="purgeClearFields('name')"
          v-model="name"
          id="name"
          label="Name"
        />

        <vue-smarty-streets
          :uppercase="true"
          :disabled="fieldAttrs.address.disabled"
          :addressObj="addressObj"
        ></vue-smarty-streets>
      </div>
    </div>

    <div id="purgeSwitchBtns">
      <v-switch v-model="isArchiveSearch" label="Archive Records" />

      <v-switch
        v-model="isUndercoverSearch"
        label="Undercover"
        v-if="isFeatureEnabled('mvUndercover')"
      />
    </div>

    <div class="search-button">
      <v-btn color="primary" @click="doAdvancedSearch" id="search"
        >Search</v-btn
      >
    </div>
  </div>
</template>

<script>
import vehicleFunctions from "@/mixins/vehicleFunctions.js";
import searchFunctions from "@/mixins/searchFunctions.js";
import vueSmartyStreets from "@/components/nonPageComponents/vue-smarty-streets";
import makeSelect from "@/components/nonPageComponents/makeSelect";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  components: {
    "vue-smarty-streets": vueSmartyStreets,
    makeSelect
  },
  mixins: [vehicleFunctions, searchFunctions],
  data() {
    return {
      show: false,
      titleNo: "",
      vin: "",
      VINLast: "",
      plateNo: "",
      placard: "",
      dealerPlate: "",
      plateClass: "",
      plateClassIndex: "",
      PlateClassIssueYear: "",
      makeCode: "",
      modelCode: "",
      name: "",
      addressObj: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: ""
      },
      year: "",
      issueYearArray: "",
      code: "I",
      barCode: "",
      barCodeLastTime: Date.now(),
      advancedSearch: true,
      simpleSearch: "",
      inquiryRunning: false,
      noResultsMenuTitle: "",
      noResultsMenuOptions: [],
      isArchiveSearch: false,
      isUndercoverSearch: false,
      fieldAttrs: {
        make: { disabled: false },
        address: { disabled: false }
      }
    };
  },
  computed: {
    ...mapGetters({
      isFeatureEnabled: "isFeatureEnabled"
    }),
    classSelectMap() {
      return Object.keys(this.plateClassArray).map((key, index) => {
        return {
          text:
            this.plateClassArray[key].plateClassCode +
            " - " +
            this.plateClassArray[key].plateClassDescription,
          value: index + 1
        };
      });
    },
    issueYearSelectMap() {
      return Object.keys(this.issueYearArray).map((key, index) => {
        return {
          text: this.issueYearArray[key],
          value: index
        };
      });
    },
    plateClassArray() {
      return this.$store.getters.plateClasses;
    },
    streetAddress() {
      return this.addressObj.address1;
    },
    street2Address() {
      return this.addressObj.address2;
    },
    ZIP() {
      return this.addressObj.postalCode;
    },
    city() {
      return this.addressObj.city;
    },
    state() {
      return this.addressObj.state;
    }
  },
  methods: {
    clearFields() {
      this.titleNo = "";
      this.vin = "";
      this.VINLast = "";
      this.plateNo = "";
      this.placard = "";
      this.dealerPlate = "";
      this.plateClass = "";
      this.plateClassIndex = "";
      this.PlateClassIssueYear = "";
      this.makeCode = "";
      this.modelCode = "";
      this.name = "";
      this.addressObj = {
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: ""
      };
      this.year = "";
    },
    doAdvancedSearch() {
      this.doInquiry();
      this.clearFields();
      this.$emit("close");
    },
    disableFieldsInArr(disabledArr, disabled) {
      for (let i = 0; i < disabledArr.length; i++) {
        Vue.set(this.fieldAttrs, disabledArr[i], {
          disabled: disabled
        });
      }
    }
  },
  watch: {
    placard() {
      const disabledArr = [
        "make",
        "model",
        "year",
        "address",
        "vinLast8",
        "class",
        "issueYear",
        "name",
        "vin",
        "plate",
        "title"
      ];
      this.disableFieldsInArr(disabledArr, this.placard.length > 0);
    },
    isArchiveSearch() {
      this.isUndercoverSearch = false;
      const disabledArr = [
        "vinLast8",
        "class",
        "issueYear",
        "make",
        "model",
        "year",
        "address",
        "placard"
      ];
      this.disableFieldsInArr(disabledArr, this.isArchiveSearch);
    },
    isUndercoverSearch() {
      this.isArchiveSearch = false;
      const disabledArr = [
        "model",
        "address",
        "vinLast8",
        "class",
        "issueYear",
        "name",
        "plate",
        "title",
        "placard"
      ];
      this.disableFieldsInArr(disabledArr, this.isUndercoverSearch);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/global-variables.scss";
.modal {
  width: 100%;
  height: 40vh;
  min-height: 480px;
  position: fixed;
  top: calc(#{$nav-height} - 2px);
  left: 0;
  display: block;
  overflow-y: hidden;
  z-index: 198;
  border: none;
  -webkit-box-shadow: 0px 7px 7px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 7px 7px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 7px 7px 2px rgba(0, 0, 0, 0.25);
}

#advanced-search .modal .v-label {
  color: var(--primary);
}

.search-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#purgeSwitchBtns {
  display: flex;
  flex-direction: row;
}
#purgeSwitchBtns .v-input {
  display: flex;
  flex-direction: row;
  width: 200px;
}

select {
  width: 100%;
  margin: 20px 0;
}

.outer {
  display: flex;
}

.inner {
  flex: 1;
}

.inner:first-child,
.inner:nth-child(2) {
  margin-right: 10px;
}

.inner:nth-child(2),
.inner:last-child {
  margin-left: 10px;
}

.modal label {
  color: white;
}
</style>
