import hmacSHA256 from "crypto-js/hmac-sha256";

export default function (axios) {
  return {
    /*
    Checks local system for Sigweb and installs it if not found.
    Sigweb is required for topaz pad signatures.
    Returns {success: true}
    */
    installSigWeb() {
      return axios("/installSigWeb");
    },
    /*
    Manually checks for an updated version of HubApp
    Returns update and version info
    */
    checkForUpdates() {
      return axios("/checkForUpdates");
    },
    /*
    Sends documents to HubApp to be printed.
    Returns msg 'print job received' on success
    */
    print(data) {
      const form = new FormData();
      form.append("send", JSON.stringify(data));
      const sendData = new URLSearchParams(form);
      return axios.post("/print", sendData);
    },
    /*
    Converts PDF images to Base64 and sends them along to be printed
    */
    printBase64PDF(data) {
      const form = new FormData();
      form.append("send", JSON.stringify(data));
      const sendData = new URLSearchParams(form);
      return axios.post("/printBase64PDF", sendData);
    },
    /*
    Returns the current printer settings for user
    
    Returns:
    {
      printsettings: [{
        0: {
          name: string,
          printer: string,
          bin: string
        }
      }]
    }
    */
    getHubConfigs() {
      return axios("/getHubConfigs");
    },
    /*
    Sends updated printer settings to hubapp

    hubConfiguration: {
      printSettings: [{
        name: string:,
        printer: string,
        bin: string
      }]
    }
    */
    setHubConfigs(data) {
      const form = new FormData();
      form.append("send", JSON.stringify(data));
      const sendData = new URLSearchParams(form);
      return axios.post("/setHubConfigs", sendData);
    },
    /*
    Generic receiver for Uniterm requests. Should be used for all requests made to Uniterm
    u_action: 'txnrequest' asks Uniterm to begin a new transaction
    u_action: 'status' asks Uniterm for the current status of the transaction
    u_action: 'reqsignature' asks Uniterm to require a signature for the transaction
    u_action: 'cancel' asks Uniterm to cancel the current transaction
    */
    queryUniterm(data, customErrorHandlers = {}, requireAuth = true) {
      let uAction, uId;
      const form = new FormData();
      Object.keys(data).forEach(key => {
        if (key === "u_action") uAction = data[key];
        else if (key === "u_id") uId = data[key];
        form.append(key, data[key]);
      });

      if (requireAuth === false) {
        const sequence = Math.floor(Math.random() * Math.pow(10, 10));
        const timestamp = Math.floor(new Date().getTime() / 1000);
        form.append("u_req_sequence", sequence);
        form.append("u_req_timestamp", timestamp);

        const hmac = hmacSHA256(
          `${uAction}${uId}${sequence}${timestamp}`,
          "uniSS1910"
        );
        form.append("u_req_hmacsha256", hmac);
      }

      const sendData = new URLSearchParams(form);
      return axios({
        method: "post",
        url: "/queryUniterm",
        data: sendData,
        customErrorHandlers
      });
    },
    /*
    Generic receiver for Monetra requests. Currently used to retrieve signature pad image from Monetra
    */
    queryMonetra(data) {
      return axios.post("/queryMonetra", data);
    },
    /*
    Runs when we select to charge a card through Uniterm
    Sends our configured monetra user, pass, and host to hubapp
    */
    setMonetraCreds(data) {
      axios({
        method: "post",
        url: "/setMonetraCreds",
        data: data,
        headers: {
          "Content-Type": "application/json"
        }
      });
    },
    /*
    Changes the brightness of the image in the image scanner
    */
    changeBrightness(data) {
      const form = new FormData();
      Object.keys(data).forEach(key => {
        form.append(key, data[key]);
      });
      const sendData = new URLSearchParams(form);
      return axios.post("/brightness", sendData);
    },
    /*
    Returns:
    [
      0: {
        manufacturer: string,
        model: string,
        name: string,
        options: {...},
        sources: [{...}]
      }
    ]
    */
    getAllPrinters() {
      return axios("/getAllPrinters");
    },
    /*
    Used to submit test and error logs to hubapp
    */
    log(logLevel, message) {
      const data = new URLSearchParams();
      data.append("level", logLevel);
      data.append("message", JSON.stringify(message));
      return axios.post("/log", data);
    }
  };
}
