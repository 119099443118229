import { isArray } from "lodash";

function formatErrorResponse(error) {
  let uiErrorMsg = "There was a problem.";

  let errorResponseData = error.response?.data;

  if (isArray(errorResponseData)) {
    errorResponseData = errorResponseData[0];
  }

  if (
    errorResponseData != undefined &&
    errorResponseData?.detail !== undefined
  ) {
    uiErrorMsg = error.response.data.detail;
  }

  if (
    errorResponseData != undefined &&
    errorResponseData["invalid-params"] !== undefined &&
    errorResponseData["invalid-params"]?.validation !== undefined
  ) {
    uiErrorMsg = errorResponseData["invalid-params"]?.validation;
  }

  if (errorResponseData !== undefined && errorResponseData.err !== undefined) {
    try {
      const errDetail = JSON.parse(errorResponseData.err);
      if (errDetail["invalid-params"] !== undefined) {
        uiErrorMsg = "";
        Object.values(errDetail["invalid-params"]).forEach(errVal => {
          uiErrorMsg += `${errVal}\n`;
        });
      } else if (errDetail.detail !== undefined) {
        uiErrorMsg = errDetail.detail;
      }
    } catch (e) {
      uiErrorMsg = errorResponseData.err;
    }
  }

  return uiErrorMsg;
}

export { formatErrorResponse };
