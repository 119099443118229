<template>
  <div class="override-login">
    This action requires additional permissions.<br />Please provide override
    user credentials.<br />
    <v-text-field v-model="username" label="Username" />
    <v-text-field v-model="password" label="Password" />
    <div>
      <v-btn
        :disabled="![username, password].includes('')"
        @click="emitOverride"
        >Override
      </v-btn>
      <v-btn>Cancel</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    emitOverride() {
      // make override login request with user provided credentials
      this.$emit(
        "override",
        this.$api.login(
          {
            // cookie: true, // todo> restore
            countyId: parseInt(this.$store.getters.countyId),
            officeId: parseInt(this.$store.getters.officeId),
            drawerNo: parseInt(this.$store.getters.drawerNo),
            workstationId: parseInt(this.$store.getters.workstationId),
            invoiceNo: this.invoiceNo,
            username: this.username,
            password: this.password
          },
          true
        )
      );
    }
  },
  computed: {
    ...mapGetters({
      invoiceNo: "invoiceNo"
    })
  }
};
</script>

<style scoped lang="scss">
.override-login {
  padding: 20px;
}
</style>
