<template>
  <div class="bootstrap">
    <b-table
      :sticky-header="tableSize"
      :no-border-collapse="true"
      :show-empty="true"
      :fields="tableFields"
      head-variant="light"
      sort-icon-left
      responsive="sm"
      selectable
      select-mode="single"
      hover
      @row-clicked="item => loadDraft(item)"
      :items="draftList"
    >
      <template v-slot:cell(time)="data">
        {{ new Date(data.item.time).toLocaleString() }}
      </template>
      <template v-slot:cell(remove)="data">
        <v-btn @click="removeItem(data.item)">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      draftList: "unfinishedDrafts"
    })
  },
  props: {
    tableSize: {
      type: String,
      default: () => {
        return "";
      }
    }
  },
  data() {
    return {
      tableFields: [
        {
          key: "vin"
        },
        {
          key: "transactionType",
          sortable: true
        },
        {
          key: "time",
          label: "Timestamp",
          sortable: true,
          sortDesc: false
        },
        {
          key: "remove"
        }
      ]
    };
  },
  methods: {
    loadDraft(value) {
      this.$store.commit("editObj", value.obj);
      this.$store.commit("transactionType", value.transactionType);
      if (value.obj.vehicle.vin !== undefined && value.obj.vehicle.vin !== "") {
        this.$router.push({
          name: "Transaction",
          params: {
            vin: value.obj.vehicle.vin,
            make: value.obj.vehicle.makeCode,
            year: value.obj.vehicle.modelYear,
            isFromBackButton: true
          }
        });
      } else {
        this.$router.push({
          name: "NewTransaction",
          params: {
            isFromBackButton: true
          }
        });
      }
    },
    removeItem(item) {
      let index = null;
      for (let i = 0; i < this.draftList.length; i++) {
        if (this.draftList[i].vin == item.vin) {
          index = i;
          break;
        }
      }
      if (index !== undefined)
        this.$store.commit("removeUnfinishedDraft", index);
      event.stopPropagation(); // prevent propagation to tr and subsequent call to loadDraft
    }
  }
};
</script>
