<template>
  <div v-if="show" id="loadingWrapper" class="loadingDiv">
    <div
      style="
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        left: 50%;
        top: 45%;
        text-align: center;
      "
    >
      <span style="font-size: 24pt; margin-left: -50px; opacity: 0.9"
        ><h1 style="display: inline-block">Loading...</h1></span
      ><br /><br />
      <div class="dot-overtaking"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timeout: undefined
    };
  },
  name: "loadingDiv",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    timeoutTime: {
      type: Number,
      required: false,
      default: () => 5000
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.timeout = setTimeout(() => {
          this.checkForAxiosCalls();
        }, this.timeoutTime);
      } else {
        clearTimeout(this.timeout);
      }
    }
  },
  methods: {
    checkForAxiosCalls() {
      if (this.$store.getters.activeAxiosCalls === 0) {
        this.$hubapp.log("debug", "Loading visual force interrupted");
        
        this.$root.$emit("setLoading", false);
        this.$store.dispatch("setGlobalAlertState", {
          title: "Error!",
          description: "Action Timed Out!",
          icon: "error",
          actions: [
            {
              text: "Okay",
              handler: () => this.$store.dispatch("hideGlobalAlert"),
              color: "primary"
            }
          ]
        });
      } else {
        this.timeout = setTimeout(() => {
          this.checkForAxiosCalls();
        }, 1000);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@charset "UTF-8";

#loadingWrapper {
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9997;
}

/**
     * ==============================================
     * Experiment-Gooey Effect
     * Dot Overtaking
     * ==============================================
     */
.dot-overtaking {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: transparent;
  color: black;
  margin: -1px 0;
  box-shadow: 0 -20px 0 0;
  opacity: 0.2;

  animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
}

.dot-overtaking::before,
.dot-overtaking::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: transparent;
  color: black;
  box-shadow: 0 -20px 0 0;
}

.dot-overtaking::before {
  animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  animation-delay: 0.3s;
}

.dot-overtaking::after {
  animation: dotOvertaking 1.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  animation-delay: 0.6s;
}

@keyframes dotOvertaking {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@media print {
  .loadingDiv {
    display: none;
  }
}
</style>
