/* A mixing that provides transaction related component properties. */
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { invert } from "lodash";
import { formatErrorResponse } from "@/assets/js/format.js";

import { TransactionRequest as transactionReq } from "@/classes/TransactionRequest";

const transactionTypes = invert(
  require("@/assets/jsonScaffolds/transactionTypes.json")
);

export default {
  data: () => ({
    SSE: null,
    SSETimeout: null
  }),
  methods: {
    async loadRenewalTransaction(renewal, pendingTransaction = undefined) {
      if (pendingTransaction !== undefined) {
        renewal = await this.$api.renewalSearch(
          "?transactionID=" + pendingTransaction.transactionID
        );

        renewal = renewal[0];
      }

      this.$store.dispatch("clearPendingTransaction");
      try {
        this.$root.$emit("setLoading", true);
        var responses = await this.$api
          .transactionSearch(renewal.transactionID, renewal.assignedTo === null)
          .catch(e => console.error(e));

        this.$root.$emit("setLoading", false);

        if (responses === undefined) {
          return;
        }

        var transactionRequest = JSON.parse(responses[0].transactionJSON);

        //Allows Exisiting supporting documents to be displayed in Online Renwal Transactions
        if (![null, undefined].includes(responses[0].supportingDocumentID)) {
          transactionRequest.supportingDocumentID =
            responses[0].supportingDocumentID;
        }
      } catch (error) {
        console.error(error);
        return;
      }

      this.$store.commit("transactionType", "Online Renewal");
      if ([undefined, null].includes(transactionRequest.placard)) {
        if (
          [null, undefined].includes(transactionRequest.title) &&
          responses[0]?.titleNo !== undefined
        ) {
          transactionRequest.title = {};
          transactionRequest.title.titleNo = responses[0].titleNo;
        }
        const processObj =
          this.reformatTandrObjectforTransactionScreen(transactionRequest);

        if (processObj.stops !== undefined && processObj.stops.length > 0) {
          //todo, this needs to check specifically for evis not just any stop
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error",
            description: "An EIVS stop is present for this vehicle.",
            icon: "error"
          });
          return;
        }

        this.$store.commit("processObj", this._.cloneDeep(processObj)); // committing for navbar display todo> this can be avoided by removing process obj from store or implementing correct state management

        this.$router.push({
          name: "Transaction",
          params: {
            renewal: renewal,
            readOnly: true,
            allowProcess: ["3", "P"].includes(responses[0].statusCode),
            vin: processObj.vehicle.vin,
            make: processObj.vehicle.makeCode,
            year: processObj.vehicle.modelYear,
            transactionRequest: new transactionReq(processObj),
            menuIsShown: false
          }
        });
      } else {
        this.$router.push({
          name: "PlacardTransaction",
          params: {
            renewal: renewal,
            transactionRequest: new transactionReq(transactionRequest),
            readOnly: true
          }
        });
      }
    },
    async getTitlePdf(vin) {
      let promptResolved = false;
      let response;
      while (!promptResolved) {
        response = await new Promise(res => {
          this.$api
            .titlePrintCheckGet(vin, {
              422: error => {
                this.$store.dispatch("setGlobalAlertState", {
                  title: "Error",
                  minWidth: "350px",
                  description: formatErrorResponse(error),
                  actions: [
                    {
                      text: "Try Again",
                      handler: () => {
                        this.$store.dispatch("hideGlobalAlert");
                        res(true);
                      },
                      color: "primary"
                    },
                    {
                      text: "Print Later",
                      handler: () => {
                        this.$store.commit("instaTitlePrintFailure", true);
                        this.$store.dispatch("hideGlobalAlert");
                        res(false);
                      },
                      color: "error"
                    }
                  ]
                });
              }
            })
            .then(title => {
              if (title !== undefined) {
                res(title);
              }
            })
            .catch(e => {
              console.error(e);
              this.$store.dispatch("setGlobalAlertState", {
                title: "Error",
                minWidth: "350px",
                description:
                  "There was a problem receiving the title from the server."
              });
              res(false);
            });
        });

        if (response !== true) {
          promptResolved = true;
        }
      }

      return response;
    },

    isPermanentPlate(registration) {
      if (!registration) return;

      const plate = registration.currentPlate || registration.newPlate;
      const permPlate = this.inventoryPlateClasses.find(plateClass => {
        if (plateClass.plateClassCode === plate.class) {
          return plateClass.expirationCode === "P";
        } else {
          return false;
        }
      });
      // If the plate class CAN be permanent & expiration date is not explicitly set
      return (
        permPlate !== undefined &&
        [this.endOfTime.format(), null, undefined, ""].includes(
          registration.registrationExpiresDate
        )
      );
    },
    async requestTransaction(dataToSend, errorHandling) {
      let transactionAPI = "";

      if (
        ["Replace Placard", "New Placard", "Placard Renewal"].includes(
          this.transactionType
        ) ||
        dataToSend.transaction.transactionType === "PT"
      ) {
        transactionAPI = "/placard/transaction";
      } else if (["Undercover Registration"].includes(this.transactionType)) {
        transactionAPI = "/undercover/transaction";
      } else if (this._.has(dataToSend, "dealerPlateTransactions")) {
        delete dataToSend.transaction;
        transactionAPI = "/dealerplates";
      } else {
        transactionAPI = "/tandr/transaction";
      }

      //todo remove this when we figure out the actual problem
      this.deleteBadDateToFixParsingProblem(dataToSend);

      try {
        return await this.$api.transaction(
          transactionAPI,
          dataToSend,
          errorHandling
        );
      } finally {
        this.$root.$emit("setLoading", false);
      }
    },
    async createTransaction(dataToSend, errorHandling) {
      dataToSend.transaction.transactionTimestamp = this.getCurrentTimestamp();
      dataToSend.transaction.invoiceNo = parseInt(
        this.$store.getters.invoiceNo
      );
      this.removeEmptyComments(dataToSend);
      return await this.requestTransaction(dataToSend, errorHandling);
    },
    async editTransaction(dataToSend, errorHandling) {
      this.removeEmptyComments(dataToSend);
      return await this.requestTransaction(dataToSend, errorHandling);
    },
    deleteBadDateToFixParsingProblem(processObj) {
      //todo remove this when we figure out the actual problem

      //we think this is causing a parsing error
      ["owners", "lienholders", "lessors", "liens"].forEach(objectKey => {
        if (this._.has(processObj, objectKey)) {
          for (let i = 0; i < processObj[objectKey].length; i++) {
            [
              processObj[objectKey][i],
              processObj[objectKey][i].lienholder
            ].forEach(element => {
              if (this._.has(element, "businessCustomer.licenseStatusDate")) {
                const date = element.businessCustomer.licenseStatusDate;
                if (
                  (typeof date !== "string" || date.length !== 25) &&
                  date !== null
                )
                  console.error(
                    "Invalid value for licenseStatusDate in fees request body:",
                    processObj
                  );

                delete element.businessCustomer.licenseStatusDate;
              }
            });
          }
        }
      });
    },
    getItemsToPrint(transactionRequest, transactionType, transRequestResult) {
      const itemsToPrint = [];

      //this is deciding what to print, if anything, at the end of the transaction.
      //moved here so we don't have to keep up with all differences on card/cash as much
      switch (transactionType) {
        case "Registration Renewal":
        case "Online Renewal":
          if (
            //TODO >> Need to remove the countyID flag and add to MV Configuration when MV Configurations are working in Settings Page.
            transactionRequest.registration != undefined &&
            !this.isPermanentPlate(transactionRequest.registration) &&
            this.$store.getters.countyConfig.printRegistrationLaserCopy
          ) {
            itemsToPrint.push({ doc: "decal", copies: 1, index: 0 });
          }
        // falls through

        case "Replace Plate":
        case "Reprint Decal":
        case "Correction of Registration":
        case "Change Plate Class":
        case "Title And Registration":
        case "Registration Only":
        case "New Owner":
        case "Undercover Registration":
        case "Lost/Stolen Plate or Decal":
          if (
            transactionRequest.registration != undefined &&
            !this.isPermanentPlate(transactionRequest.registration)
          ) {
            itemsToPrint.push({ doc: "decal", copies: 1 });
          }
          break;
        case "Temporary Operators Permit":
        case "Title With Temporary Tag":
        case "Temporary Tag Only":
          break;
        case "Dealer Plates":
          itemsToPrint.push({ doc: "dealerPlate", copies: 1 });
          break;
        case "Duplicate Title":
          break;
        case "Manage Lienholders":
          if (
            this.$store.getters.countyConfig.promptForMLHTitleIssuance &&
            this.$store.getters.printMLHTitle === true
          ) {
            itemsToPrint.push({ doc: "title", copies: 1 });
          }
          break;
        case "Re-Title and Register":
          //only print if Surviving Spouse transaction
          if (
            ![null, undefined, ""].includes(
              transactionRequest.ownership.spouseDateOfDeath
            ) &&
            transactionRequest.registration !== undefined &&
            !this.isPermanentPlate(transactionRequest.registration)
          ) {
            itemsToPrint.push({ doc: "decal", copies: 1 });
          }
          break;
      }

      if (
        this._.includes(
          this.$store.getters.officeConfig.autoTitlePrintTransactionCodes,
          transactionTypes[this.transactionType]
        ) &&
        (this.transactionType !== "Manage Lienholders" ||
          this.$store.getters.printMLHTitle === undefined)
      ) {
        itemsToPrint.push({ doc: "title", copies: 1 });
      }

      if (this._.has(transactionRequest, "revenueSticker")) {
        itemsToPrint.push({ doc: "tdrSticker", copies: 1, index: 1 });
      }

      //check the plate class here for temp tags so we can print if needed
      if (
        transactionRequest.registration != undefined &&
        transactionRequest.registration.newPlate != undefined &&
        transactionRequest.registration.newPlate.class == "1200"
      ) {
        if (transRequestResult.plateNo != undefined) {
          transactionRequest.registration.newPlate.plateNo =
            transRequestResult.plateNo;
          this.$store.commit("transObj", transactionRequest);
        }
        itemsToPrint.push({ doc: "top", copies: 1 });
      }

      //add 201 print to all but the following types
      const no201Array = ["Registration Renewal", "Online Renewal"];
      if (
        !no201Array.includes(transactionType) ||
        transactionRequest.placard !== undefined
      ) {
        itemsToPrint.push({ doc: "201", copies: 2 });
      }
      return itemsToPrint;
    },
    removeDischargedLiens(processObj) {
      // todo> This and it's usage can be removed once print templates are in the web-app instead of the hub-app.
      if (this._.has(processObj, "liens") && processObj.liens.length > 0) {
        for (let i = 0; i < processObj.liens.length; i++) {
          if (
            this._.has(processObj.liens[i], "dischargeDate") &&
            processObj.liens[i].dischargeDate !== null
          ) {
            processObj.liens.splice(i, 1);
          }
        }
      }
      return processObj;
    },
    getTodaysInvoice() {
      const todaysDate = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .format("ddd MMM DD YYYY HH:mm:ss");
      return parseInt(this.gregorianToJulian(todaysDate));
    },
    setWheelTaxDecalNumbers(transactionRequest) {
      if (
        transactionRequest.transaction.fees.find(
          fee => fee.feeName === "County Wheel Tax"
        ) &&
        !this._.get(
          transactionRequest,
          "countyData.registration.countyWheelTaxDecalNo"
        )
      ) {
        this._.set(
          transactionRequest,
          "countyData.registration.countyWheelTaxDecalNo",
          "P"
        );
      }
      if (
        transactionRequest.transaction.fees.find(
          fee => fee.feeName === "City Wheel Tax"
        ) &&
        !this._.get(
          transactionRequest,
          "countyData.registration.cityWheelTaxDecalNo"
        )
      ) {
        this._.set(
          transactionRequest,
          "countyData.registration.cityWheelTaxDecalNo",
          "P"
        );
      }
      return transactionRequest;
    },
    async printTransaction(
      itemsToPrint,
      transactionRequest,
      transactionResponse
    ) {
      this.setWheelTaxDecalNumbers(transactionRequest);

      itemsToPrint = itemsToPrint.slice(); // copy items to print to avoid mutating the original
      const titleToPrintIndex = itemsToPrint.findIndex(
        item => item.doc === "title"
      );
      if (titleToPrintIndex > -1) {
        const title = await this.getTitlePdf(transactionRequest.vehicle.vin);

        // print the title
        if (title) {
          try {
            this.$hubapp.printBase64PDF({
              pdf: title,
              test: false
            });
          } catch (error) {
            itemsToPrint[titleToPrintIndex].printFailure = true;
          }
        } else {
          itemsToPrint[titleToPrintIndex].serverFailure = true;
        }

        itemsToPrint.splice(titleToPrintIndex, 1);
      }

      //run the correct actions to build data
      if (this._.has(transactionRequest, "transaction")) {
        this.setPrintVars(transactionRequest, transactionResponse);
      }

      if (itemsToPrint.length > 0) {
        let transObjForPrint = JSON.parse(JSON.stringify(this.transObj));
        transObjForPrint = this.removeDischargedLiens(transObjForPrint);

        const counties = {};
        Object.entries(this.$store.getters.counties).forEach(
          ([countyID, countyName]) => {
            counties[countyID] = countyName.toUpperCase();
          }
        );
        const regObj = this.$store.getters.registrationObj;
        regObj.county = regObj.county.toUpperCase();

        let sendData = {};
        if (this._.has(transactionRequest, "transaction")) {
          sendData = {
            transObj: transObjForPrint,
            itemsToPrint: itemsToPrint,
            paymentObj: this.$store.getters.paymentObj,
            regFeesObj: this.$store.getters.regFeesObj,
            editObj: this.$store.getters.editObj,
            counties: counties,
            fuel: this.$store.getters.fuelTypes,
            registrationObj: regObj,
            countyId: this.$store.getters.countyId,
            state: this.$store.getters.state,
            userObject: this.$store.getters.userObject,
            starTransaction: transactionResponse,
            title: this.$store.getters.titleData,
            countyConfig: this.$store.getters.countyConfig,
            test: false
          };
        } else {
          sendData = {
            transObj: transObjForPrint,
            itemsToPrint: itemsToPrint,
            paymentObj: {},
            regFeesObj: {},
            editObj: {},
            counties: counties,
            fuel: this.$store.getters.fuelTypes,
            registrationObj: {},
            countyId: this.$store.getters.countyId,
            state: this.$store.getters.state,
            userObject: this.$store.getters.userObject,
            starTransaction: transactionResponse,
            title: this.$store.getters.titleData,
            countyConfig: this.$store.getters.countyConfig,
            test: false
          };
        }

        try {
          this.$hubapp.print(sendData).then(msg => {
            this.$root.$emit("setLoading", false);
            if (msg.msg !== true && msg.msg != "Print Job Received") {
              this.$store.dispatch("setGlobalAlertState", {
                title: "Print Error",
                description:
                  "Printer settings have not been specified. Please configure and reprint from the summary page",
                icon: "error"
              });
            }
          });
        } catch (error) {
          this.$root.$emit("setLoading", false);

          itemsToPrint.forEach(item => {
            if (item.doc !== "title") item.printFailure = true;
          });
        }
      } else {
        this.$root.$emit("setLoading", false);
      }
    },
    setPrintVars(transactionRequest, transactionResponse) {
      const paymentObj = {};
      const tenderMap = {
        // todo> the need for this could be removed by updating expected property names in https://git.bisonline.com/web/STAR/blob/master/src/renderer/components/printPages/RegistrationPrint.vue
        Cash: "cash",
        Check: "check",
        Change: "change",
        CreditCard: "credit"
      };

      Object.values(tenderMap).forEach(type => (paymentObj[type] = "0.00")); // default payments to zero

      if (this._.has(transactionRequest, "payments")) {
        // update payments from transactionRequest
        transactionRequest.payments.forEach(payment => {
          paymentObj[tenderMap[payment.tenderType]] = payment.amount.toFixed(2);
          if (payment.tenderType === "Check") {
            paymentObj["checkNo"] = payment.check.checkno;
          }
        });
      }

      // todo> add voucher to paymentObj when available

      this.$store.commit("paymentObj", paymentObj);
      const leftTableObjOfFloats = {
        //dont jam something in here that isnt a float and cant go through tofixed
        Cash: parseFloat(paymentObj["cash"]),
        Check: parseFloat(paymentObj["check"]),
        Change: parseFloat(paymentObj["change"]),
        Credit: parseFloat(paymentObj["credit"]),
        Registration: 0.0,
        "Elec Vhec": 0.0,
        Lease: 0.0,
        Trans: 0.0,
        Clerk: 0.0,
        Issuance: 0.0,
        Title: 0.0,
        Lien: 0.0,
        "Sales Tax": 0.0,
        "SA Tax": 0.0,
        "Local Tax": 0.0,
        "Addtnl Tax": 0.0,
        "Wheel Tax": 0.0,
        "Cty Wh Tax": 0.0,
        "Organ Don": 0.0,
        Postage: 0.0,
        "Online Fee": 0.0,
        "INS Fee": 0.0,
        "Total Tax": 0.0,
        "Total Fees": 0.0,
        "Cnt Wh Tax": 0.0
      };

      //add auth number here if we have it
      paymentObj.creditAuth =
        transactionRequest.authNo || this._.get(transactionResponse, "authNo");

      const nonNumberList = {
        //does not get tofixed
        "CC Auth #": paymentObj.creditAuth,
        "Check #": paymentObj["checkNo"] || "",
        "ID Verify": ""
      };

      const title = this.$store.getters.transObj.title;
      if (title != undefined) {
        if (title.outStateTaxCredit > 0) {
          leftTableObjOfFloats["Addtl Tax"] += parseFloat(
            title.outStateTaxCredit
          );
        }
      }

      //adding code to use new fee sub groups
      if (this._.has(transactionRequest, "transaction.fees")) {
        const feesArr = transactionRequest.transaction.fees;

        leftTableObjOfFloats["Sales Tax"] =
          feesArr.find(fee => fee.feeName === "State tax rate")?.feeAmount ||
          0.0;

        for (let i = 0; i < feesArr.length; i++) {
          //adding fees to their object key
          if (
            feesArr[i].subcategory != undefined &&
            this._.has(leftTableObjOfFloats, feesArr[i].subcategory)
          )
            leftTableObjOfFloats[feesArr[i].subcategory] += parseFloat(
              feesArr[i].feeAmount
            );

          //adding all taxes to total tax spot
          if (feesArr[i].feeGroup != undefined && feesArr[i].feeGroup == "Tax")
            leftTableObjOfFloats["Total Tax"] += parseFloat(
              feesArr[i].feeAmount
            );

          //adding all fees to total fees here
          leftTableObjOfFloats["Total Fees"] += parseFloat(
            feesArr[i].feeAmount
          );
        }
      }

      if (leftTableObjOfFloats["Cash"] > leftTableObjOfFloats["Total Fees"]) {
        leftTableObjOfFloats["Change"] =
          leftTableObjOfFloats["Cash"] - leftTableObjOfFloats["Total Fees"];
      }
      //changing these to strings for printing
      for (const key of Object.keys(leftTableObjOfFloats)) {
        leftTableObjOfFloats[key] = leftTableObjOfFloats[key].toFixed(2);
      }

      paymentObj["change"] = leftTableObjOfFloats["Change"];

      Object.assign(leftTableObjOfFloats, nonNumberList);

      //add transactionid to id verify if we have it
      if (transactionRequest.transactionID !== undefined)
        leftTableObjOfFloats["ID Verify"] = transactionRequest.transactionID;

      if (transactionResponse.starTransactionID !== undefined) {
        leftTableObjOfFloats["ID Verify"] =
          transactionResponse.starTransactionID;
      }

      if (this.transactionType == "Undercover Registration") {
        Object.values(tenderMap).forEach(type => (paymentObj[type] = "0.00"));
        paymentObj["cash"] = leftTableObjOfFloats["Total Fees"];
        this.$store.commit("paymentObj", paymentObj);
      }

      this.$store.commit("regFeesObj", leftTableObjOfFloats);

      if (this._.has(transactionRequest, "title.taxExemptCode")) {
        transactionRequest.title.taxExemptCode =
          this.titleTaxExemptReasons[transactionRequest.title.taxExemptCode];
      }
    },
    nameCodeToOwnerData(owners, ownership) {
      let nameCode = "0";
      if (this._.has(ownership, "nameCode")) {
        nameCode = ownership.nameCode.toString();
        delete ownership.nameCode;
      }

      switch (nameCode) {
        case "0":
          owners.splice(1, 1);
          owners[0]["customerType"] = "I";
          if (ownership !== undefined) ownership.conjunctionCode = "0";
          break;
        case "1":
          owners[0].customerType = "I";
          owners[1].individualCustomer.lastName =
            owners[0].individualCustomer.lastName;
          owners[1].physicalAddress = owners[0].physicalAddress;
          owners[1].customerType = "I";
          break;
        case "2":
          owners[0].customerType = "I";
          owners[1].physicalAddress = owners[0].physicalAddress;
          owners[1].customerType = "I";
          break;
        case "3":
          owners.splice(1, 1);
          if (owners[0].businessCustomer == undefined) {
            owners[0].businessCustomer = {};
          }
          owners[0].businessCustomer.businessName = owners[0].customerName;
          delete owners[0].individualCustomer;
          owners[0].customerType = "B";
          if (ownership !== undefined) ownership.conjunctionCode = "0";
          break;
      }
    },
    async formatForTandR(processObj) {
      //this is used to add the timezone on the end of dates, uses the computer's timezone
      let localOffset = "";
      const offset = new Date().getTimezoneOffset();
      const o = Math.abs(offset);
      localOffset =
        (offset < 0 ? "+" : "-") +
        ("00" + Math.floor(o / 60)).slice(-2) +
        ":" +
        ("00" + (o % 60)).slice(-2);

      //this is used later to change the format of purchase date to what api needs
      if (
        this._.has(processObj, "ownership.purchaseDate") &&
        !["", undefined, null].includes(processObj.ownership.purchaseDate)
      ) {
        const date = new Date(processObj.ownership.purchaseDate);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        if (day < 10) day = "0" + day;
        if (month < 10) month = "0" + month;
      } else if (this._.has(processObj, "ownership.purchaseDate")) {
        delete processObj.ownership.purchaseDate;
      }

      //check to see if this is an existing title that already has a title number, if not, then make sure then make sure it is an empty string
      if (
        processObj.title !== undefined &&
        [undefined, null, ""].includes(processObj.title.titleNo)
      ) {
        //todo the stateitemtypeID refers to bug 29104 and needs to be changed based on title type, no types now so hardcode
        processObj.title.stateItemTypeID = 120;
      }

      if (processObj.title !== undefined) {
        if (processObj.title.formerTitleNo == "")
          delete processObj.title.formerTitleNo;
        if (processObj.title.formerTitleState == "")
          processObj.title.formerTitleState = this.$store.getters.configState;
      }

      if (this._.has(processObj, "title.controlNo"))
        //according to analysts git issue 323
        delete processObj.title.controlNo;

      if (this._.has(processObj, "title.statusID"))
        //according to analysts git issue 328
        delete processObj.title.statusID;

      // format registration expiration date if necessary
      const registrationExpiresDate = this._.get(
        processObj,
        "registration.registrationExpiresDate"
      );
      if (
        ![null, undefined, ""].includes(registrationExpiresDate) &&
        registrationExpiresDate.toString().substring(0, 10) !=
          this.endOfTime.format("YYYY-MM-DD")
      ) {
        processObj.registration.registrationExpiresDate = dayjs(
          registrationExpiresDate
        ).format();
      }

      //this block formats the liens for api by reformatting the dates and removing empty liens, it also changes precedence number to an int
      if (this._.has(processObj, "liens")) {
        for (let i = 0; i < processObj.liens.length; i++) {
          processObj.liens[i].CountyLienID = 1; //todo  this fixes a legacy issue on apidemo and should be removed in the future
          if (
            (processObj.liens[i].lienholder.businessCustomer === undefined ||
              processObj.liens[i].lienholder.businessCustomer.businessName ===
                "") &&
            processObj.liens[i].lienholder.customerName === ""
          ) {
            processObj.liens.splice(i, 1);
            continue;
          }
          if (
            this._.has(processObj.liens[i], "lienDate") &&
            processObj.liens[i].lienDate != undefined &&
            processObj.liens[i].lienDate !== ""
          ) {
            if (processObj.liens[i].lienPrecedenceNo != "") {
              processObj.liens[i].lienPrecedenceNo = parseInt(
                processObj.liens[i].lienPrecedenceNo
              );
            } else {
              delete processObj.liens[i].lienPrecedenceNo;
            }
          }

          //remove unneeded custom flag here
          if (processObj.liens[i].AddedLien != undefined) {
            delete processObj.liens[i].AddedLien;
          }

          //remove unneeded fields
          if (
            processObj.liens[i].lienholder.physicalAddress.nCOAAddressInd !=
            undefined
          ) {
            delete processObj.liens[i].lienholder.physicalAddress
              .nCOAAddressInd;
          }

          //remove unneeded fields
          if (
            processObj.liens[i].lienholder.physicalAddress.pOVerifiedInd !=
            undefined
          ) {
            delete processObj.liens[i].lienholder.physicalAddress.pOVerifiedInd;
          }
          processObj.liens[i].lienholder.physicalAddress.countyID = parseInt(
            this.$store.getters.countyId
          );
        }
      }

      if (this._.has(processObj, "undercoverRegistration.newPlate.issueYear")) {
        processObj.undercoverRegistration.newPlate.issueYear = parseInt(
          processObj.undercoverRegistration.newPlate.issueYear
        );
      }

      //this formats the registration, sets issueyear to int removes current plate if the platenum is not defined
      if (processObj.registration != undefined) {
        if (this._.has(processObj.registration, "currentPlate")) {
          processObj.registration.currentPlate.issueYear = parseInt(
            processObj.registration.currentPlate.issueYear
          );
          if (processObj.registration.currentPlate.class != undefined) {
            processObj.registration.currentPlate.class =
              processObj.registration.currentPlate.class.toString();
          }
        }
        if (processObj.registration.newPlate != undefined) {
          processObj.registration.newPlate.issueYear = parseInt(
            processObj.registration.newPlate.issueYear
          );
          if (processObj.registration.newPlate.class != undefined) {
            processObj.registration.newPlate.class =
              processObj.registration.newPlate.class.toString();
          }
        }
        if (processObj.registration.currentPlate != undefined) {
          if (processObj.registration.currentPlate.plateNo == undefined) {
            delete processObj.registration.currentPlate;
          }
        }
      }
      if (this._.has(processObj, "vehicle")) {
        if (
          ![undefined, null].includes(processObj.vehicle.insurance) &&
          typeof processObj.vehicle.insurance == "object"
        ) {
          if (
            processObj.vehicle.insurance.NAIC == "" ||
            processObj.vehicle.insurance.PolicyNo == ""
          ) {
            delete processObj.vehicle.insurance;
          } else {
            processObj.vehicle.insurance.NAIC = parseInt(
              processObj.vehicle.insurance.NAIC
            );
            processObj.vehicle.insurance.VIN = processObj.vehicle.vin;
          }
        }
      }

      if (this._.has(processObj, "vehicle.fuelTypeCode")) {
        processObj.vehicle.fuelTypeCode =
          processObj.vehicle.fuelTypeCode.toString();
      }

      if (
        processObj.registration != undefined &&
        processObj.registration.countyID != undefined
      ) {
        processObj.registration.countyID = parseInt(
          processObj.registration.countyID,
          10
        );
      }

      //this block fixes the owners in the obj based on what namecode was decided, sets business / individual / more than one name
      if (processObj.owners !== undefined) {
        this.nameCodeToOwnerData(processObj.owners, processObj.ownership);
      }

      if (
        processObj.undercoverOwners !== undefined &&
        processObj.undercoverOwners.length > 0
      ) {
        this.nameCodeToOwnerData(
          processObj.undercoverOwners,
          processObj.undercoverOwnership
        );
      }

      //this block reformats the owners changing id's to ints where it needs and deleting addesses if they are not needed.
      if (this._.has(processObj, "owners")) {
        this.formatOwnerAddresses(processObj.owners);
      }

      if (this._.has(processObj, "undercoverOwners")) {
        this.formatOwnerAddresses(processObj.undercoverOwners);
      }

      for (let i = 0; i < processObj.owners.length; i++) {
        if (
          (this._.has(processObj.owners[i], "individualCustomer.firstName") &&
            processObj.owners[i].individualCustomer.firstName.length > 0) ||
          (this._.has(processObj.owners[i], "businessCustomer.businessName") &&
            processObj.owners[i].businessCustomer.businessName.length > 0)
        ) {
          delete processObj.owners[i].customerName;
          continue;
        } else {
          processObj.owners.splice(i, 1);
        }
      }
      if (processObj.lessor != undefined) {
        if (processObj.lessor.mailingAddress?.address1 === "") {
          processObj.lessor.mailingAddress = this._.cloneDeep(
            processObj.lessor.physicalAddress
          );
        }
        if (processObj.lessor.physicalAddress != undefined) {
          processObj.lessor.physicalAddress.countyID = parseInt(
            processObj.lessor.physicalAddress.countyID
          );
        }
        if (
          processObj.lessor.customerName == undefined ||
          processObj.lessor.customerName == "" ||
          processObj.lessor.customerName == null
        ) {
          delete processObj.lessor;
        }
      }

      //this runs through all comments and uppercases them.
      if (processObj.tempComments != undefined) {
        if (processObj.tempComments.length > 0) {
          if (!this._.has(processObj.transaction, "comments")) {
            this.$set(processObj.transaction, "comments", []);
          }
          this.$set(processObj, "starComments", []);
          for (let i = 0; i < processObj.tempComments.length; i++) {
            if (processObj.tempComments[i].isGlobalComment) {
              processObj.transaction.comments.push(
                processObj.tempComments[i].comment.toUpperCase()
              );
            } else {
              processObj.starComments.push({
                comment: processObj.tempComments[i].comment.toUpperCase()
              });
            }
          }
        }
        delete processObj.tempComments;
      }
      if (this._.has(processObj, "transaction.comments")) {
        processObj.transaction.comments = processObj.transaction.comments.map(
          x => {
            return x.toUpperCase();
          }
        );
      }

      //if there is a lessor but no customer type, then customer type is buisiness and needs a buisiness name not customer name
      if (
        processObj.lessor != undefined &&
        ["", "B", undefined].includes(processObj.lessor.customerType)
      ) {
        processObj.lessor.customerType = "B";
        if (processObj.lessor.businessCustomer == undefined) {
          processObj.lessor.businessCustomer = {};
        }
        processObj.lessor.businessCustomer.businessName =
          processObj.lessor.customerName;
      }

      //setting all these to ints for api since they are strings for user data
      if (this._.has(processObj, "vehicle")) {
        processObj.vehicle.vin = processObj.vehicle.vin.toUpperCase();
        processObj.vehicle.modelYear = parseInt(
          processObj.vehicle.modelYear,
          10
        );
        processObj.vehicle.grossVehicleWeight = parseInt(
          processObj.vehicle.grossVehicleWeight,
          10
        );
        processObj.vehicle.axleCount = parseInt(
          processObj.vehicle.axleCount,
          10
        );

        if (this._.has(processObj, "vehicle.odometerTypeCode"))
          processObj.vehicle.odometerTypeCode =
            processObj.vehicle.odometerTypeCode.toString();

        if (processObj.vehicle.odometerTypeCode == "1") {
          //if no brand is chosen then we need to send a zero
          processObj.vehicle.odometerReading = 0;
        } else {
          processObj.vehicle.odometerReading = parseInt(
            processObj.vehicle.odometerReading,
            10
          );
        }
      }

      //setting these to ints and floats if they exist
      if (processObj.title !== undefined) {
        processObj.title.statusID = parseInt(processObj.title.statusID, 10);
        if (processObj.title.salePrice !== undefined) {
          processObj.title.salePrice = parseFloat(processObj.title.salePrice);
          processObj.title.vehicleCost = parseFloat(processObj.title.salePrice);
        }
      }
      //this removes the registration if the only thing in it is an empty plate. this happens if the user does title only
      let emptyreg = {
        currentPlate: { issueYear: null },
        newPlate: { issueYear: null }
      };
      if (JSON.stringify(processObj.registration) == JSON.stringify(emptyreg)) {
        delete processObj.registration;
      }

      //this formats the plates in registration, if the are the same it removes newPlate, if they are undefined it deletes them
      emptyreg = { issueYear: null };
      if (processObj.registration != undefined) {
        if (this.transactionType == "Restore Vehicle Record") {
          //this does not need to verify first and will not have fees.
          //rebuild transactions need plate as current plate to avoid inventory validation problems
          if (this._.has(processObj, "registration.newPlate")) {
            if (
              this._.has(
                processObj,
                "registration.newPlate.isTitleOnlyBuild"
              ) &&
              processObj.registration.newPlate.isTitleOnlyBuild
            ) {
              delete processObj.registration.currentPlate;
              delete processObj.registration.newPlate;
            } else {
              if (
                this._.has(processObj, "registration.newPlate.isTitleOnlyBuild")
              )
                delete processObj.registration.newPlate.isTitleOnlyBuild;
              processObj.registration.currentPlate =
                processObj.registration.newPlate ||
                processObj.registration.currentPlate;
              delete processObj.registration.newPlate;
            }
          }
        }

        ["newPlate", "currentPlate"].forEach(plate => {
          if (
            this._.has(processObj, "registration.plate") &&
            processObj.registration[plate].plateNo == "" &&
            processObj.registration[plate].class == ""
          ) {
            delete processObj.registration[plate];
          }
        });

        if (
          JSON.stringify(processObj.registration.currentPlate) ==
          JSON.stringify(emptyreg)
        ) {
          delete processObj.registration.currentPlate;
        }
        if (
          JSON.stringify(processObj.registration.newPlate) ==
          JSON.stringify(emptyreg)
        ) {
          delete processObj.registration.newPlate;
        }
        if (
          JSON.stringify(processObj.registration.newPlate) ==
          JSON.stringify(processObj.registration.currentPlate)
        ) {
          delete processObj.registration.newPlate;
        }
        if (
          processObj.registration.newPlate == undefined &&
          processObj.registration.currentPlate == undefined
        ) {
          delete processObj.registration;
        }
      }

      //format to int for endpoint, go green checkbox
      if (
        processObj.owners != undefined &&
        processObj.owners[0] != undefined &&
        processObj.owners[0].emailRenewalInd != undefined
      ) {
        processObj.owners[0].emailRenewalInd = parseInt(
          processObj.owners[0].emailRenewalInd
        );
      }

      processObj.transaction.countyID = parseInt(this.$store.getters.countyId);
      //this is set to verify so that it does not actually save the transaction here, if set to to false, it will perform the transaction without needing money.
      processObj.transaction.isVerify = true;

      if (this.transactionType == "Restore Vehicle Record") {
        //this does not need to verify first and will not have fees.

        processObj.transaction.isVerify = false;
      } else if (
        ["EZ Tag"].includes(this.transactionType) ||
        processObj.transaction.isDeficientFinish != undefined
      ) {
        //set this to false so it does the transaction instead of verify it
        processObj.transaction.isVerify = false;
      }

      //set leasedInd based on lessor
      if (processObj.lessor == undefined && this._.has(processObj, "vehicle")) {
        if (this._.has(processObj, "ownership"))
          processObj.ownership.leasedInd = false;
        processObj.vehicle.leasedInd = false;
      }

      //set color code if there is one
      if (this._.has(processObj, "vehicle")) {
        if (processObj.vehicle.majorColorCode != undefined) {
          processObj.vehicle.majorColorCode =
            processObj.vehicle.majorColorCode.toString();
        }
        if (processObj.vehicle.minorColorCode != undefined) {
          processObj.vehicle.minorColorCode =
            processObj.vehicle.minorColorCode.toString();
        }
      }

      if (this._.has(processObj, "placard")) {
        const endDate = dayjs(processObj.placard.endDate);
        if (endDate.isValid()) processObj.placard.endDate = endDate.format();
        if (processObj.placard.previousEndDate !== undefined)
          delete processObj.placard.previousEndDate;

        //this formats the begin date for api use
        if (
          processObj.placard.beginDate != undefined &&
          processObj.placard.beginDate.toString().substring(0, 10) !=
            "0001-01-01"
        ) {
          const date = new Date(processObj.placard.beginDate);
          let day = date.getDate();
          let month = date.getMonth() + 1;
          if (day < 10) day = "0" + day;
          if (month < 10) month = "0" + month;
          if (processObj.placard.beginDate != "") {
            processObj.placard.beginDate =
              date.getFullYear() + "-" + month + "-" + day;
            processObj.placard.beginDate =
              processObj.placard.beginDate + "T00:00:00" + localOffset;
          }
        }
      }

      // Formats the maintenance block to use int values
      if (this._.has(processObj, "maintenance.invoiceNo")) {
        processObj.maintenance.invoiceNo = parseInt(
          processObj.maintenance.invoiceNo
        );
      }

      if (this._.has(processObj, "maintenance.siteNo")) {
        processObj.maintenance.siteNo = parseInt(processObj.maintenance.siteNo);
      }

      if (this._.has(processObj, "maintenance.drawerNo")) {
        processObj.maintenance.drawerNo = parseInt(
          processObj.maintenance.drawerNo
        );
      }

      if (this._.has(processObj, "maintenance.createdUserID")) {
        processObj.maintenance.createdUserID = parseInt(
          processObj.maintenance.createdUserID
        );
      }

      this.clearEmpties(processObj);

      return processObj;
    },
    formatOwnerAddresses(owners) {
      for (let i = 0; i < owners.length; i++) {
        if (owners[i].customerID == null) {
          delete owners[i].customerID;
        }
        if (owners[i].physicalAddressID == null) {
          delete owners[i].physicalAddressID;
        }
        if (
          owners[i].physicalAddress != undefined &&
          owners[i].physicalAddress.countyID != undefined
        ) {
          owners[i].physicalAddress.countyID = parseInt(
            owners[i].physicalAddress.countyID
          );
        }
        if (
          owners[i].mailingAddress != undefined &&
          owners[i].mailingAddress.countyID != undefined
        ) {
          owners[i].mailingAddress.countyID = parseInt(
            owners[i].mailingAddress.countyID
          );
        }

        if (owners[i].deafInd != undefined) {
          owners[i].deafInd = parseInt(owners[i].deafInd);
        }

        if (owners[i].mailingAddress != undefined) {
          if (
            owners[i].mailingAddress.address1 == undefined ||
            owners[i].mailingAddress.address1.length == 0
          ) {
            delete owners[i].mailingAddress;
          }
        }
      }
    },
    clearEmpties(o) {
      for (const k in o) {
        if (!o[k] || typeof o[k] !== "object") {
          if (o[k] == undefined) {
            delete o[k];
          }
          continue; // If null or not an object, skip to the next iteration
        }

        // The property is an object
        this.clearEmpties(o[k]); // <-- Make a recursive call on the nested object
        if (Object.keys(o[k]).length === 0) {
          delete o[k]; // The object had no properties, so delete that property
        }
      }
    },
    gregorianToJulian(date) {
      if (date === null) return;
      const dateToday = dayjs(date);
      const startOfYear = dateToday.startOf("year").format();
      let daysThisYear = (
        dateToday.diff(dayjs(startOfYear)) / 86400000 +
        1
      ).toFixed(); // number of days since start of the year plus one (there are 86400000 milliseconds per day)
      const currentYear = dateToday.format("YY"); // last two digits of current year
      let i = 3 - daysThisYear.length; // the number of zeroes to pad with (minus one for zero index)
      while (i-- > 0) daysThisYear = 0 + daysThisYear;
      const julian = currentYear + daysThisYear; // concat current year and daysThisYear
      return julian;
    },
    julianToGregorian(julian, dateToChange = null) {
      if (julian.length !== 5)
        throw "Invalid Julian date. Must be 5 digits in the format yyddd where yy represents the two least significant digits of the year, and ddd represents the number of days that have passed this calendar year plus one padded left with '0's.";
      const yy = julian.slice(0, 2); // get two least sig figs of year
      const date = new Date("Jan 01," + yy + " 00:00:00");
      date.setDate(parseInt(julian.slice(2)));

      if (dateToChange !== null) dateToChange.setTime(date.getTime());

      return date;
    },
    getCurrentInvoice() {
      return this.$store.getters.invoiceNo;
    },
    removeEmptyComments(transactionRequest) {
      if (
        this._.has(transactionRequest, "transaction.comments") &&
        Array.isArray(transactionRequest.transaction.comments)
      ) {
        const comments = transactionRequest.transaction.comments;
        for (let i = comments.length; i > 0; i--) {
          if (comments[i] === "") comments.splice(i, 1);
        }
        if (comments.length === 0)
          delete transactionRequest.transaction.comments;
      }
    },
    defaultPlacardEndDate() {
      const placard =
        typeof this.placard === "object"
          ? this.placard
          : this.processObj.placard; // compatibility with components that have either just the placard or the full transaction request
      let endDate;
      const today = Object.freeze(new dayjs());

      // determine end date starting point
      endDate = today.clone();
      if (placard.previousEndDate !== undefined) {
        const gracePeriodEndDate = dayjs(placard.previousEndDate).add(
          90,
          "days"
        );
        if (!today.isAfter(gracePeriodEndDate))
          endDate = dayjs(placard.previousEndDate);
      }

      endDate.hour(0).minute(0).second(0);
      if (placard.placardTypeCode == "T") {
        placard.endDate = endDate.add(6, "months").format();
      } else {
        placard.endDate = endDate.add(2, "years").format();
      }
    },
    sseEvent(event) {
      // todo> we may want to move sse handling for star into a module for reusability
      const message = JSON.parse(event.data);
      const action = message.action;
      const data = message.data;

      switch (action) {
        /**
         * Using server sent events is more convenient for MSR transactions becuase
         * they can be 'canceled'. I've had problems with read and readSync when
         * canceling a transaction
         *
         * Also, there is more versitility for retreiving data from
         * any connected ID Tech or Magtek reader, as any such device can be
         * attached with an ondata event handler
         *
         */
        case "deviceInput":
          if (this.isCharging) {
            this.chargeMSR(data);
          }
          break;
      }
    },
    hubAppIsResponsiveCheck() {
      let alreadyShownError = false;
      const listener = () => {
        try {
          this.SSE = new EventSource("http://127.0.0.1:8085/SSE");
        } catch (error) {
          console.error(error);
        } // we create a new Event Source with each attempt to connect due to an issue with FireFox causing the SSE to close automatically when failing to connect
        this.SSE.addEventListener("event", e => this.sseEvent(e));
        this.SSE.addEventListener("error", () => {
          this.SSE.close();
          this.hubAppIsResponsive = false;
          if (!alreadyShownError) {
            this.showHubError = true;
            alreadyShownError = true;
          }
          this.SSETimeout = setTimeout(listener, 1000);
        });
        this.SSE.addEventListener("open", () => {
          alreadyShownError = false;
          this.hubAppIsResponsive = true;
        });
      };
      listener();
    }
  },
  computed: {
    ...mapGetters([
      "endOfTime",
      "inventoryPlateClasses",
      "titleTaxExemptReasons"
    ]),
    isRegistration() {
      return [
        "Registration Renewal",
        "Replace Plate",
        "Correction of Registration",
        "Temporary Operators Permit",
        "Lost/Stolen Plate or Decal",
        "Reprint Decal",
        "Change Plate Class",
        "Registration Only",
        "Wheel Tax"
      ].includes(this.transactionType);
    }
  }
};
