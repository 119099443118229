import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "vuetify";

import "core-js/stable";
import "regenerator-runtime/runtime";

import "leaflet/dist/leaflet.css";

import _ from "lodash";
import Axios from "axios";
import axiosPlugin from "./plugins/axios";
import api from "./assets/js/api/api.js";
import hubAppApi from "./assets/js/api/hubAppApi.js";

//components import for use on all pages
const TransactionsMenu = () =>
  import("@/components/nonPageComponents/TransactionsMenu");
const History = () => import("@/components/History");
const PlateHistoryResults = () => import("@/components/PlateHistoryResults");

import VueHtml2Canvas from "vue-html2canvas";
import "@/assets/css/global.scss";

//import "dwt/dist/addon/dynamsoft.barcodereader.initiate.js"; //dynamsoft barcode reader

import vueDebounce from "vue-debounce";

import { TablePlugin } from "bootstrap-vue";

Vue.config.productionTip = false;
Vue.use(vuetify);

// prototype extensions
Vue.prototype._ = _;

Vue.use(VueHtml2Canvas);

Vue.use(vueDebounce, {
  listenTo: ["input"]
});

Vue.use(TablePlugin);

Vue.use({
  install(Vue) {
    const axios = Axios.create();
    axios.defaults.baseURL = "http://127.0.0.1:8085";
    axios.interceptors.response.use(
      ({ data }) => {
        return data;
      },
      error => {
        if (!error.config.customErrorHandlers[error.response.status])
          return Promise.reject(error);
        return error.config.customErrorHandlers[error.response.status](error);
      }
    );
    Vue.prototype.$hubapp = hubAppApi(axios);
  }
});

//non-route pages
Vue.component("TransactionsMenu", TransactionsMenu);
Vue.component("history", History);
Vue.component("plateHistory", PlateHistoryResults);

Vue.use(axiosPlugin, {
  api: api(axiosPlugin, store, router),
  store,
  router
});

new Vue({
  router,
  store,
  vuetify: new vuetify(),
  beforeCreate() {
    // We need access to the instance in order to find the $root object
    // to make the push-alert work. Therefore we need to additionally add
    // these extra default error handlers here
    // Attempting to add the entire axios plugin inside of here causes
    // a race condition in which the $api is not always accessible
    axiosPlugin.axios.defaultErrorHandlers = Object.assign(
      axiosPlugin.axios.defaultErrorHandlers,
      {
        401: () => {
          this.$root.$emit("push-alert", "Your session has timed out!", {
            color: "error"
          });
          router.push({ name: "Login" });
        },
        500: error => {
          this.$root.$emit(
            "push-alert",
            "The server is currently experiencing issues, please try again",
            { color: "error" }
          );
          throw error;
        }
      }
    );
  },
  render: h => h(App)
}).$mount("#app");
