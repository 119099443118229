/**
 * Created by jordan.vanhoy on 7/3/2018.
 */

import { dashBoardDefault } from "@/assets/js/constants";
import _ from "lodash";
import dayjs from "dayjs";

const actions = {
  setGlobalAlertState({ commit }, alertParams) {
    commit("alertParams", alertParams);
    commit("showAlert", true);
  },
  hideGlobalAlert({ commit }) {
    commit("showAlert", false);
  },
  async getLocations({ getters, commit, dispatch }) {
    // skip if locations have already been axios'd
    if (getters.locations.length > 0) return;

    await dispatch("setConfig");
    const locations =
      (await this._vm.$api.getLocations(false, {
        404: () => {
          this._vm.$root.$emit("push-alert", "Initialization Failed", {
            color: "error"
          });
        }
      })) || [];

    locations.sort((a, b) => (a.countyName > b.countyName ? 1 : -1));

    for (const county of locations) {
      if (Array.isArray(county.offices)) {
        county.offices.sort((a, b) => (a.officeName > b.officeName ? 1 : -1));
        if (!county.offices) county.offices = [];
        for (const office of county.offices) {
          if (!office.workstations) office.workstations = [];
          office.workstations.sort((a, b) => {
            const regex = new RegExp(/^[Ww][Kk](\d+)$/);
            const matchesA = regex.exec(a.workstationName);
            const matchesB = regex.exec(b.workstationName);

            if (Array.isArray(matchesA) && Array.isArray(matchesB)) {
              return parseInt(matchesA[1]) - parseInt(matchesB[1]);
            }
            return a.workstationName > b.workstationName ? 1 : -1;
          });
        }
      }
    }

    commit("locations", Object.freeze(locations));
  },
  async getHubConfiguration({ commit }) {
    const configuration = await this._vm.$hubapp.getHubConfigs();
    commit("hubConfiguration", configuration);
  },
  async setHubConfiguration({ getters, commit }, config) {
    const hubConfiguration = Object.assign(
      {},
      getters.hubConfiguration,
      config
    );
    try {
      await this._vm.$hubapp.setHubConfigs(hubConfiguration).then(() => {
        commit("hubConfiguration", hubConfiguration);
      });
    } catch (error) {
      console.error();
    }
  },
  mergeOfficeConfig({ commit }, objToMerge) {
    commit("MERGE_OFFICE_CONFIG", objToMerge);
  },
  async VTRSVehicleInquire(
    { commit, getters, dispatch },
    { vehicle, usePrevResponse = false }
  ) {
    const VTRSVehicle = getters.VTRSVehicleRecord?.vehicle;
    if (
      !usePrevResponse ||
      VTRSVehicle?.vin !== vehicle.vin ||
      VTRSVehicle?.makeCode !== vehicle.makeCode ||
      parseInt(VTRSVehicle?.modelYear) !== parseInt(vehicle.modelYear)
    ) {
      try {
        const data = await this._vm.$api.VTRSVehicleInquiry(vehicle);
        commit("storeVTRSVehicleRecord", data);
      } catch (e) {
        dispatch("setGlobalAlertState", {
          title: "Error!",
          description: "VTRS Record Failed To Load.",
          icon: "error"
        });
        commit("storeVTRSVehicleRecord", null);
      }
    }
  },
  async VTRSPlacardInquiry({ commit }, placard) {
    try {
      const data = await this._vm.$api.VTRSPlacardInquiry(placard);
      commit("storeVTRSVehicleRecord", data[0]);
    } catch (e) {
      commit("storeVTRSVehicleRecord", null);
    }
  },
  clearPendingTransaction({ commit }) {
    commit("storeVTRSVehicleRecord", null);
    commit("resultsArray", []);
  },
  setPaymentFrameMessageHandler({ state, dispatch }, handler) {
    dispatch("removePaymentFrameMessageHandler");
    state.paymentFrameMessageHandler = handler;
    window.addEventListener("message", handler);
  },
  removePaymentFrameMessageHandler({ state }) {
    window.removeEventListener("message", state.paymentFrameMessageHandler);
  }
};
const state = {
  endOfTime: dayjs("9999-12-31"), // The use of the string '9999-12-21' in data originated from a TNClerk bug and may be removed in the future.
  isoDateTimeFormat: "YYYY-MM-DDTHH:mm:ssZ",
  BDPProps: {
    displayFormat: "MM/DD/YYYY", //Also used for hint
    TextField: {
      prependIcon: "event"
    },
    DatePicker: {
      "no-title": true
    },
    Menu: {
      ref: "menu",
      transition: "scale-transition",
      closeOnContentClick: false,
      "offset-y": true,
      "max-width": "290px",
      "min-width": "290px"
    }
  },
  dashboardSettings: dashBoardDefault,
  activeAxiosCalls: 0,
  installerPath: "",
  configState: "TN", // todo> get this from api
  countyId: undefined,
  officeId: undefined,
  siteNo: undefined,
  printMLHTitle: undefined,
  clerkInfo: {},
  drawerNo: "1",
  workstationId: undefined,
  invoiceNo: null,
  todaysInvoiceNo: null,
  plateClasses: [], //data for plateclass dropdowns
  inventoryPlateClasses: {}, //todo> incomplete alternative to plateClasses
  makes: [], //data for make dropdowns
  counties: {}, //data for dropdown
  titleTaxExemptReasons: {}, //data for tax exemption code dropdowns
  groups: {}, //user permission groups
  userObject: {
    extended: []
  }, //user information from login
  officeConfig: {
    autoTitlePrintTransactionCodes: [],
    promptForControlNoAfterTransaction: true,
    scanDocumentationAfterTransaction: true,
    digitallySign201: false
  },
  permissions: {},
  userPermissions: [], // todo> remove and replace with permissions above
  editPermissions: [], // todo> remove and replace with permissions above
  imagePermissions: [], // todo> remove and replace with permissions above
  resultsArray: [], //array of results from inquiry
  selectedIndex: "", //index of currently selected result
  placardNumber: "", //hold from search to fill in fields
  allTitleBrands: {},
  vehicleTypes: {},
  autoDrivingTypes: {},
  vehicleColors: {},
  bodyTypes: {},
  fuelTypes: {},
  titleCodes: {},
  titleCodeID: null,
  sideLinks: [],
  historyArray: [],
  partnerName: "",
  personalizedArray: [],
  isPurgeName: false,
  page: 0,
  titleNo: "",
  vin: "",
  vinLast8: "",
  plateNo: "",
  plateClass: "",
  plateClassIssueYear: "",
  makeCode: "",
  modelCode: "",
  name: "",
  modelYear: "",
  streetAddress: "",
  city: "",
  state: "",
  zip: "",
  streetNumber: "",
  code: "I",
  overrideComment: null,
  placardArray: [],
  placardIndex: "",
  dealerArray: {},
  dealerIndex: "",
  stopCodesArray: [],
  editStopsObj: {},
  plateHistory: [],
  odometerBrands: [],
  vehicleUse: [],
  transactionType: "",
  expRedir: false,
  simpleSearch: "",
  processObj: {},
  transObj: {},
  editObj: {},
  selectedObj: {},
  paymentObj: {
    cash: "0.00",
    check: "0.00",
    checkNo: "",
    credit: "0.00",
    creditNo: "",
    creditAuth: "",
    voucher: "",
    change: ""
  },
  regFeesObj: {},
  maintenance: {},
  autoCompleteArr: [],
  vinHistoryArray: [],
  instaTitlePrintFailure: false,
  barcode: {
    // information from a scanned barcode
    /*
         raw: string, // the raw string that the barcode represents
         parse: object // individual values parsed from the raw string (e.g. vin, plateNo)
         */
  },
  MVConfigurations: {},
  showNoResults: false,
  noResultsMenuOptions: [],
  noResultsMenuTitle: "",
  showAdvancedSearch: false,
  isAdvancedSearch: false,
  placardOwnerIndex: "0",
  oldCustomCSS: {},
  unfinishedDrafts: [],
  insuranceCompanies: [],
  toolTipDelay: 300,
  updateNotification: false,
  titleData: {},
  locations: [],
  hubConfiguration: undefined,
  STARSVehicleState: undefined, // the vehicle state according to the STARS back-end (less current than VTRS)
  VTRSVehicleRecord: undefined,
  paymentFrameMessageHandler: null,
  fromDeficientList: false,
  alertParams: {
    title: ""
  },
  showAlert: false,
  alertComponentValue: ""
};

const getters = {
  overrideComment: state => state.overrideComment,
  endOfTime: state => {
    return state.endOfTime;
  },
  officeConfig: state => {
    return state.officeConfig;
  },
  printMLHTitle: state => state.printMLHTitle,
  isoDateTimeFormat: state => {
    return state.isoDateTimeFormat;
  },
  clerkInfo: state => {
    return state.clerkInfo;
  },
  updateNotification: state => {
    return state.updateNotification;
  },
  dashboardSettings: state => {
    return JSON.parse(state.dashboardSettings);
  },
  toolTipDelay: state => {
    return state.toolTipDelay;
  },
  installerPath: state => {
    return state.installerPath;
  },
  insuranceCompanies: state => {
    return state.insuranceCompanies;
  },
  unfinishedDrafts: state => {
    return state.unfinishedDrafts;
  },
  workstationId: state => {
    return state.workstationId;
  },
  instaTitlePrintFailure: state => state.instaTitlePrintFailure,
  workstationName: (state, getters) => {
    return getters.workstation ? getters.workstation.workstationName : "";
  },
  workstations: state => {
    if (typeof state.countyId !== "number") return [];
    const county = state.locations.find(
      county => county.countyID === state.countyId
    );
    if (county === undefined) return [];
    const office = county.offices.find(
      office => office.officeID === state.officeId
    );
    if (office === undefined) return [];
    return office.workstations;
  },
  workstation: (state, getters) => {
    return getters.workstations.find(
      workstation => workstation.workstationID === state.workstationId
    );
  },
  drawerNo: state => {
    return state.drawerNo;
  },
  officeId: state => {
    return state.officeId;
  },
  invoiceNo: state => {
    return state.invoiceNo;
  },
  todaysInvoiceNo: state => {
    return state.todaysInvoiceNo;
  },
  noResultsMenuTitle: state => {
    return state.noResultsMenuTitle;
  },
  showNoResults: state => {
    return state.showNoResults;
  },
  placardNumber: state => {
    return state.placardNumber;
  },
  MVConfigurations: state => {
    return state.MVConfigurations;
  },
  vinHistoryArray: state => {
    const tmp = JSON.parse(JSON.stringify(state.vinHistoryArray));
    return tmp.reverse();
  },
  barcode: state => {
    return state.barcode;
  },
  itemsToPrint: state => {
    return state.itemsToPrint;
  },
  configState: state => {
    return state.configState;
  },
  countyId: state => {
    return state.countyId;
  },
  editObj: state => {
    return state.editObj;
  },
  paymentObj: state => {
    return state.paymentObj;
  },
  autoCompleteArr: state => {
    return state.autoCompleteArr;
  },
  //this formats an object to print the registration based on existing information in vuex.
  registrationObj: (state, getters) => {
    if (state.transObj.liens != undefined) {
      if (state.transObj.liens.length < 1) {
        state.transObj.liens[0] = {};
      }
    } else {
      state.transObj.liens = [];
      state.transObj.liens[0] = {};
    }
    if (state.transObj.owners != undefined) {
      if (state.transObj.owners.length < 1) {
        state.transObj.owners[0] = {};
      }
    } else {
      state.transObj.owners = [];
      state.transObj.owners[0] = {};
    }
    // return state.transObj
    // todo> remove hardcoding
    //todo change these the object assign on a defaulted template scaffold so we dont have to check every line.
    const regObj = {
      leftTable: {
        Invoice:
          state.transObj.transaction.invoiceNo != undefined
            ? state.transObj.transaction.invoiceNo
            : "",
        TimeStamp: "",
        "Station No": getters.workstationName + "/" + state.siteNo,
        "Clerk/Draw":
          state.drawerNo != undefined
            ? state.userObject.username + "/" + state.drawerNo
            : "",
        Cash: state.paymentObj.cash,
        Check: state.paymentObj.check,
        "Check #": state.paymentObj.checkNo,
        Credit: state.paymentObj.credit,
        "Credit #": state.paymentObj.creditNo,
        "CC Auth #": state.paymentObj.creditAuth,
        Voucher: state.paymentObj.voucher,
        Change: state.paymentObj.change
      },
      rightTable: state.regFeesObj,
      plateNo: "",
      stickerYear: "",
      titleNo: "",
      classNo: "",
      baseYear: "",
      expirationDate: "",
      transCode: "",
      workstation: getters.workstationName,
      drawer: state.drawerNo,
      dateOfIssue:
        _.get(state.transObj, "registration.registrationBeginDate") != undefined
          ? dayjs(state.transObj.registration.registrationBeginDate).format(
              "MM/DD/YYYY"
            )
          : "",
      make:
        state.transObj.vehicle != undefined
          ? state.transObj.vehicle.makeCode
          : "",
      model:
        state.transObj.vehicle != undefined
          ? state.transObj.vehicle.modelCode
          : "",
      year:
        state.transObj.vehicle != undefined
          ? state.transObj.vehicle.modelYear
          : "",
      body:
        state.transObj.vehicle != undefined
          ? state.transObj.vehicle.bodyTypeCode
          : "",
      color:
        state.transObj.vehicle != undefined
          ? state.transObj.vehicle.majorColorCode
          : "",
      fuel:
        state.transObj.vehicle != undefined
          ? state.transObj.vehicle.fuelTypeCode
          : "",
      lien:
        state.transObj.vehicle != undefined
          ? state.transObj.liens[0].lienID
          : "",
      vin:
        state.transObj.vehicle != undefined ? state.transObj.vehicle.vin : "",
      operatingZone: state.transObj.registration?.operatingZone || "",
      driverLicense: "",
      lease: "",
      emissionNo: "",
      vehicleNo: "",
      countySticker: "",
      citySticker: "",
      county:
        state.transObj.transaction != undefined
          ? state.counties[state.transObj.transaction.countyID]
          : "",
      countyClerk:
        state.clerkInfo !== undefined ? state.clerkInfo.clerkName : "",
      vehUse:
        state.transObj.vehicle != undefined
          ? state.transObj.vehicle.vehicleUseCode
          : "",
      regWeight: "",
      firstName: "",
      lastName: "",
      apartment: _.has(state, "transObj.owners[0].physicalAddress.address2")
        ? state.transObj.owners[0].physicalAddress.address2
        : "",
      streetAddress: "",
      city: _.has(state, "transObj.owners[0].physicalAddress.city")
        ? state.transObj.owners[0].physicalAddress.city
        : "",
      state: _.has(state, "transObj.owners[0].physicalAddress.state")
        ? state.transObj.owners[0].physicalAddress.state
        : "",
      zip: _.has(state, "transObj.owners[0].physicalAddress.postalCode")
        ? state.transObj.owners[0].physicalAddress.postalCode
        : "",
      inCareOf: _.has(state, "transObj.owners[0].inCareOf")
        ? state.transObj.owners[0].inCareOf
        : "",
      phoneNum: _.has(state, "transObj.owners[0].customerPhone")
        ? state.transObj.owners[0].customerPhone
        : "",
      streetAddressM: "",
      cityM: "",
      stateM: "",
      zipM: "",
      revenueStickerNo: "",
      titleBrandsString: ""
    };

    if (
      _.has(state, "transObj.vehicle.titleBrands") &&
      state.transObj.vehicle.titleBrands.length > 0
    ) {
      const titleBrands = state.transObj.vehicle.titleBrands;
      regObj.titleBrandsString =
        state.allTitleBrands[titleBrands[0].titleBrandID];
      for (let i = 1; i < titleBrands.length; i++) {
        regObj.titleBrandsString +=
          ", " + state.allTitleBrands[titleBrands[i].titleBrandID];
      }
    }

    if (state.transObj.transaction.transactionTimestamp != undefined) {
      regObj.leftTable.TimeStamp =
        state.transObj.transaction.transactionTimestamp
          .toString()
          .substr(11, 8);
    }
    if (_.has(state, "transObj.owners[0].physicalAddress")) {
      if (state.transObj.owners[0].physicalAddress.streetNo != undefined) {
        if (
          !state.transObj.owners[0].physicalAddress.address1.includes(
            state.transObj.owners[0].physicalAddress.streetNo
          )
        ) {
          regObj.streetAddress =
            state.transObj.owners[0].physicalAddress.streetNo +
            " " +
            state.transObj.owners[0].physicalAddress.address1;
        } else {
          regObj.streetAddress =
            state.transObj.owners[0].physicalAddress.address1;
        }
      } else {
        regObj.streetAddress =
          state.transObj.owners[0].physicalAddress.address1;
      }
    }

    if (state.transObj.transaction != undefined) {
      regObj.transCode = state.transObj.transaction.transactionType;
    }
    if (state.transObj.registration != undefined) {
      if (state.transObj.registration.newPlate != undefined) {
        regObj.plateNo = state.transObj.registration.newPlate.plateNo;
        regObj.classNo = state.transObj.registration.newPlate.class;
        regObj.baseYear = state.transObj.registration.newPlate.issueYear;
      } else if (state.transObj.registration.currentPlate != undefined) {
        regObj.plateNo = state.transObj.registration.currentPlate.plateNo;
        regObj.classNo = state.transObj.registration.currentPlate.class;
        regObj.baseYear = state.transObj.registration.currentPlate.issueYear;
      }
    }

    if (state.transObj.owners[0].mailingAddress != undefined) {
      regObj.streetAddressM = state.transObj.owners[0].mailingAddress.address1;
      regObj.cityM = state.transObj.owners[0].mailingAddress.city;
      regObj.stateM = state.transObj.owners[0].mailingAddress.state;
      regObj.zipM = state.transObj.owners[0].mailingAddress.postalCode;
      regObj.apartmentM = _.has(
        state,
        "transObj.owners[0].mailingAddress.address2"
      )
        ? state.transObj.owners[0].mailingAddress.address2
        : "";
    }
    if (state.transObj.registration != undefined) {
      if (state.transObj.registration.registrationExpiresDate != undefined) {
        const year = state.transObj.registration.registrationExpiresDate.substr(
          0,
          4
        );
        const month =
          state.transObj.registration.registrationExpiresDate.substr(5, 2);
        const day = state.transObj.registration.registrationExpiresDate.substr(
          8,
          2
        );
        regObj.expirationDate = month + "/" + day + "/" + year;
        regObj.stickerYear =
          state.transObj.registration.registrationExpiresDate.substr(2, 2);
      }
    }

    if (
      state.transObj.title != undefined &&
      state.transObj.title.titleNo != undefined
    ) {
      regObj.titleNo = state.transObj.title.titleNo;
    } else if (
      _.has(
        state,
        "resultsArray[" + state.selectedIndex + "].title.currentTitleNo"
      )
    ) {
      regObj.titleNo =
        state.resultsArray[state.selectedIndex].title.currentTitleNo;
    } else {
      regObj.titleNo = "";
    }

    if (regObj.titleNo == "" && _.has(state.titleData, "titleNo")) {
      regObj.titleNo = state.titleData.titleNo;
    }

    if (state.transObj.owners[0].businessCustomer != undefined) {
      regObj.firstName = state.transObj.owners[0].businessCustomer.businessName;
      regObj.lastName = "";
    } else {
      if (_.has(state, "transObj.owners[0].individualCustomer")) {
        regObj.firstName =
          state.transObj.owners[0].individualCustomer.firstName;
        regObj.lastName = state.transObj.owners[0].individualCustomer.lastName;
        if (
          state.transObj.owners[0].individualCustomer.middleName != undefined
        ) {
          regObj.lastName =
            state.transObj.owners[0].individualCustomer.middleName.substr(
              0,
              1
            ) +
            " " +
            regObj.lastName;
        }
        if (
          state.transObj.owners[0].individualCustomer.nameSuffix != undefined
        ) {
          regObj.lastName =
            regObj.lastName +
            " " +
            state.transObj.owners[0].individualCustomer.nameSuffix;
        }
      }
    }
    return JSON.parse(JSON.stringify(regObj));
  },
  transObj: state => {
    return _.cloneDeep(state.transObj);
  },
  activeAxiosCalls(state) {
    return state.activeAxiosCalls;
  },
  maintenance: state => {
    return state.maintenance;
  },
  simpleSearch: state => {
    return state.simpleSearch;
  },
  transactionType: state => {
    return state.transactionType;
  },
  vehicleUse: state => {
    return state.vehicleUse;
  },
  odometerBrands: state => {
    return state.odometerBrands;
  },
  odometerBrandCodes: state => {
    return _.invert(state.odometerBrands);
  },
  allTitleBrands: state => {
    return state.allTitleBrands;
  },
  processObj: state => {
    return state.processObj;
  },
  BDPProps: state => {
    return state.BDPProps;
  },
  vehicle: state => {
    return state.processObj.vehicle;
  },
  plateHistory: state => {
    return state.plateHistory;
  },
  editStopsObj: state => {
    return state.editStopsObj;
  },
  stopCodesArray: state => {
    return state.stopCodesArray;
  },
  alertParams: state => {
    return state.alertParams;
  },
  placardOwnerIndex: state => state.placardOwnerIndex,
  showAlert: state => {
    return state.showAlert;
  },
  alertComponentValue: state => {
    return state.alertComponentValue;
  },
  code: state => {
    return state.code;
  },
  streetNumber: state => {
    return state.streetNumber;
  },
  zip: state => {
    return state.zip;
  },
  state: state => {
    return state.state;
  },
  city: state => {
    return state.city;
  },
  streetAddress: state => {
    return state.streetAddress;
  },
  modelYear: state => {
    return state.modelYear;
  },
  name: state => {
    return state.name;
  },
  modelCode: state => {
    return state.modelCode;
  },
  makeCode: state => {
    return state.makeCode;
  },
  titleTaxExemptReasons: state => {
    return state.titleTaxExemptReasons;
  },
  plateClassIssueYear: state => {
    return state.plateClassIssueYear;
  },
  plateClass: state => {
    return state.plateClass;
  },
  plateNo: state => {
    return state.plateNo;
  },
  vinLast8: state => {
    return state.vinLast8;
  },
  vin: state => {
    return state.vin;
  },
  titleNo: state => {
    return state.titleNo;
  },
  titleData: state => {
    return state.titleData;
  },
  plateClasses: state => {
    return state.plateClasses;
  },
  inventoryPlateClasses: state => {
    return state.inventoryPlateClasses;
  },
  makes: state => {
    return state.makes;
  },
  counties: state => {
    return state.counties;
  },
  groups: state => {
    return state.groups;
  },
  userObject: state => {
    return state.userObject;
  },
  permissions: state => {
    return state.permissions;
  },
  userPermissions: state => {
    return state.userPermissions;
  },
  editPermissions: state => {
    return state.editPermissions;
  },
  imagePermissions: state => {
    return state.imagePermissions;
  },
  resultsArray: state => {
    return state.resultsArray;
  },
  selectedIndex: state => {
    return state.selectedIndex;
  },
  vehicleTypes: state => {
    return state.vehicleTypes;
  },
  autoDrivingTypes: state => {
    return state.autoDrivingTypes;
  },
  vehicleColors: state => {
    return state.vehicleColors;
  },
  bodyTypes: state => {
    return state.bodyTypes;
  },
  fuelTypes: state => {
    return state.fuelTypes;
  },
  titleCodes: state => {
    return state.titleCodes;
  },
  titleCodeID: state => {
    return state.titleCodeID;
  },
  sideLinks: state => {
    return state.sideLinks;
  },
  historyArray: state => {
    return state.historyArray;
  },
  partnerName: state => {
    return state.partnerName;
  },
  personalizedArray: state => {
    return state.personalizedArray;
  },
  isPurgeName: state => {
    return state.isPurgeName;
  },
  page: state => {
    return state.page;
  },
  placardArray: state => {
    return state.placardArray;
  },
  placardIndex: state => {
    return state.placardIndex;
  },
  dealerArray: state => {
    return state.dealerArray;
  },
  dealerIndex: state => {
    return state.dealerIndex;
  },
  expRedir: state => {
    return state.expRedir;
  },
  regFeesObj: state => {
    return state.regFeesObj;
  },
  noResultsMenuOptions: state => {
    return state.noResultsMenuOptions;
  },
  showAdvancedSearch: state => {
    return state.showAdvancedSearch;
  },
  isAdvancedSearch: state => {
    return state.isAdvancedSearch;
  },
  oldCustomCSS: state => {
    return state.oldCustomCSS;
  },
  locations: state => state.locations,
  hubConfiguration: state => _.cloneDeep(state.hubConfiguration),
  pendingTransaction(state, getters) {
    const pendingTransaction =
      _.get(getters, "VTRSVehicleRecord.pendingTransaction") ||
      _.get(getters, "STARSVehicleRecord.pendingTransaction");
    return _.cloneDeep(pendingTransaction);
  },
  pendingTransactionRequest(state, getters) {
    if (!getters.pendingTransaction) return null;
    return JSON.parse(getters.pendingTransaction.transactionJSON);
  },
  STARSVehicleRecord(state, getters) {
    if (!state.resultsArray || state.resultsArray.length === 0) return null;
    if (state.resultsArray.length === 1) return state.resultsArray[0];
    if (getters.processObj && getters.processObj.vehicle) {
      return state.resultsArray.find(
        result =>
          result.vehicle.vin === getters.processObj.vehicle.vin &&
          result.vehicle.makeCode === getters.processObj.vehicle.makeCode &&
          result.vehicle.issueYear === getters.processObj.vehicle.issueYear
      );
    }
    return null;
  },
  VTRSVehicleRecord(state) {
    return state.VTRSVehicleRecord;
  },
  fromDeficientList(state) {
    return state.fromDeficientList;
  }
};

//=====================================================================mutators================================================================
//=====================================================================mutators================================================================
//=====================================================================mutators================================================================
//=====================================================================mutators================================================================

const mutations = {
  overrideComment(state, n) {
    state.overrideComment = n;
  },
  clerkInfo(state, n) {
    state.clerkInfo = n;
  },
  placardOwnerIndex(state, n) {
    state.placardOwnerIndex = n;
  },
  alertParams(state, n) {
    state.alertParams = n;
  },
  showAlert: (state, n) => {
    state.showAlert = n;
  },
  alertComponentValue: (state, n) => {
    state.alertComponentValue = n;
  },
  officeConfig(state, n) {
    state.officeConfig = n;
  },
  MERGE_OFFICE_CONFIG(state, objToMerge) {
    state.officeConfig = Object.assign(state.officeConfig, objToMerge);
  },
  updateNotification(state, n) {
    state.updateNotification = n;
  },
  dashboardSettings(state, n) {
    state.dashboardSettings = JSON.stringify(n);
  },
  toolTipDelay(state, n) {
    state.toolTipDelay = n;
  },
  installerPath(state, n) {
    state.installerPath = n;
  },
  insuranceCompanies(state, n) {
    state.insuranceCompanies = n;
  },
  unfinishedDrafts(state, n) {
    state.unfinishedDrafts = n;
  },
  addUnfinishedDraft(state, n) {
    state.unfinishedDrafts.unshift(n);
  },
  removeUnfinishedDraft(state, n) {
    state.unfinishedDrafts.splice(n, 1);
  },
  workstationId(state, n) {
    state.workstationId = n;
  },
  drawerNo(state, n) {
    state.drawerNo = n;
  },
  officeId(state, n) {
    state.officeId = n;
  },
  siteNo(state, n) {
    state.siteNo = n;
  },
  invoiceNo(state, n) {
    const invoiceNo = parseInt(n);

    if (!Number.isInteger(invoiceNo) || invoiceNo.toString().length !== 5)
      throw "Attempted to set invalid invoice number: " + n;

    state.invoiceNo = invoiceNo;
  },
  todaysInvoiceNo(state, n) {
    state.todaysInvoiceNo = n;
  },
  showAdvancedSearch(state, n) {
    state.showAdvancedSearch = n;
  },
  isAdvancedSearch(state, n) {
    state.isAdvancedSearch = n;
  },
  noResultsMenuTitle(state, n) {
    state.noResultsMenuTitle = n;
  },
  noResultsMenuOptions(state, n) {
    state.noResultsMenuOptions = n;
  },
  showNoResults(state, n) {
    state.showNoResults = n;
  },
  placardNumber(state, n) {
    state.placardNumber = n;
  },
  MVConfigurations(state, n) {
    state.MVConfigurations = n;
  },
  vinHistoryArrayADD(state, n) {
    if (state.vinHistoryArray.length < 20) {
      state.vinHistoryArray.push(n);
    } else {
      state.vinHistoryArray.splice(0, 1);
      state.vinHistoryArray.push(n);
    }
  },
  vinHistoryArrayReplace(state, n) {
    state.vinHistoryArray = n.reverse();
  },
  configState(state, n) {
    state.configState = n;
  },
  countyId(state, n) {
    state.countyId = n;
  },
  regFeesObj(state, n) {
    state.regFeesObj = n;
  },
  printMLHTitle(state, n) {
    state.printMLHTitle = n;
  },
  editObj(state, n) {
    state.editObj = n;
  },
  paymentObj(state, n) {
    state.paymentObj = n;
  },
  transObj(state, n) {
    state.transObj = n;
  },
  maintenance(state, n) {
    state.maintenance = n;
  },
  simpleSearch(state, n) {
    state.simpleSearch = n;
  },
  transactionType(state, n) {
    state.transactionType = n;
  },
  activeAxiosCalls(state, n) {
    state.activeAxiosCalls = n;
  },
  vehicleUse(state, n) {
    state.vehicleUse = n;
  },
  odometerBrands(state, n) {
    state.odometerBrands = n;
  },
  instaTitlePrintFailure(state, n) {
    state.instaTitlePrintFailure = n;
  },
  allTitleBrands(state, n) {
    state.allTitleBrands = n;
  },
  processObj(state, n) {
    if (
      n.transaction !== undefined &&
      n.transaction.transactionType === undefined
    ) {
      n.transaction.transactionType = "";
    }

    if (
      n.registration !== undefined &&
      n.registration.registrationExpiresDate === undefined
    ) {
      n.registration.registrationExpiresDate = "";
    }
    state.processObj = n;
  },
  BDPProps(state, n) {
    state.BDPProps = n;
  },
  plateHistory(state, n) {
    state.plateHistory = n;
  },
  editStopsObj(state, n) {
    state.editStopsObj = n;
  },
  stopCodesArray(state, n) {
    state.stopCodesArray = n;
  },
  plateClasses(state, n) {
    state.plateClasses = n;
  },
  inventoryPlateClasses(state, n) {
    state.inventoryPlateClasses = n;
  },
  makes(state, n) {
    state.makes = n;
  },
  counties(state, n) {
    state.counties = n;
  },
  groups(state, n) {
    state.groups = n;
  },
  userObject(state, n) {
    if (!_.has(n, "extended") || n.extended === null) n.extended = [];

    state.userObject = n;
  },
  permissions(state, n) {
    state.permissions = n;
  },
  userPermissions(state, n) {
    state.userPermissions = n;
  },
  editPermissions(state, n) {
    state.editPermissions = n;
  },
  imagePermissions(state, n) {
    state.imagePermissions = n;
  },
  resultsArray(state, n) {
    state.resultsArray = n;
  },
  selectedIndex(state, n) {
    state.selectedIndex = n;
  },
  vehicleTypes(state, n) {
    state.vehicleTypes = n;
  },
  autoDrivingTypes(state, n) {
    state.autoDrivingTypes = n;
  },
  vehicleColors(state, n) {
    state.vehicleColors = n;
  },
  bodyTypes(state, n) {
    state.bodyTypes = n;
  },
  fuelTypes(state, n) {
    state.fuelTypes = n;
  },
  titleCodes(state, n) {
    state.titleCodes = n;
  },
  titleCodeID(state, n) {
    state.titleCodeID = n;
  },
  sideLinks(state, n) {
    state.sideLinks = n;
  },
  historyArray(state, n) {
    state.historyArray = n;
  },
  partnerName(state, n) {
    state.partnerName = n;
  },
  personalizedArray(state, n) {
    state.personalizedArray = n;
  },
  isPurgeName(state, n) {
    state.isPurgeName = n;
  },
  page(state, n) {
    state.page = n;
  },
  streetNumber(state, n) {
    state.streetNumber = n;
  },
  zip(state, n) {
    state.zip = n;
  },
  state(state, n) {
    state.state = n;
  },
  city(state, n) {
    state.city = n;
  },
  streetAddress(state, n) {
    state.streetAddress = n;
  },
  modelYear(state, n) {
    state.modelYear = n;
  },
  name(state, n) {
    state.name = n;
  },
  modelCode(state, n) {
    state.modelCode = n;
  },
  makeCode(state, n) {
    state.makeCode = n;
  },
  titleTaxExemptReasons(state, n) {
    state.titleTaxExemptReasons = n;
  },
  plateClassIssueYear(state, n) {
    state.plateClassIssueYear = n;
  },
  plateClass(state, n) {
    state.plateClass = n;
  },
  plateNo(state, n) {
    state.plateNo = n;
  },
  vinLast8(state, n) {
    state.vinLast8 = n;
  },
  vin(state, n) {
    state.vin = n;
  },
  titleNo(state, n) {
    state.titleNo = n;
  },
  titleData(state, n) {
    state.titleData = n;
  },
  code(state, n) {
    state.code = n;
  },
  placardArray(state, n) {
    state.placardArray = n;
  },
  placardIndex(state, n) {
    state.placardIndex = n;
  },
  dealerArray(state, n) {
    state.dealerArray = n;
  },
  dealerIndex(state, n) {
    state.dealerIndex = n;
  },
  expRedir(state, n) {
    state.expRedir = n;
  },
  autoCompleteArr(state, n) {
    state.autoCompleteArr = n;
  },
  barcode(state, n) {
    state.barcode = n;
  },
  oldCustomCSS(state, n) {
    state.oldCustomCSS = n;
  },
  locations(state, n) {
    state.locations = n;
  },
  hubConfiguration(state, n) {
    state.hubConfiguration = n;
  },
  storeVTRSVehicleRecord(state, n) {
    state.VTRSVehicleRecord = n;
  },
  fromDeficientList(state, n) {
    state.fromDeficientList = n;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
