import customCSSFunctions from "@/mixins/customCSSFunctions.js";
import JSONBigInt from "json-bigint";
import { permissionMap } from "@/assets/js/constants";
export default {
  mixins: [customCSSFunctions],
  methods: {
    async doStarInit(apiToken, storeHold) {
      // parse token
      const JWTPayloadString = atob(apiToken.split(".")[1]);
      const JWTPayload = JSONBigInt.parse(JWTPayloadString);

      const permissions = {}; // container for permissions organized by api
      const apiNames = Object.keys(permissionMap);
      // populate permissions object
      for (let apiIndex = 0; apiIndex < apiNames.length; apiIndex++) {
        const apiName = apiNames[apiIndex];
        const permissionNames = Object.keys(permissionMap[apiName]);
        permissions[apiName] = {};
        let permissionSet = [];
        for (
          let permissionSubsetIndex = 0;
          permissionSubsetIndex < JWTPayload.per[apiName].length;
          permissionSubsetIndex++
        ) {
          const permissionSubset = JWTPayload.per[apiName][
            permissionSubsetIndex
          ]
            .toString(2) // convert to binary string
            .split("") // convert to binary array
            .reverse(); // reverse binary array so that the least significant bit is at position 0

          permissionSet = permissionSet.concat(permissionSubset);
        }
        for (
          let permissionIndex = 0;
          permissionIndex < permissionNames.length;
          permissionIndex++
        ) {
          const permissionName = permissionNames[permissionIndex];

          permissions[apiName][permissionName] =
            permissionSet[permissionMap[apiName][permissionName]] === "1";
        }
      }
      storeHold.commit("permissions", permissions);
      storeHold.commit("userPermissions", JWTPayload.per.tandr || ""); // todo> does this exist?
      storeHold.commit("editPermissions", JWTPayload.per.Triggers || ""); // todo> does this exist?
      storeHold.commit("imagePermissions", JWTPayload.per.gallery || ""); // todo> does this exist?
      const authBaseUrl = storeHold.getters.apiHostAndPort;

      const requiredPromiseArray = [];
      await storeHold._vm.$api
        .checkUserAuth(apiToken, authBaseUrl)
        .then(data => {
          storeHold.commit("userObject", data);

          if (!storeHold.getters.permissions.star.access) {
            this.$store.dispatch("setGlobalAlertState", {
              title: "Unauthorized",
              description: "This user is not currently active.",
              icon: "error"
            });
            storeHold.commit("userObject", {});
          }
          if (storeHold.getters.groups != undefined) {
            const userObjectTemp = storeHold.getters.userObject;
            userObjectTemp["groupNames"] = [];
            const groupKeys = Object.keys(storeHold.getters.groups);
            for (let i = 0; i < groupKeys.length; i++) {
              for (let k = 0; k < userObjectTemp.memberOf.length; k++) {
                if (userObjectTemp.memberOf[k] == groupKeys[i]) {
                  userObjectTemp.groupNames.push(groupKeys[i]);
                }
              }
            }
            storeHold.commit("userObject", userObjectTemp);
          }
        })
        .catch(e => {
          throw e;
        });
      //get user configurations
      requiredPromiseArray.push(
        storeHold._vm.$api
          .getThemeSettings(
            storeHold.getters.userObject.id,
            { 404: () => {} },
            apiToken
          )
          .then(data => {
            if (!data) {
              const emptyConfig = require("@/assets/jsonScaffolds/MVConfigurationScaffold.json");
              storeHold.commit(
                "MVConfigurations",
                JSON.parse(JSON.stringify(emptyConfig))
              );
            } else {
              storeHold.commit("MVConfigurations", JSON.parse(data));
              const customCSS = JSON.parse(
                JSON.stringify(storeHold.getters.MVConfigurations.customCSS)
              );
              storeHold.commit(
                "oldCustomCSS",
                JSON.parse(JSON.stringify(customCSS))
              );
            }
          })
      );

      requiredPromiseArray.push(
        storeHold._vm.$api
          .dashboardSettings(storeHold.getters.userObject.id, apiToken, {
            404: () => {}
          })
          .then(data => {
            if (data) {
              storeHold.commit("dashboardSettings", JSON.parse(data.dash));
            }
          })
          .catch(() => {})
      );

      const setupArray = [
        {
          endpoint: "/star/inventory/plateclass",
          storeVal: "inventoryPlateClasses",
          required: true
        },
        {
          endpoint: "/star/vehicle/maintenance/makes",
          storeVal: "makes",
          required: true
        },
        {
          endpoint: "/star/vehicle/maintenance/vehicletypes",
          storeVal: "vehicleTypes",
          required: false
        },
        {
          endpoint: "/star/lookup/counties",
          storeVal: "counties",
          required: false,
          baseURL: "apiHostAndPort"
        },
        {
          endpoint:
            "/location/county/search?countyID=" + storeHold.getters.countyId,
          storeVal: "clerkInfo",
          required: false,
          baseURL: "apiHostAndPort",
          mutation: data => {
            return data[0];
          }
        },
        {
          endpoint: "/star/vehicle/maintenance/vehiclecolors",
          storeVal: "vehicleColors",
          required: false,
          mutation: data => {
            const keys = Object.keys(data);
            const sortable = [];
            for (let i = 0; i < keys.length; i++) {
              const temp = { key: keys[i], value: data[keys[i]] };
              sortable.push(temp);
            }
            sortable.sort((a, b) => {
              const x = a.value;
              const y = b.value;
              return x < y ? -1 : x > y ? 1 : 0;
            });
            return sortable;
          }
        },
        {
          endpoint: "/lookup/VehicleAutoDriverInd",
          storeVal: "autoDrivingTypes",
          required: false,
          mutation: data => {
            const keys = Object.keys(data);
            const sortable = [];
            for (let i = 0; i < keys.length; i++) {
              const description = keys[i] + " - " + data[keys[i]];
              const temp = { key: parseInt(keys[i]), value: description };
              sortable.push(temp);
            }
            sortable.sort((a, b) => {
              const x = a.value;
              const y = b.value;
              return x < y ? -1 : x > y ? 1 : 0;
            });
            return sortable;
          }
        },
        {
          endpoint: "/star/vehicle/maintenance/bodytypes",
          storeVal: "bodyTypes",
          required: false
        },
        {
          endpoint: "/star/vehicle/maintenance/fueltypes",
          storeVal: "fuelTypes",
          required: false,
          mutation: data => {
            const sorted = Object.values(data);
            sorted.sort((a, b) => {
              const x = a.FuelTypeDescription;
              const y = b.FuelTypeDescription;
              return x < y ? -1 : x > y ? 1 : 0;
            });
            return sorted;
          }
        },
        {
          endpoint: "/star/lookup/titlestatuscodes",
          storeVal: "titleCodes",
          required: false,
          baseURL: "apiHostAndPort"
        },
        {
          endpoint: "/star/vehicle/maintenance/plateclasses",
          storeVal: "plateClasses",
          required: true,
          mutation: data => {
            const sorted = data.slice(0);
            sorted.sort((a, b) => {
              const x = a.plateClassCode;
              const y = b.plateClassCode;
              return x < y ? -1 : x > y ? 1 : 0;
            });
            return sorted;
          }
        },
        {
          endpoint: "/star/lookup/OdometerBrandCode",
          storeVal: "odometerBrands",
          required: false,
          baseURL: "apiHostAndPort"
        },
        {
          endpoint: "/star/lookup/vehicleuse",
          storeVal: "vehicleUse",
          required: false,
          baseURL: "apiHostAndPort"
        },
        {
          endpoint: "/star/lookup/titlebrands",
          storeVal: "allTitleBrands",
          required: false,
          baseURL: "apiHostAndPort"
        },
        {
          endpoint: "/star/financialresponsibility/insurancecompany",
          storeVal: "insuranceCompanies",
          required: false
        },
        {
          endpoint: "/lookup/webrenewalrejectreason",
          storeVal: "webRenewalRejectReasons",
          required: false,
          baseURL: "apiHostAndPort"
        },
        {
          endpoint: "/star/lookup/transactionstatus",
          storeVal: "transactionStatuses",
          required: false,
          baseURL: "apiHostAndPort"
        },
        {
          endpoint: "/auth/users", // todo> narrow this by county
          storeVal: "users",
          required: true,
          baseURL: "apiHostAndPort"
        },
        {
          endpoint: "/star/lookup/TitleTaxExemptCode",
          storeVal: "titleTaxExemptReasons",
          required: true,
          baseURL: "apiHostAndPort"
        },
        {
          endpoint:
            "/kv/county." +
            storeHold.getters.countyId +
            "/newmetalbatchclasses",
          storeVal: "newMetalPlateClasses",
          required: true,
          mutation: data => {
            return JSON.parse(data).classes;
          }
        },
        {
          endpoint:
            "/kv/county." + storeHold.getters.countyId + "/next.plate.config",
          storeVal: "plateAssignConfig",
          required: true
        }
      ];
      for (let i = 0; i < setupArray.length; i++) {
        requiredPromiseArray.push(
          storeHold._vm.$api
            .initialize(setupArray[i].endpoint, apiToken, { 404: () => {} })
            .then(response => {
              if ([null, undefined, ""].includes(response)) {
                if (setupArray[i].required === true) {
                  // Get out if we don't have a required property
                  throw new Error(
                    `Required Initialization Request Failed for ${setupArray[i].endpoint}`
                  );
                } else {
                  console.warn(
                    `Initialization Endpoint '${setupArray[i].endpoint}' gave a status of ${response.status}`
                  );
                }
              }
              if (setupArray[i].mutation !== undefined) {
                storeHold.commit(
                  setupArray[i].storeVal,
                  setupArray[i].mutation(response)
                );
              } else {
                storeHold.commit(setupArray[i].storeVal, response);
              }
            })
        );
      }
      requiredPromiseArray.push(storeHold.dispatch("getLocations"));

      if (storeHold.getters.countyConfig.hasWheelTaxCities)
        requiredPromiseArray.push(storeHold.dispatch("getCountyCities"));

      return Promise.all(requiredPromiseArray).then(() => {
        storeHold.dispatch("setApiToken", apiToken);
      });
    }
  }
};
