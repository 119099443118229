export default function () {
  return {
    nameCode: "0",
    ownershipID: 0,
    vehicleID: 0,
    purchaseDate: null,
    conjunctionCode: "",
    insurancePolicyNo: null,
    sellDate: null,
    statusCode: "",
    statusDate: null,
    lastUpdateUserID: 0,
    leasedInd: false,
    undercoverInd: false,
    vaGrantNo: "",
    vaGrantAmount: 0,
    isNewOwner: false,
    ownerTypeCode: null
  };
}
