<template>
  <div>
    <div id="search" autocomplete="false">
      <div class="search-field">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              :class="{ invisible: advancedSearchIsVisible }"
              id="mainNavOmniSearchInput"
              data-testid="mainNavOmniSearchInput"
              autofocus
              hide-details
              clearable
              v-model="simpleSearch"
              @keyup.enter="doInquiry()"
              placeholder="Search"
            >
              <template slot="append">
                <v-icon color="primary" @click="doInquiry()"
                  >mdi-magnify</v-icon
                >
              </template>
            </v-text-field>
          </template>
          <span
            >Search by VIN, plate, title, name, etc. Use "Ctrl + s" key to
            focus</span
          >
        </v-tooltip>

        <v-tooltip bottom class="mr-5">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon small @click="advancedSearchToggle">
              <v-icon color="primary">
                <template v-if="!advancedSearchIsVisible">
                  mdi-chevron-down
                </template>
                <template v-else>mdi-chevron-up</template>
              </v-icon>
            </v-btn>
          </template>
          <span> Advanced Search </span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import vehicleFunctions from "@/mixins/vehicleFunctions.js";
import searchFunctions from "@/mixins/searchFunctions.js";
import { states } from "@/assets/js/constants";
export default {
  data() {
    return {
      show: false,
      plateClassArray: this.$store.getters.plateClasses,
      makesArrayDisplay: [],
      titleNo: "",
      vin: "",
      VINLast: "",
      plateNo: "",
      placard: "",
      dealerPlate: "",
      plateClass: "",
      plateClassIndex: "",
      PlateClassIssueYear: "",
      makeCode: "",
      modelCode: "",
      name: "",
      streetAddress: "",
      city: "",
      state: "",
      ZIP: "",
      year: "",
      issueYearArray: "",
      code: "I",
      barCode: "",
      barCodeLastTime: Date.now(),
      advancedSearch: false,
      simpleSearch: "",
      inquiryRunning: false,
      states: states,
      addressPropMap: {
        street: {
          object: this,
          prop: "street"
        },
        city: {
          object: this,
          prop: "city"
        },
        state: {
          object: this,
          prop: "state"
        },
        zipCode: {
          object: this,
          prop: "ZIP"
        }
      },
      noResultsMenuTitle: "",
      noResultsMenuOptions1: []
    };
  },
  mixins: [vehicleFunctions, searchFunctions],
  methods: {
    clearSimple() {
      this.simpleSearch = "";
      document.querySelector("#search-input").focus();
    }
  },
  computed: {
    advancedSearchIsVisible() {
      return this.$store.getters.showAdvancedSearch;
    }
  }
};
</script>

<style scoped>
#search {
  display: flex;
  align-items: center;
}

#search .search-btn {
  margin-top: -14px;
}

#search .search-box {
  margin-top: 25px;
}

#search .white {
  color: white;
}

.search-field {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
