export default function () {
  return {
    titleID: 0,
    titleNo: "",
    vehicleID: 0,
    ownershipID: 0,
    controlNo: null,
    outStateTaxCredit: null,
    outStateTaxState: null,
    salePrice: "",
    dealerCollectedTax: null,
    tradeAllowance: null,
    vehicleCost: null,
    warrantyCost: 0,
    titleReIssueReason: null,
    taxExemptCode: null,
    titleIssueDate: null,
    imsOriginalDate: null,
    saleTypeCode: null,
    titleBrandRemark: null,
    statusID: 0,
    statusDate: null,
    formerTNTitleID: null,
    formerTitleNo: null,
    formerTitleState: null,
    formerIssueDate: null,
    surrenderStates: null,
    nadaRetailValue: null,
    fairMarketValue: null,
    stateItemTypeID: 0,
    lastUpdateUserID: 0,
    isSalvage: false,
    assignTitleNo: false
  };
}
