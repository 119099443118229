export default function address() {
  return {
    address1: "",
    address2: "",
    addressType: "",
    city: "",
    state: "",
    zip: ""
  };
}
