var inquiryDefaults = {
  search: () => {
    return {
      titleNo: "",
      vin: "",
      vinLast8: "",
      plateNo: "",
      plateClass: "",
      plateClassIssueYear: "",
      makeCode: "",
      modelCode: "",
      name: "",
      modelYear: "",
      streetAddress: "",
      city: "",
      state: "",
      zip: "",
      partialPlateNo: "",
      disabledPlacard: "",
      bodyType: "",
      titanBodyCode: "",
      majorColorCode: "",
      minorColorCode: "",
      page: 0
    };
  }
};

module.exports = {
  inquiryDefaults
};
