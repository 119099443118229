import { make } from "vuex-pathify";

const state = {
  apiToken: "",
  monetraUser: null,
  monetraPass: null
};

const mutations = make.mutations(state);
// automatically create mutations (e.g. setLoading, setError)

const actions = {
  ...make.actions(state),
  async getMonetraCreds({ commit }) {
    try {
      const response = await this._vm.$api.monetraUser();
      commit("SET_MONETRA_USER", response.username);
      commit("SET_MONETRA_PASS", response.password);
    } catch (error) {
      console.error(error);
    }
  }
};

const getters = {
  ...make.getters(state),
  tokenExpiration: state => {
    try {
      if (state.apiToken === "")
        throw "Attempted to get expiration of auth token when no auth token is stored.";
      return (
        parseFloat(JSON.parse(atob(state.apiToken.split(".")[1])).exp) * 1000
      );
    } catch (e) {
      console.error(e);
      return "";
    }
  },
  isLoggedIn(state, getters) {
    return (
      typeof state.apiToken === "string" &&
      state.apiToken.length > 0 &&
      getters.tokenExpiration - new Date().getTime() > 0
    );
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
