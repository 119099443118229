<template>
  <v-app id="app" v-cloak>
    <div :class="{ versionNum: true, underNavBar: showNavBar }">
      {{ versionNum }}
    </div>
    <v-navigation-drawer
      id="nav-drawer"
      :app="true"
      temporary
      v-show="showNavBar"
      clipped
      v-model="drawerIsShown"
    >
      <v-list-item
        v-for="(value, index) in menuLinks"
        :key="index"
        :id="'navBarMenu' + index"
        @click="onDrawerItemClick(value)"
      >
        <v-list-item-icon>
          <v-icon color="primary">{{ value.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ value.dispName }}</v-list-item-title>
        <v-badge
          color="var(--nav-background)"
          v-if="value.badge != undefined && value.badge > 0"
          :content="value.badge"
        />
      </v-list-item>
    </v-navigation-drawer>

    <v-main>
      <!--Route Content Loaded Here  -->
      <loading-div :show="loading" />

      <v-container fluid>
        <navBar
          v-show="showNavBar"
          @hamClick="
            drawerIsShown = !drawerIsShown;
            $store.commit('showAdvancedSearch', false);
          "
          id="scroller"
        />

        <transition name="fade" mode="out-in">
          <div id="mainContent">
            <keep-alive include="OnlineRenewals">
              <router-view @loginSuccessful="register403Handler"></router-view>
            </keep-alive>
          </div>
        </transition>
      </v-container>

      <v-dialog v-model="showOverridePrompt">
        <override-login @override="getOverride"></override-login>
      </v-dialog>

      <bis-alert v-model="showAlert" v-bind="alertParams" />

      <v-snackbar
        top
        right
        color="orange"
        :timeout="8000"
        :value="updateNotification"
      >
        A site update is available, press the button to reload and get the
        update.

        <v-btn @click="window.location.reload(true)" dark icon>
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-snackbar>
      <alert-feed />
    </v-main>
  </v-app>
</template>

<script>
/*global _, Dynamsoft, basePath */
import { mapGetters } from "vuex";
import { inquiryDefaults } from "./assets/js/apiDefaults";
import navBar from "@/components/nonPageComponents/navBar";
import OverrideLogin from "@/components/nonPageComponents/OverrideLogin";
import { detect } from "detect-browser";
import customCSSFunctions from "@/mixins/customCSSFunctions.js";
import { version } from "../package.json";
import Vue from "vue";
import { BroadcastChannel } from "broadcast-channel";
import axiosPlugin from "./plugins/axios";
import BisAlert from "./components/nonPageComponents/bisAlert.vue";
import loadingDiv from "./components/nonPageComponents/LoadingDiv.vue";
import AlertFeed from "@web-components/alert-feed";

export default Vue.extend({
  components: {
    navBar: navBar,
    OverrideLogin: OverrideLogin,
    BisAlert,
    loadingDiv,
    AlertFeed
  },
  mixins: [customCSSFunctions],
  data() {
    return {
      broadcast: new BroadcastChannel("logout"),
      versionNum: version,
      window: window,
      drawerIsShown: false,
      plateClassArray: this.$store.getters.plateClasses,
      code: "I",
      barcodeRaw: "",
      advancedSearch: false,
      simpleSearch: "",
      inquiryRunning: false,
      interval: "",
      timeoutMessage: "",
      barcodeTimeout: null,
      scanning: false,
      resolveOverrideRequest: null, // gets set to the resolve function of a promise that gets an override login response
      showOverridePrompt: false,
      showSnack: false,
      loading: false
    };
  },
  methods: {
    onDrawerItemClick(value) {
      value["func"]();
      this.$store.commit("showAdvancedSearch", false);
    },
    register403Handler() {
      //TODO PUT BACK IN WITH AXIOS
      // let app = this;
      // Object.deepAssign(greaterFetch.specific, {
      //   async 403(response, resource, init) {
      //     // prompt user for override credentials
      //     app.showOverridePrompt = true;
      //     let override = await new Promise((resolve, reject) => {
      //       app.resolveOverrideRequest = resolve;
      //     });
      //     // redo fetch with new credentials
      //     return greaterFetch(
      //       resource,
      //       Object.deepAssign(init, {
      //         headers: {
      //           "X-Override-Auth": "bearer " + override.token
      //         }
      //       })
      //     );
      //   }
      // });
    },
    /**
     * KeydownEvent callback that builds a string from successive input characters if each character is determined to be
     * scanned from a barcode reader based on the timing and content of input. If a string is determined to be the
     * result of a scan and matches a looked-for pattern, store.Main.barcode is updated. This method is not perfect and
     * leaves the potential for misreads. A more reliable method may be possible by having the tray app detect scans,
     * and then push the information to the web app.
     *
     * patterns:
     * registration/top
     * 201
     * plate
     *
     *
     * @param event a keydown event
     */
    barcodescanner(event) {
      if (event.key == "i" && event.ctrlKey) {
        // F2 key for menu open
        event.preventDefault();
        this.drawerIsShown = !this.drawerIsShown;
        if (this.drawerIsShown) {
          document.getElementById("navBarMenu0").focus();
        }
      }

      if (event.key == "s" && event.ctrlKey) {
        // "\" key for search focus
        event.preventDefault();
        document.getElementById("mainNavOmniSearchInput").focus();
      }

      if (["Control", "Shift", "Alt"].includes(event.key)) {
        // if key is ctrl, shift, or alt
        return;
      }

      if (event.ctrlKey && this.scanning) {
        event.preventDefault();
        return;
      }
      if (
        event.key == "Enter" &&
        event.target == document.body &&
        this.scanning
      ) {
        // keyCode 32 is space
        event.preventDefault();
      }
      this.scanning = true;
      if (
        this.barcodeRaw.length > 10 &&
        !this.barcodeRaw.split("").every(char => char === this.barcodeRaw[0])
      ) {
        // if scanned string is 10+ non-homogeneous characters
        this.$root.$emit("setLoading", true);
      }

      const scanWaitTime = 100; // amount of time to wait for new input (should be slower than scanned input, but faster than human input)

      //this is listening for barcodes
      if (event.key == "-") {
        // if key is '-'
        this.barcodeRaw += "-";
      } else if (event.key !== "Enter") {
        // ignore endlines
        this.barcodeRaw += event.key;
      }

      clearTimeout(this.barcodeTimeout);
      this.barcodeTimeout = setTimeout(() => {
        const barcode = {
          raw: this.barcodeRaw,
          parses: {}
        };
        this.barcodeRaw = "";
        this.scanning = false;

        let match;
        match = /^.*VAD(.*)RAM.*$/.exec(barcode.raw);
        if (match !== null) {
          _.assign(barcode.parses, { vin: match[1] });
        }

        if (match === null) {
          match = /^([0-9]{8})MVD(.+)$/.exec(barcode.raw);
          if (match !== null) {
            _.assign(barcode.parses, { titleNo: match[1] });
          }
        }

        if (match === null) {
          match = /^(\d{4})(\d){1}([a-zA-Z0-9 ]{1,20})$/.exec(barcode.raw);
          if (match !== null) {
            const plate = {
              plateClass: match[1],
              issueYearLeastSignificantDigit: match[2],
              plateNo: match[3]
            };
            _.assign(barcode.parses, { plate: plate });
          }
        }

        if (match === null) {
          match = /.*ANSI.*DCS.*DAC.*DAD.*DAG.*DDB.*ZT/.exec(barcode.raw);
          if (match !== null) {
            const nameSpecialCharacters = /[^A-Z]/; // todo> verify/replace with documented set of characters to replace with " " in driver's license
            try {
              // todo> update this with documented possible characters and simplify regex matching / string formatting
              const LastName = match[0]
                .match(/DCS.*DDEUDAC/)[0]
                .replace("DCS", "")
                .replace("DDEUDAC", "")
                .replace(nameSpecialCharacters, " ")
                .trim();
              const FirstName = match[0]
                .match(/DAC.*DDFU/)[0]
                .replace("DAC", "")
                .replace("DDFU", "")
                .replace(nameSpecialCharacters, " ")
                .trim();
              const MiddleName = match[0]
                .match(/DAD.*DDGU/)[0]
                .replace("DAD", "")
                .replace("DDGU", "")
                .replace(nameSpecialCharacters, " ")
                .trim();
              const Address = match[0]
                .match(/DAG.*DAI/)[0]
                .replace("DAG", "")
                .replace("DAI", "")
                .trim();
              const City = match[0]
                .match(/DAI.*DAJ/)[0]
                .replace("DAI", "")
                .replace("DAJ", "")
                .trim();
              const State = match[0]
                .match(/DAJ.*DAK/)[0]
                .replace("DAJ", "")
                .replace("DAK", "")
                .trim();
              const Zip = match[0]
                .match(/DAK.*DCF/)[0]
                .replace("DAK", "")
                .replace("DCF", "")
                .trim();

              const Name = FirstName + " " + MiddleName + " " + LastName;
              const id = {
                name: Name,
                firstName: FirstName,
                middleName: LastName,
                city: City,
                state: State,
                zip: Zip.substr(0, 5),
                streetAddress: Address
              };
              _.assign(barcode.parses, { id: id });
            } catch (exception) {
              console.error(exception);
              match = null;
            }
          }
        }

        if (match === null) {
          match = /^(.{1,20})\s?([0-9]{2}.[0-9]{2}.[0-9]{2})$/.exec(
            barcode.raw
          );
          if (match !== null) {
            const reminder = {
              plateNo: match[1],
              date: match[2]
            };
            _.assign(barcode.parses, reminder);
          }
        }

        if (match === null) {
          match = /^\d{8}$/.exec(barcode.raw);
          if (match !== null) {
            _.assign(barcode.parses, { controlNo: match[1] });
          }
        }

        if (match === null || _.has(barcode.parses, "controlNo")) {
          match = /^([0-9A-Za-z]{17})$/.exec(barcode.raw);
          if (match !== null) {
            _.assign(barcode.parses, { vin: match[1] });
          }
        }

        if (match !== null) {
          this.$store.commit("barcode", barcode);
        }
        this.$root.$emit("setLoading", false);
      }, scanWaitTime);
    },
    // todo> this may be replacable by search.mixin.doInquiry
    async doInquiry(search) {
      if (this.inquiryRunning) {
        return false;
      }
      this.inquiryRunning = true;
      document.activeElement.blur();
      if (!this.isLoggedIn) {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Session Expired",
          description: "Please Log in Again",
          icon: "error",
          actions: [
            {
              text: "OK",
              handler: () => {
                this.$store.dispatch("hideGlobalAlert");
                this.inquiryRunning = false;
                this.$router.push({ name: "Login" });
              },
              color: "primary"
            }
          ]
        });
      }

      // reset inquiry fields in store
      Object.keys(inquiryDefaults.search).forEach(field => {
        if (Object.keys(this.$store._mutations).includes(field)) {
          this.$store.commit(field, "");
        }
      });

      // update store with current search params
      Object.keys(search).forEach(field => {
        if (Object.keys(this.$store._mutations).includes(field)) {
          this.$store.commit(field, search[field]);
        }
      });

      this.$root.$emit("setLoading", true);

      await this.$api
        .inquirySearch(search)
        .then(transactions => {
          this.$store.commit("resultsArray", transactions);

          this.$router.push({ name: "BarcodeSearch" });
        })
        .catch(() => {});

      this.$root.$emit("setLoading", false);
      this.inquiryRunning = false;
    },
    async getOverride(override) {
      this.resolveOverrideRequest(await override);
    }
  },

  async created() {
    this.$root.$on("setLoading", value => {
      if (value === undefined) {
        return (this.loading = !this.loading);
      }
      this.loading = value;
    });
    // Stop Dynamsoft loader
    Dynamsoft.DWT.OnWebTwainPreExecute = () => {};
    await this.$store.dispatch("setConfig");

    axiosPlugin.axios.defaults.baseURL = this.apiUrl;

    this.broadcast.onmessage = msg => {
      if (msg !== "logout") return;
      this.$router.push({ name: "Login" });
    };

    window.addEventListener("keydown", event => {
      if (this.$router.currentRoute.name != "Login") {
        this.barcodescanner(event);
      }
    });

    window.addEventListener("click", event => {
      if (
        document.getElementById("advancedMenu") != undefined &&
        document.getElementById("mainNavBar") != undefined
      ) {
        const isClickInside =
          document.getElementById("advancedMenu").contains(event.target) ||
          document.getElementById("mainNavBar").contains(event.target);

        if (!isClickInside) {
          this.$store.commit("showAdvancedSearch", false);
        }
      }
    });

    if (localStorage.vinHistoryArray) {
      this.$store.commit(
        "vinHistoryArrayReplace",
        JSON.parse(localStorage.vinHistoryArray)
      );
    }

    //setting the os type for hub app link generation.
    const browser = detect();
    let installerPath = "";
    if (browser) {
      const os = browser.os.toLowerCase();
      const basePath = this.$store.getters.hubDownloadBaseLocation;

      if (os.indexOf("windows") !== -1) {
        installerPath = basePath + "StarInstaller.exe";
      } else if (os.indexOf("mac") !== -1) {
        installerPath = basePath + "STAR.dmg";
      } else {
        installerPath = basePath + "StarInstaller.exe";
      }
    } else {
      installerPath = basePath + "StarInstaller.exe";
    }
    this.$store.commit("installerPath", installerPath);
  },
  computed: {
    ...mapGetters({
      config: "MVConfigurations",
      vinHistArr: "vinHistoryArray",
      apiUrl: "apiUrl",
      drafts: "unfinishedDrafts",
      updateNotification: "updateNotification",
      installerPath: "installerPath",
      invoiceNo: "invoiceNo",
      isFeatureEnabled: "isFeatureEnabled",
      isLoggedIn: "isLoggedIn",
      alertParams: "alertParams",
      showAlert: "showAlert"
    }),
    showNavBar() {
      return this.isLoggedIn;
    },
    menuLinks() {
      const links = [
        {
          dispName: "Previous",
          func: () => {
            this.$router.push({ name: "PreviousTransactions" });
          },
          icon: "history",
          feature: "mvPrevious"
        },
        {
          dispName: "Drafts",
          func: () => {
            this.$router.push({ name: "UnfinishedDrafts" });
          },
          icon: "create",
          badge: this.drafts.length,
          feature: "mvDrafts"
        },
        {
          dispName: "Deficients",
          func: () => {
            this.$router.push({ name: "DeficientTransactions" });
          },
          icon: "cloud_off",
          feature: "mvDeficients"
        },
        {
          dispName: "Inventory",
          func: () => {
            this.$router.push({ name: "Inventory" });
          },
          icon: "move_to_inbox",
          feature: "mvInventory"
        },
        {
          dispName: "LienHolders",
          func: () => {
            this.$router.push({ name: "LienholderMaint" });
          },
          icon: "contacts",
          feature: "mvLienholders"
        },
        {
          dispName: "Control Numbers",
          func: () => {
            this.$router.push({ name: "ControlNumbers" });
          },
          icon: "assignment",
          feature: "mvControlNumbers"
        },
        {
          dispName: "Missing Images",
          func: () => {
            this.$router.push({ name: "MissingImages" });
          },
          icon: "mdi-image-off-outline",
          feature: "mvMissingImages"
        },
        {
          dispName: "Online Renewals",
          func: () => {
            this.$router.push({ name: "OnlineRenewals" });
          },
          icon: "important_devices",
          feature: "mvOnlineRenewals"
        },
        {
          dispName: "Title Printing",
          func: () => {
            this.$router.push({
              name: "TitlePrinting"
            });
          },
          icon: "print",
          feature: "mvTitlePrinting"
        },
        {
          dispName: "Bulk Scanning",
          func: () => {
            this.$router.push({
              name: "BulkScanning"
            });
          },
          icon: "scanner",
          feature: "mvBulkScanning"
        },
        {
          dispName: "Dealer Plates",
          func: () => {
            this.$router.push({ name: "DealerPlates" });
          },
          icon: "people",
          feature: "mvDealerPlates"
        },
        {
          dispName: "EZ Tag",
          func: () => {
            this.$router.push({ name: "EZTag" });
          },
          icon: "mdi-car",
          feature: "mvEZTag"
        },
        {
          dispName: "Dashboard",
          func: () => {
            this.$router.push({ name: "InventoryDashboard" });
          },
          icon: "mdi-view-dashboard-variant",
          feature: "inDashboard"
        },
        {
          dispName: "Location Maint.",
          func: () => {
            this.$router.push({ name: "LocationMaint" });
          },
          icon: "mdi-map",
          feature: "inLocationMaint"
        },
        {
          dispName: "Settings",
          func: () => {
            this.$router.push({ name: "Configuration" });
          },
          icon: "mdi-cog",
          feature: "mvSettings"
        }
      ];

      const displayLinks = [];
      links.forEach(link => {
        if (this.isFeatureEnabled(link.feature)) {
          displayLinks.push(link);
        }
      });

      return displayLinks;
    },
    barcode() {
      return this.$store.state.Main.barcode;
    }
  },
  watch: {
    isLoggedIn() {
      if (!this.isLoggedIn) {
        this.broadcast.postMessage("logout");
      }
    },
    config(newVal) {
      if (this._.has(newVal, "customCSS")) {
        this.applyCustomCSS(JSON.parse(JSON.stringify(newVal.customCSS)));
      }
    },
    vinHistArr(newVal) {
      localStorage.vinHistoryArray = JSON.stringify(newVal);
    },
    $route(to) {
      if (to.meta.name) {
        document.title = `STARS - ${to.meta.name}`;
      } else {
        document.title = "STARS";
      }
    },
    async barcode() {
      const nonSearchRoutes = [
        "Transaction",
        "NewTransaction",
        "PlacardTransaction"
      ];
      if (!nonSearchRoutes.includes(this.$router.currentRoute.name)) {
        if (this.barcode.parses !== undefined) {
          // Flattens object hierarchy for doInquiry
          const inquiryParses = this.barcode.parses;
          if (_.has(inquiryParses, "id")) {
            _.merge(inquiryParses, inquiryParses.id);
            delete inquiryParses.id;
          }
          if (_.has(inquiryParses, "plate")) {
            _.merge(inquiryParses, inquiryParses.plate);
            delete inquiryParses.plate;
          }
          await this.doInquiry(inquiryParses);
        }
      }
    }
  }
});
</script>

<style scoped lang="scss">
@import "assets/web-fonts-with-css/css/fontawesome-all.css";
@import "assets/css/global-variables.scss";

#app {
  height: 100vh;
}

.nav-icon {
  margin-right: 10px;
}

.v-navigation-drawer--fixed {
  margin-top: $nav-height;
  margin-right: 10px;
}

[v-cloak] {
  display: none;
}

html {
  overflow: auto !important;
}

#mainContent {
  margin-top: $nav-height;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.subTable {
  background-color: #f7f7f7;
}

.versionNum {
  position: fixed;
  right: 5px;
  font-size: 10px;
  cursor: default;
  z-index: 100;
}
.versionNum.underNavBar {
  top: $nav-height;
}
</style>
