import {
  address as defaultAddress,
  transactionRequest as defaultTransactionRequest,
  countyData as defaultCountyData
} from "@/assets/js/defaultTransactionRequestObjects/defaults";

export default {
  methods: {
    concatenateStreetNoAndAddress(vehicleRecord) {
      const addressLocations = [
        this._.get(vehicleRecord, "lessor.physicalAddress"),
        this._.get(vehicleRecord, "lessor.mailingAddress"),
        this._.get(vehicleRecord, "currentAddress")
      ];
      vehicleRecord.owners.forEach(owner => {
        addressLocations.push(owner.physicalAddress);
        addressLocations.push(owner.mailingAddress);
      });
      if (vehicleRecord.liens) {
        vehicleRecord.liens.forEach(lien => {
          addressLocations.push(lien.lienholder.physicalAddress);
        });
      }
      addressLocations.forEach(address => {
        if (!address) return;
        const { streetNo, address1 } = address;
        // combine streetNo and address1 as the new address1
        address.address1 = [streetNo, address1]
          .filter(str => typeof str === "string" && str.length > 0)
          .join(" ");
        address.streetNo = null;
      });
    },
    setupTitleBrands(processObj) {
      if (
        processObj.vehicle.allTitleBrands == undefined ||
        processObj.vehicle.allTitleBrands.length == 0
      ) {
        // add iterable title brand representation to vehicle
        processObj.vehicle.allTitleBrands = [];
        if (!this._.has(processObj, "vehicle.titleBrands"))
          processObj.vehicle.titleBrands = [];
        const allTitleBrands = this.$store.getters.allTitleBrands;
        const boundTitleBrands = processObj.vehicle.titleBrands || [];

        const titleBrandKeys = Object.keys(allTitleBrands);
        for (let i = 0; i < titleBrandKeys.length; i++) {
          const isBound =
            boundTitleBrands.find(
              titleBrand => titleBrand.titleBrandID === titleBrandKeys[i]
            ) !== undefined;
          processObj.vehicle.allTitleBrands.push({
            titleBrandID: titleBrandKeys[i],
            description: allTitleBrands[titleBrandKeys[i]],
            isSelected: isBound,
            isBound: isBound
          });
        }
      }
      return processObj;
    },
    newVehicle() {
      let processObj = defaultTransactionRequest();
      processObj.registration.currentPlate = {};
      delete processObj.registration.registrationExpiresDate;
      processObj.registration.newPlate = processObj.registration.currentPlate;
      processObj.lessor = {};
      processObj.lessor.physicalAddress = {};
      processObj.owners = [];
      processObj.owners[0] = {};
      processObj.owners[0].physicalAddress = defaultAddress();
      processObj.owners[0].mailingAddress = defaultAddress();
      processObj.owners[0].individualCustomer = {};
      processObj.owners[0].physicalAddress.state = "TN";
      processObj.owners[1] = {};
      processObj.owners[1].individualCustomer = {};
      processObj.liens = [];
      for (let i = 0; i < processObj.owners.length; i++) {
        processObj.owners[i].customerID = null;
        processObj.owners[i].physicalAddressID = null;
      }
      if (processObj.title == undefined) {
        processObj.title = {};
      }
      processObj.transaction.comments = [];

      processObj = this.setupTitleBrands(processObj);

      return processObj;
    },
    async addVehicle(type) {
      // todo> merge this logic into transaction.mixin's transferWipe

      //adding this for new add vehicle menu
      this.$store.commit("transactionType", type);

      const processObj = this.newVehicle();

      let possiblePlate = "";

      if (this.isAdvancedSearch) {
        if (this.plateNo != "") {
          possiblePlate = this.plateNo.trim();
        }
      } else {
        const simpleSearch = this.$store.getters.simpleSearch;
        possiblePlate = simpleSearch.trim();
      }

      try {
        const quietErrors = {
          500: () => {},
          404: () => {},
          422: () => {}
        };

        if (possiblePlate !== "" && possiblePlate.length <= 8) {
          const subrange = await this.$api.subRangePlateSearch(
            possiblePlate,
            quietErrors
          );
          if (subrange) {
            processObj.registration.newPlate.plateNo = possiblePlate;
          }
        } else {
          throw "Not a plate number!";
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.commit("processObj", processObj);
        this.$store.commit("resultsArray", null);
        this.$router.push({ name: "NewDirectTransactionChange" });
      }
    },

    reformatTandrObjectforTransactionScreen(processObj) {
      if (processObj.registration != undefined) {
        if (processObj.registration.currentPlate == undefined) {
          processObj.registration.currentPlate = {};
        }

        if (processObj.registration.newPlate) {
          if (
            processObj.transaction.transactionType === "OR" &&
            processObj.registration.newPlate.class !==
              processObj.registration.currentPlate.class
          ) {
            processObj.registration.newRequiredClass =
              processObj.registration.newPlate.class;
          }
        }

        //todo why is deleting registrationexpiresdate here nessesary
        if (
          (!this._.has(processObj, "registration.currentPlate.plateNo") &&
            !this._.has(processObj, "registration.newPlate.plateNo")) ||
          (this._.has(processObj, "registration.newPlate.plateNo") &&
            processObj.registration.newPlate.plateNo == "")
        ) {
          delete processObj.registration.registrationExpiresDate;
        }

        if (
          !this._.has(processObj, "registration.newPlate") ||
          processObj.registration.newPlate.plateNo == undefined ||
          processObj.registration.newPlate.plateNo == ""
        ) {
          processObj.registration.newPlate =
            processObj.registration.currentPlate;
        }

        if (processObj.lessor == undefined) {
          processObj.lessor = {};
          processObj.lessor.physicalAddress = {};
        }
      } else if (!this._.has(processObj, "placard")) {
        processObj.registration = { currentPlate: {}, newPlate: {} };
      }
      if (processObj.owners == undefined) {
        processObj.owners = [];
      }
      if (processObj.owners[0] != undefined) {
        if (processObj.owners[0].physicalAddress == undefined) {
          processObj.owners[0].physicalAddress = {};
        } else {
          processObj.owners[0].physicalAddress.addressID = null;
        }
        if (processObj.owners[0].mailingAddress == undefined) {
          processObj.owners[0].mailingAddress = defaultAddress();
        } else {
          processObj.owners[0].mailingAddress.addressID = null;
        }
        if (processObj.owners[0].individualCustomer == undefined) {
          processObj.owners[0].individualCustomer = {};
        }
        if (processObj.owners[1] == undefined) {
          processObj.owners[1] = {};
          processObj.owners[1].customerType = "I";
        }
        if (processObj.owners[1].individualCustomer == undefined) {
          processObj.owners[1].individualCustomer = {};
        }
      }
      if (processObj.liens == undefined) {
        processObj.liens = [];
      }
      for (let i = 0; i < processObj.owners.length; i++) {
        processObj.owners[i].customerID = null;
        processObj.owners[i].physicalAddressID = null;
      }

      //setting namecode based on owners array

      const nameCodeArr = [];

      if (
        this._.has(processObj, "undercoverOwners") &&
        processObj.undercoverOwners.length > 0
      ) {
        nameCodeArr.push({
          undercover: true,
          ownersBlock: processObj.undercoverOwners
        });
      } else if (this._.has(processObj, "undercoverOwnership")) {
        processObj.undercoverOwnership.nameCode = "0";
      }
      if (this._.has(processObj, "owners") && processObj.owners.length > 0) {
        nameCodeArr.push({ undercover: false, ownersBlock: processObj.owners });
      } else if (this._.has(processObj, "ownership")) {
        processObj.ownership.nameCode = "0";
      }

      for (let i = 0; i < nameCodeArr.length; i++) {
        const owners = nameCodeArr[i].ownersBlock;
        if (owners == undefined) continue;
        let nameCodeVar = "";
        if (owners[0].customerType == "B") {
          nameCodeVar = "3";
        } else {
          if (
            owners[1] == undefined ||
            (["", undefined].includes(owners[1].individualCustomer.lastName) &&
              ["", undefined].includes(owners[1].individualCustomer.firstName))
          ) {
            nameCodeVar = "0";
          } else if (
            this._.has(owners[0], "individualCustomer.lastName") &&
            this._.has(owners[1], "individualCustomer.lastName") &&
            owners[0].individualCustomer.lastName ==
              owners[1].individualCustomer.lastName &&
            ![undefined, ""].includes(owners[0].individualCustomer.lastName)
          ) {
            nameCodeVar = "1";
          } else {
            nameCodeVar = "2";
          }
        }

        if (nameCodeArr[i].undercover) {
          processObj.undercoverOwnership.nameCode = nameCodeVar;
        } else if (this._.has(processObj, "ownership")) {
          processObj.ownership.nameCode = nameCodeVar;
        }
      }

      for (let i = 0; i < processObj.liens.length; i++) {
        if (processObj.liens[i].lienholder.physicalAddress == undefined) {
          processObj.liens[i].lienholder.physicalAddress = {};
        } else {
          processObj.liens[i].lienholder.physicalAddress.addressID = null;
        }
        processObj.liens[i].lienholder.customerID = null;
        processObj.liens[i].lienholder.physicalAddressID = null;
        processObj.liens[i].dischargeDate = null;
      }

      this.concatenateStreetNoAndAddress(processObj);

      if (processObj.title == undefined && this._.has(processObj, "vehicle")) {
        processObj.title = {};
      }
      if (!processObj.transaction.comments) {
        processObj.transaction.comments = [];
      }

      if (
        this._.has(processObj, "vehicle") &&
        processObj.vehicle.insurance == undefined
      ) {
        processObj.vehicle.insurance = { PolicyNo: "", NAIC: "" };
      }
      if (this._.has(processObj, "vehicle"))
        processObj = this.setupTitleBrands(processObj);

      processObj.countyData = this._.merge(
        defaultCountyData(),
        processObj.countyData
      );

      return processObj;
    },
    /**
     * Starts a transaction by setting the transaction state and routing to the transaction page or emitting a transaction change event if the current route is Transaction.
     *
     * @param {string} type - Transaction type
     * @param {Object} [vehicleRecord] - Specific record to use
     */
    startTransaction(type, vehicleRecord = undefined) {
      vehicleRecord = vehicleRecord || this.processObj;
      this.$store.commit("transactionType", type);
      // Can cause problems if not reset before a transaction
      this.processObj.title.titleReIssueReason = "";
      if (
        this.$route.name === "Transaction" ||
        this.$route.name === "NewTransaction"
      ) {
        this.$emit("changeTransaction", vehicleRecord);
      } else {
        this.$router.push({
          name: "Transaction",
          params: {
            vin: vehicleRecord.vehicle.vin,
            make: vehicleRecord.vehicle.makeCode,
            year: vehicleRecord.vehicle.modelYear
          }
        });
      }
    }
  }
};
