import vehicleFunctions from "@/mixins/vehicleFunctions.js";
import newPlacardScaffold from "@/assets/jsonScaffolds/newPlacardScaffold.js";
import { mapGetters } from "vuex";
export default {
  mixins: [vehicleFunctions],
  methods: {
    showPlacardOptions() {
      this.noResultsMenuOptions1 = [{ val: "Placard", display: "New Placard" }];
      this.$store.commit("noResultsMenuOptions", this.noResultsMenuOptions1);
      this.noResultsMenuTitle = "Select an Option";
      //this.showModal = true;
      this.$store.commit("showNoResults", true);
      this.$root.$emit("setLoading", false);
    },
    noResultsMenuFunction(option) {
      this.$store.commit("transactionType", "");
      this.$store.commit("editObj", "");
      let placardNo = this.$store.getters.simpleSearch;
      if (this.$store.getters.isAdvancedSearch) {
        placardNo = this.$store.getters.placardNumber;
      }
      if (option == "Placard") {
        this.$store.commit("transactionType", "New Placard");
        const transScaffold = newPlacardScaffold();
        transScaffold.placard.controlNo = placardNo;
        this.$store.commit(
          "processObj",
          JSON.parse(JSON.stringify(transScaffold))
        );
        this.$store.commit("resultsArray", null);
        this.$router.push({ name: "NewDirectTransactionChange" });
      } else {
        this.addVehicle(option);
      }
    },
    switchView() {
      this.advancedSearch = !this.advancedSearch;
    },
    async archiveSearchAdvanced() {
      //this is ran if we know what fields they used.

      const sendData = "";
      let sendURL = "";
      const sendMethod = "GET";
      if (this.vin != "") {
        sendURL = "/archivesearch/vin/" + this.vin;
        this.$store.commit("vin", this.vin);
      } else if (this.titleNo != "") {
        sendURL = "/archivesearch/title/" + this.titleNo;
        this.$store.commit("titleNo", this.titleNo);
      } else if (this.plateNo != "") {
        sendURL = "/archivesearch/plate/" + this.plateNo;
        this.$store.commit("plateNo", this.plateNo);
      } else if (this.name != "") {
        const nameArr = this.name.split(" ");
        let nameString = nameArr[0];
        for (let i = 0; i < nameArr.length; i++) {
          if (i > 0) nameString = nameString + "+" + nameArr[i];
        }
        sendURL =
          "/archivesearch/owner?NameSearchHandlerCode=" +
          this.code +
          "&name=" +
          nameString +
          "&page=1";
        this.$store.commit("isPurgeName", true);
        this.$store.commit("name", this.name);
        this.$store.commit("code", this.code);
      } else {
        this.showNewVehicleSelect();
        this.inquiryRunning = false;
        return false;
      }
      this.$root.$emit("setLoading", true);
      await this.$api
        .archiveSearch(sendURL, sendMethod, sendData)
        .then(resultsArrayTemp => {
          if (resultsArrayTemp === null) {
            //no results coming from general inquiry.
            this.showNewVehicleSelect();
            this.inquiryRunning = false;
            return false;
          } else {
            if (this.isArchiveSearch) {
              this.$store.commit("resultsArray", resultsArrayTemp);
              this.$root.$emit("setLoading", false);
              this.$router.push({ name: "Results" });
            } else {
              this.$root.$emit("setLoading", false);
              this.$store.dispatch("setGlobalAlertState", {
                title: "Archive Record Found!",
                description: "Do you want to start the build process?",
                icon: "warning",
                actions: [
                  {
                    text: "See Archive Record",
                    handler: () => {
                      this.$store.dispatch("hideGlobalAlert");

                      if (resultsArrayTemp) {
                        this.$store.commit("resultsArray", resultsArrayTemp);
                        this.$router.push({ name: "Results" });
                      }
                    },
                    color: "primary"
                  },
                  {
                    text: "Cancel",
                    handler: () => {
                      this.$store.dispatch("hideGlobalAlert");
                    },
                    color: "#E0E0E0"
                  }
                ]
              });
            }
          }
        })
        .catch(() => {
          this.$root.$emit("setLoading", false);
          this.showNewVehicleSelect();
          this.inquiryRunning = false;
          return false;
        });
    },
    archiveSearch(type = "vin") {
      const sendData = "";
      let sendURL = "";
      const sendMethod = "GET";
      //only when fields are specified in advanced search
      //this is for the omi (one field) search, since we cannot tell what the value will be.
      //choose which type of search to do
      switch (type) {
        case "vin":
          sendURL = "/archivesearch/vin/" + encodeURI(this.simpleSearch.trim());
          break;
        case "title":
          sendURL =
            "/archivesearch/title/" + encodeURI(this.simpleSearch.trim());
          break;
        case "plate":
          sendURL =
            "/archivesearch/plate/" + encodeURI(this.simpleSearch.trim());
          break;
      }
      this.$root.$emit("setLoading", true);
      this.$api
        .archiveSearch(sendURL, sendMethod, sendData, {
          404: () => {
            this.inquiryRunning = false;

            switch (type) {
              case "vin":
                this.archiveSearch("title");
                break;
              case "title":
                this.archiveSearch("plate");
                break;
              case "plate":
                this.showNewVehicleSelect();
                break;
            }
            throw 404;
          }
        })
        .then(resultsArrayTemp => {
          if (resultsArrayTemp === null) {
            //this also means no results.
            switch (type) {
              case "vin":
                this.archiveSearch("title");
                break;
              case "title":
                this.archiveSearch("plate");
                break;
              case "plate":
                this.showNewVehicleSelect();
                this.inquiryRunning = false;
                break;
            }
            return false;
          } else {
            switch (type) {
              case "vin":
                this.$store.commit("vin", this.simpleSearch.trim());
                break;
              case "title":
                this.$store.commit("titleNo", this.simpleSearch.trim());
                break;
              case "plate":
                this.$store.commit("plateNo", this.simpleSearch.trim());
                break;
            }
            this.$root.$emit("setLoading", false);

            this.$store.dispatch("setGlobalAlertState", {
              title: "Archive Record Found!",
              description: "Do you want to start the build process?",
              icon: "warning",
              actions: [
                {
                  text: "See Archive Record",
                  handler: () => {
                    this.$store.commit("resultsArray", resultsArrayTemp);
                    this.$root.$emit("setLoading", false);
                    this.$router.push({ name: "Results" });
                    this.simpleSearch = "";
                    this.$store.dispatch("hideGlobalAlert");
                  },
                  color: "primary"
                },
                {
                  text: "Cancel",
                  handler: () => {
                    this.$store.dispatch("hideGlobalAlert");
                  },
                  color: "#E0E0E0"
                }
              ]
            });
          }
        })
        .catch(() => {
          this.$root.$emit("setLoading", false);
          this.showNewVehicleSelect();
          this.inquiryRunning = false;
          return false;
        });
    },
    async doUndercoverInquiry() {
      this.$store.commit("vin", this.vin);
      let routeParams;
      try {
        routeParams = {
          name: "Details",
          params: {
            vin: this.vin,
            make: this.makeCode,
            year: parseInt(this.year),
            undercoverVehicle: true
          }
        };
        const results = await this.$api.undercoverInquiry({
          vehicle: {
            vin: this.vin,
            makeCode: this.makeCode,
            modelYear: parseInt(this.year)
          }
        });
        this.$store.commit("resultsArray", [results]);
        this.$store.commit("processObj", results);
      } catch (e) {
        console.error(e);
        return false;
      } finally {
        this.inquiryRunning = false;
        this.$root.$emit("setLoading", false);
      }
      this.$router.push(routeParams);
    },
    async doInquiry() {
      if (this.inquiryRunning) {
        return false;
      }
      this.inquiryRunning = true;
      document.activeElement.blur();

      this.$store.commit("isAdvancedSearch", this.advancedSearch);
      if (!this.$store.getters.isLoggedIn) {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Session Expired!",
          description: "Please log in again.",
          icon: "warning",
          actions: [
            {
              text: "OK",
              handler: () => {
                this.$router.push({ name: "Login" });
                this.$store.dispatch("hideGlobalAlert");
              },
              color: "primary"
            }
          ]
        });
      }
      if (
        this.placard != undefined &&
        this.placard.length > 0 &&
        this.advancedSearch
      ) {
        this.inquiryRunning = false;
        this.$root.$emit("setLoading", true);
        this.doPlacardInquiry();
        return false;
      } else if (
        this.vin != undefined &&
        this.vin.length > 0 &&
        this.advancedSearch &&
        this.isUndercoverSearch
      ) {
        this.doUndercoverInquiry();
        return false;
      }
      let sendData;
      let sendURL = "";
      let sendMethod = "";
      this.$store.commit("page", 0);
      if (this.advancedSearch) {
        //this is for the addition of the purge search funcionality
        if (this.isArchiveSearch) {
          //moved this code into its own method
          this.$root.$emit("setLoading", true);
          this.archiveSearchAdvanced();
          return false;
        } else {
          //this is for the regular advanced search
          this.$store.commit("titleNo", this.titleNo);
          this.$store.commit("vin", this.vin);
          this.$store.commit("vinLast8", this.VINLast);
          this.$store.commit("plateNo", this.plateNo);
          this.$store.commit("plateClass", this.plateClass);
          this.$store.commit("plateClassIssueYear", this.PlateClassIssueYear);
          this.$store.commit("makeCode", this.makeCode);
          this.$store.commit("modelCode", this.modelCode);
          this.$store.commit("name", this.name);
          this.$store.commit(
            "modelYear",
            this.year != undefined ? this.year.toString() : ""
          );
          this.$store.commit("streetAddress", this.streetAddress);
          this.$store.commit("city", this.city);
          this.$store.commit("state", this.state);
          this.$store.commit("zip", this.ZIP);
          if (this.isFeatureEnabled("drafts")) {
            if (this.searchDrafts(this.vin, this.plateNo)) {
              this.inquiryRunning = false;
              return;
            }
          }
          sendData = JSON.stringify({
            titleNo: this.titleNo,
            vin: this.vin,
            vinLast8: this.VINLast,
            plateNo: this.plateNo,
            plateClass: this.plateClass,
            plateClassIssueYear: this.PlateClassIssueYear,
            makeCode: this.makeCode,
            modelCode: this.modelCode,
            name: this.name,
            modelYear: this.year != undefined ? this.year.toString() : "",
            streetAddress: this.streetAddress,
            city: this.city,
            state: this.state,
            zip: this.ZIP,
            partialPlateNo: "",
            disabledPlacard: "",
            bodyType: "",
            titanBodyCode: "",
            majorColorCode: "",
            minorColorCode: "",
            page: 0
          });
          sendURL = "/star/inquiry/search";
          sendMethod = "POST";
        }
      } else {
        if (this.simpleSearch.length == 0 || this.simpleSearch == undefined)
          return;
        if (this.isFeatureEnabled("drafts")) {
          if (this.searchDrafts(this.simpleSearch, this.simpleSearch, "or")) {
            this.inquiryRunning = false;
            return;
          }
        }
        this.$store.commit("simpleSearch", this.simpleSearch);
        sendURL = "/star/inquiry/search?q=" + encodeURI(this.simpleSearch);
        sendMethod = "GET";
      }
      this.$root.$emit("setLoading", true);
      await this.$api
        .simpleSearch(sendURL, sendMethod, sendData, {
          404: () => {
            if (this.advancedSearch) {
              this.archiveSearchAdvanced();
            } else {
              this.archiveSearch();
            }
          }
        })
        .then(resultsArrayTemp => {
          this.$store.commit("resultsArray", resultsArrayTemp);
          if (this.isArchiveSearch) {
            this.$root.$emit("setLoading", false);
            this.$store.commit("transactionType", "");
            this.$store.commit("editObj", "");
            this.$router.push({ name: "Results" });
            this.simpleSearch = "";
          } else {
            if (
              resultsArrayTemp === null ||
              JSON.stringify(resultsArrayTemp) == "[]"
            ) {
              //no results coming from general inquiry.
              if (this.advancedSearch) {
                this.archiveSearchAdvanced();
              } else {
                this.archiveSearch();
              }
              this.inquiryRunning = false;
              return false;
            } else {
              this.$store.commit("transactionType", "");
              this.$store.commit("editObj", "");
              if (
                resultsArrayTemp != undefined &&
                resultsArrayTemp.length > 1
              ) {
                this.$router.push({ name: "Results" });
              } else {
                if (resultsArrayTemp[0].pendingTransaction) {
                  this.$router.push({
                    name: "Details",
                    params: {
                      vin: resultsArrayTemp[0].vehicle.vin,
                      make: resultsArrayTemp[0].vehicle.make,
                      year: resultsArrayTemp[0].vehicle.year
                    }
                  });
                } else {
                  if (resultsArrayTemp[0].vehicle.vin) {
                    this.simpleSearch = resultsArrayTemp[0].vehicle.vin;
                    sendURL =
                      "/star/inquiry/search?q=" + encodeURI(this.simpleSearch);
                    sendMethod = "GET";
                    sendData = JSON.stringify({
                      vin: this.simpleSearch
                    });
                    this.$api
                      .simpleSearch(sendURL, sendMethod, sendData)
                      .then(result => {
                        resultsArrayTemp = result;
                        this.$store.commit("resultsArray", resultsArrayTemp);
                        this.$router.push({
                          name: "Details",
                          params: {
                            vin: resultsArrayTemp[0].vehicle.vin,
                            make: resultsArrayTemp[0].vehicle.make,
                            year: resultsArrayTemp[0].vehicle.year
                          }
                        });
                      });
                  }
                }
              }
              this.simpleSearch = "";
            }
            this.inquiryRunning = false;
          }
        })
        .catch(() => {
          this.inquiryRunning = false;
          return false;
        });
    },
    async ocrSearch(scanObj) {
      if (this.inquiryRunning) {
        return false;
      }
      this.inquiryRunning = true;
      document.activeElement.blur();

      if (!this.isLoggedIn) {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Session Expired",
          description: "Please log in again",
          icon: "error",
          actions: [
            {
              text: "OK",
              handler: () => {
                this.$router.push({ name: "Login " });
                this.$store.dispatch("hideGlobalAlert");
              },
              color: "primary"
            }
          ]
        });
      }
      let sendData = "";
      let sendURL = "";
      let sendMethod = "";
      this.$store.commit("page", 0);
      sendData = JSON.stringify({
        vin: scanObj.vin,
        page: 0
      });
      sendURL = "/star/inquiry/search";
      sendMethod = "POST";
      await this.$api
        .simpleSearch(sendURL, sendMethod, sendData, {
          404: () => {
            this.inquiryRunning = false;
            this.$root.$emit("setLoading", false);
            this.addVehicle(scanObj);
            return false;
          }
        })
        .then(resultsArrayTemp => {
          if (resultsArrayTemp === null) {
            this.addVehicle(scanObj);
            this.inquiryRunning = false;
            return false;
          }
          this.$store.commit("transactionType", "");
          this.$store.commit("editObj", "");
          this.$store.commit("resultsArray", resultsArrayTemp);
          this.$router.push({ name: "Results" });
          return true;
        })
        .catch(() => {
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error",
            description: "There is a problem with the server",
            icon: "error"
          });
          return false;
        });
    },
    async doPlacardInquiry() {
      let routeParams = {};
      const placard = this.placard;
      this.$store.commit("placardNumber", this.placard);

      try {
        const msg = await this.$api.placardInquiry(this.placard, {
          404: e => {
            return e.response.data.err;
          }
        });
        if (msg === "No results found") {
          this.showPlacardOptions();
          this.$store.commit("placardArray", []);
          return false;
        }
        if (placard != "") {
          const data = await this.$api.VTRSPlacardInquiry(placard);
          this.$store.commit("resultsArray", msg);
          this.$store.commit(
            "processObj",
            this.reformatTandrObjectforTransactionScreen(data[0])
          );
          this.$store.commit("transObj", {});
          this.$store.commit("editObj", {});
        }
        if (this.$store.getters.transactionType === "Placard Renewal") {
          routeParams = {
            name: "PlacardTransaction",
            params: {
              placard: msg[0].controlNo
            }
          };
        } else {
          routeParams = {
            name: "PlacardDetails",
            params: {
              placard: msg[0].controlNo
            }
          };
        }
        this.$router.push(routeParams);
      } catch (e) {
        console.error(e);
      } finally {
        this.$root.$emit("setLoading", false);
      }
    },
    classSelect() {
      if (this.plateClassIndex === "") {
        this.issueYearArray = "";
        this.plateClass = "";
      } else {
        this.plateClass =
          this.$store.getters.plateClasses[
            this.plateClassIndex - 1
          ].plateClassCode;
        this.issueYearArray = this.$store.getters.plateClasses[
          this.plateClassIndex - 1
        ].plateDates.map(plateDate => parseInt(plateDate.issueYear));
      }
    },
    removeShadow(e) {
      e.target.style.boxShadow = "none";
    },
    purgeClearFields(currentField) {
      if (this.isArchiveSearch) {
        if (currentField != "vin") this.vin = "";
        if (currentField != "plate") this.plateNo = "";
        if (currentField != "title") this.titleNo = "";
        if (currentField != "name") this.name = "";
      }
    },
    loginFromStorage() {},
    async showNewVehicleSelect() {
      this.$root.$emit("setLoading", false);
      this.noResultsMenuOptions1 = [
        { val: "Title And Registration", display: "Title & Registration" },
        { val: "Title Only", display: "Title Only" },
        { val: "Temporary Tag Only", display: "Temporary Tag Only" },
        { val: "Placard", display: "New Placard" }
      ];
      if (
        (this.simpleSearch != undefined &&
          this.simpleSearch.length == "17" &&
          !this.simpleSearch.includes(" ")) ||
        (this.$store.getters.vin.length > 0 && this.advancedSearch)
      ) {
        this.noResultsMenuOptions1.splice(3, 1); //remove this if it is a vin that we searched for
      }
      this.$store.commit("noResultsMenuOptions", this.noResultsMenuOptions1);
      this.noResultsMenuTitle = "Would you like to add a new vehicle?";
      this.$store.commit("showNoResults", true);
      this.simpleSearch = "";
    },
    hideModal() {
      this.$store.commit("showNoResults", false);
      this.$root.$emit("setLoading", false);
    },
    hideAdvanced() {
      this.$store.commit("showAdvancedSearch", false);
    },
    showAdvancedSearch() {
      this.$store.commit("showAdvancedSearch", true);
    },
    advancedSearchToggle() {
      if (this.$store.getters.showAdvancedSearch) {
        this.$store.commit("showAdvancedSearch", false);
      } else {
        this.$store.commit("showAdvancedSearch", true);
      }
    },
    searchDrafts(vin, plate, conjunction = "and") {
      const matchingDrafts = this.drafts.filter(draft => {
        const conditions = [];
        if (![undefined, null, ""].includes(vin)) {
          conditions.push(vin == draft.vin);
        }
        const draftPlateObj =
          draft.obj.registration.newPlate ||
          draft.obj.registration.currentPlate;
        const draftPlate = draftPlateObj.plateNo;
        if (![undefined, null, ""].includes(plate)) {
          conditions.push(draftPlate == plate);
        }
        if (conjunction == "or") {
          return conditions.includes(true);
        } else {
          return !conditions.includes(false);
        }
      });
      if (matchingDrafts.length == 1) {
        const matchingDraft = matchingDrafts[0];
        this.$store.commit("editObj", matchingDraft.obj);
        this.$store.commit("transactionType", matchingDraft.transactionType);
        this.$router.push({
          name: "DirectTransactionChange",
          params: {
            vin: matchingDraft.obj.vehicle.vin,
            make: matchingDraft.obj.vehicle.makeCode,
            year: matchingDraft.obj.vehicle.modelYear,
            isFromBackButton: true
          }
        });
        return true;
      }
    }
  },
  computed: {
    ...mapGetters({
      drafts: "unfinishedDrafts",
      isFeatureEnabled: "isFeatureEnabled"
    })
  }
};
