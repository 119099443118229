import Vue from "vue";
import Vuex from "vuex";
import pathify from "vuex-pathify";

import BaseInfo from "./modules/BaseInfo";
import Main from "./modules/Main";
import Static from "./modules/Static";
import UserInfo from "./modules/UserInfo.js";

import config from "./modules/config";
import auth from "./modules/auth";

Vue.use(Vuex);

//replacing the programatic load with definitions so jest can load the store properly
//jest does not like the require.context method
export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: {
    BaseInfo,
    Main,
    Static,
    UserInfo,
    config,
    auth
  }
});
