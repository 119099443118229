import Axios from "axios";
import { createLogMessage } from "@/assets/js/createLogMessage";
import { cloneDeep } from "lodash";
import { formatErrorResponse } from "@/assets/js/format.js";

const defaultHeaders = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0"
};

const axios = Axios.create();

function install(Vue, options) {
  if (Vue.prototype.$api) {
    console.error("Vue instance already has an api!");
    return;
  }

  axios.defaults.headers = Object.assign(
    defaultHeaders,
    options.authHeaders || {}
  );

  axios.interceptors.request.use(request => {
    options.store.commit(
      "activeAxiosCalls",
      options.store.getters.activeAxiosCalls + 1
    );

    if (
      options.store.getters.apiToken &&
      request.headers.authorization === undefined
    ) {
      request.headers["Authorization"] =
        "Bearer " + options.store.getters.apiToken;
    }
    return request;
  });

  axios.defaultErrorHandlers = {
    404: error => {
      options.store.dispatch("setGlobalAlertState", {
        title: "No Results",
        minWidth: "350px",
        description: "No Results were found.",
        icon: "warning",
        actions: [
          {
            text: "Okay",
            handler: () => options.store.dispatch("hideGlobalAlert"),
            color: "primary"
          }
        ]
      });
      throw error;
    },
    422: error => {
      options.store.dispatch("setGlobalAlertState", {
        title: "Error!",
        icon: "error",
        minWidth: "350px",
        description: formatErrorResponse(error),
        actions: [
          {
            text: "Okay",
            handler: () => options.store.dispatch("hideGlobalAlert"),
            color: "primary"
          }
        ]
      });
      throw error;
    }
  };

  axios.interceptors.response.use(
    async response => {
      options.store.commit(
        "activeAxiosCalls",
        options.store.getters.activeAxiosCalls - 1
      );
      if (response.config.logging !== false) {
        Vue.prototype.$hubapp.log("debug", await createLogMessage(response));
      }
      return response.data;
    },
    async error => {
      options.store.commit(
        "activeAxiosCalls",
        options.store.getters.activeAxiosCalls - 1
      );

      let errorMessage = "";
      if (Array.isArray(error.response.data)) {
        errorMessage = error.response.data[0].err;
      } else {
        errorMessage = error.response.data.err;
      }

      console.error(errorMessage);
      if (error.config.logging !== false) {
        Vue.prototype.$hubapp.log(
          "error",
          await createLogMessage(error.response)
        );
      }

      const errorHandlers = Object.assign(
        cloneDeep(axios.defaultErrorHandlers),
        error.config.customErrorHandlers
      );
      if (!errorHandlers[error.response.status]) {
        return Promise.reject(error);
      }
      return errorHandlers[error.response.status](error);
    }
  );

  Vue.prototype.$api = options.api;
}

export default {
  install,
  axios
};
