var permissionMap = {
  star: {
    access: 0,
    editStateFees: 1,
    editCountyFees: 2,
    editNonFeeInfo: 3,
    editWork: 4,
    editAnyDay: 5,
    voidWork: 6,
    voidAnyDay: 7,
    assignTitleROOnEdit: 8,
    acceptTransferInventory: 9
  }
};

var dashBoardDefault = `[
    {"x":0,"y":0,"w":5,"h":14,"i":1,"type":"Navigation"},
    {"x":5,"y":0,"w":7,"h":7,"i":2,"type":"Actions"},
    {"x":12,"y":0,"w":7,"h":7,"i":3,"type":"Bookmarks"},
    {"x":5,"y":7,"w":14,"h":7,"i":4,"type":"Previous"}
]`;

var states = [
  {
    name: "Alabama",
    abbr: "AL"
  },
  {
    name: "Alaska",
    abbr: "AK"
  },
  {
    name: "Arizona",
    abbr: "AZ"
  },
  {
    name: "Arkansas",
    abbr: "AR"
  },
  {
    name: "California",
    abbr: "CA"
  },
  {
    name: "Colorado",
    abbr: "CO"
  },
  {
    name: "Connecticut",
    abbr: "CT"
  },
  {
    name: "Delaware",
    abbr: "DE"
  },
  {
    name: "District Of Columbia",
    abbr: "DC"
  },
  {
    name: "Florida",
    abbr: "FL"
  },
  {
    name: "Georgia",
    abbr: "GA"
  },
  {
    name: "Hawaii",
    abbr: "HI"
  },
  {
    name: "Idaho",
    abbr: "ID"
  },
  {
    name: "Illinois",
    abbr: "IL"
  },
  {
    name: "Indiana",
    abbr: "IN"
  },
  {
    name: "Iowa",
    abbr: "IA"
  },
  {
    name: "Kansas",
    abbr: "KS"
  },
  {
    name: "Kentucky",
    abbr: "KY"
  },
  {
    name: "Louisiana",
    abbr: "LA"
  },
  {
    name: "Maine",
    abbr: "ME"
  },
  {
    name: "Maryland",
    abbr: "MD"
  },
  {
    name: "Massachusetts",
    abbr: "MA"
  },
  {
    name: "Michigan",
    abbr: "MI"
  },
  {
    name: "Minnesota",
    abbr: "MN"
  },
  {
    name: "Mississippi",
    abbr: "MS"
  },
  {
    name: "Missouri",
    abbr: "MO"
  },
  {
    name: "Montana",
    abbr: "MT"
  },
  {
    name: "Nebraska",
    abbr: "NE"
  },
  {
    name: "Nevada",
    abbr: "NV"
  },
  {
    name: "New Hampshire",
    abbr: "NH"
  },
  {
    name: "New Jersey",
    abbr: "NJ"
  },
  {
    name: "New Mexico",
    abbr: "NM"
  },
  {
    name: "New York",
    abbr: "NY"
  },
  {
    name: "North Carolina",
    abbr: "NC"
  },
  {
    name: "North Dakota",
    abbr: "ND"
  },
  {
    name: "Ohio",
    abbr: "OH"
  },
  {
    name: "Oklahoma",
    abbr: "OK"
  },
  {
    name: "Oregon",
    abbr: "OR"
  },
  {
    name: "Pennsylvania",
    abbr: "PA"
  },
  {
    name: "Rhode Island",
    abbr: "RI"
  },
  {
    name: "South Carolina",
    abbr: "SC"
  },
  {
    name: "South Dakota",
    abbr: "SD"
  },
  {
    name: "Tennessee",
    abbr: "TN"
  },
  {
    name: "Texas",
    abbr: "TX"
  },
  {
    name: "Utah",
    abbr: "UT"
  },
  {
    name: "Vermont",
    abbr: "VT"
  },
  {
    name: "Virginia",
    abbr: "VA"
  },
  {
    name: "Washington",
    abbr: "WA"
  },
  {
    name: "West Virginia",
    abbr: "WV"
  },
  {
    name: "Wisconsin",
    abbr: "WI"
  },
  {
    name: "Wyoming",
    abbr: "WY"
  }
];

module.exports = {
  permissionMap,
  states,
  dashBoardDefault
};
