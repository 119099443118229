<template>
  <div class="modal">
    <div class="modalClose">
      <v-btn icon @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <div class="modalBody">
      <h1>No Results Found!</h1>
      <h2 style="font-size: 18pt; margin-bottom: 10px" v-if="title != ''">
        {{ title }}
      </h2>
      <div class="modalButtons" v-if="optionsList.length > 0">
        <div v-for="value in optionsList" :key="value.val">
          <v-btn color="primary" @click="emitValue(value.val)">{{
            value.display
          }}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    optionsList: { type: Array }
  },
  methods: {
    emitValue(val) {
      this.$emit("optionSelected", val);
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.modal {
  z-index: 9999;
  width: 50vw;
  height: 75vh;
  min-height: 530px;
  position: fixed;
  top: 12.5vh;
  left: 25vw;
  border-radius: 10px;
  border: 1px lightgrey solid;
  padding: 10px;
  display: block;
}
.modalClose {
  position: absolute;
  right: 0;
}
.modalBody {
  text-align: center;
  padding: 3%;
}
.modalButtons button {
  width: 200px;
  margin: 3px;
}
</style>
