/* Values that don't change after the first load (e.g. online renewal rejection reasons). These can usually be obtained on login before they are needed. */
const state = {
  webRenewalRejectReasons: {},
  transactionStatuses: {},
  users: [],
  newMetalPlateClasses: [],
  plateAssignConfig: "U",
  countyCities: []
};

const actions = {
  async getCountyCities({ commit, getters }) {
    if (getters.countyCities.length > 0) return;

    const countyCities =
      (await this._vm.$api.getCities(getters.countyId, {
        404: () => {}
      })) || [];

    // format elements
    countyCities.forEach((city, index, cities) => {
      cities[index].cityName = this._vm._.capitalize(city.cityName);
    });

    commit("countyCities", countyCities);
  }
};

const getters = {
  webRenewalRejectReasons: state => {
    return state.webRenewalRejectReasons;
  },
  transactionStatuses: state => {
    return state.transactionStatuses;
  },
  transactionStatusCodes: state => {
    return this._vm._.invert(state.transactionStatuses);
  },
  users: state => {
    return state.users;
  },
  user: state => id => state.users.find(user => user.id === id),
  countyCities: state => state.countyCities,
  newMetalPlateClasses: state => state.newMetalPlateClasses,
  plateAssignConfig: state => state.plateAssignConfig
};

const mutations = {
  webRenewalRejectReasons: (state, payload) => {
    state.webRenewalRejectReasons = payload;
  },
  transactionStatuses: (state, payload) => {
    state.transactionStatuses = payload;
  },
  users: (state, payload) => {
    state.users = payload;
  },
  countyCities: (state, payload) => {
    state.countyCities = payload;
  },
  newMetalPlateClasses: (state, payload) => {
    state.newMetalPlateClasses = payload;
  },
  plateAssignConfig: (state, payload) => {
    state.plateAssignConfig = payload;
  }
};

export default {
  actions,
  state,
  getters,
  mutations
};
