<template>
  <div>
    <v-row class="pa-0 ma-0 main-row">
      <v-col class="pa-0 ma-0" cols="4" sm="5">
        <v-card elevation="10" class="ma-0 login-card">
          <v-form ref="loginForm" class="full-width" lazy-validation>
            <v-row
              align="center"
              justify="center"
              class="pl-9 pr-9 ma-0 full-height"
            >
              <v-col class="ma-0">
                <h2 class="title-text">STARS</h2>
                <v-text-field
                  v-if="keyReturn == undefined || keyReturn == ''"
                  label="Username"
                  placeholder=" "
                  @keyup.enter="
                    $refs.loginForm.validate() === true ? login() : ''
                  "
                  v-model="username"
                  @input="[(credentialError = false), (authError = false)]"
                  id="username"
                  :rules="[formRules.required]"
                  :error-messages="credentialError ? ' ' : authError ? ' ' : ''"
                />
                <v-text-field
                  @keyup.enter="
                    $refs.loginForm.validate() === true ? login() : ''
                  "
                  v-model="password"
                  @input="[(credentialError = false), (authError = false)]"
                  placeholder=" "
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  label="Password"
                  @click:append="showPass = !showPass"
                  :rules="[formRules.required]"
                  :error-messages="
                    credentialError
                      ? 'Invalid credentials. Please try again.'
                      : authError
                      ? 'Access denied. Please verify user permissions.'
                      : ''
                  "
                ></v-text-field>
                <v-select
                  label="County"
                  :items="counties"
                  return-object
                  item-text="countyName"
                  v-model="county"
                  :rules="[formRules.required]"
                />
                <v-select
                  label="Office"
                  :disabled="offices.length <= 1"
                  :items="offices"
                  return-object
                  item-text="officeName"
                  v-model="office"
                  :rules="[formRules.required]"
                />
                <v-select
                  label="Workstation"
                  :disabled="workstations.length <= 1"
                  :items="workstations"
                  item-text="workstationName"
                  return-object
                  v-model="workstation"
                  :rules="[formRules.required]"
                />
                <v-text-field
                  label="Drawer"
                  @keyup.enter="
                    $refs.loginForm.validate() === true ? login() : ''
                  "
                  v-model="drawerNo"
                  id="drawerNo"
                  required="required"
                  @focus="$event.target.select()"
                  :rules="[formRules.required]"
                  data-testid="drawerField"
                />
                <v-text-field
                  label="Invoice"
                  @keyup.enter="
                    $refs.loginForm.validate() === true ? login() : ''
                  "
                  append-icon="mdi-undo-variant"
                  @click:append="invoiceNo = todayInvoice"
                  v-model.number="invoiceNo"
                  id="invoiceNo"
                  required="required"
                  class="pb-1"
                  :rules="[formRules.required]"
                  data-testid="invoiceField"
                />
                <v-btn
                  :loading="isLoading"
                  @click="$refs.loginForm.validate() === true ? login() : ''"
                  large
                  class="full-width"
                  color="primary"
                >
                  <span
                    class="white-text"
                    v-if="keyReturn != undefined && keyReturn != ''"
                  >
                    Set Values
                  </span>
                  <span class="white-text" v-else>Log In</span>
                </v-btn>
                <div class="hub-links mt-2">
                  <div>
                    <a :href="installerPath" download
                      >Download the Star Hub App</a
                    >
                  </div>
                  <div id="updates">
                    <a @click="checkForUpdates">Check for Updates</a>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
      <div
        class="transition-roller"
        :style="isLoggingIn ? 'width: 100vw' : ''"
      ></div>
      <v-col class="pa-0 ma-0 right-container">
        <div class="right-login">
          <div class="star-five" />
          <div class="bottom-container">
            <v-row justify="space-around">
              <v-col cols="2">
                <v-img
                  :src="apiUrl + '/stars.png'"
                  max-width="50"
                  class="mr-5 bottom-logo"
                />
              </v-col>
              <v-col>
                <div class="mt-3 copyright">
                  Business Information Systems
                  <v-icon>mdi-copyright</v-icon>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*global IsSigWebInstalled*/ 
import customCSSFunctions from "@/mixins/customCSSFunctions.js";
import starInitialize from "@/mixins/starInitialize.mixin.js";
import { mapGetters, mapActions } from "vuex";
import transaction from "@/mixins/transaction.mixin.js";

export default {
  name: "login",
  mixins: [customCSSFunctions, starInitialize, transaction],
  props: ["tnClerkKey"],
  data() {
    return {
      showPass: false,
      username: "",
      password: "",
      county: undefined,
      office: undefined,
      workstation: undefined,
      drawerNo: this.$store.getters.drawerNo,
      invoiceNo: this.$store.getters.invoiceNo,
      saveUser: false,
      keyReturn: this.tnClerkKey,
      isCreated: false,
      isLoading: false,
      isLoggingIn: false,
      authError: false,
      credentialError: false,
      formRules: {
        required: v => !!v || "This field is required."
      }
    };
  },
  computed: {
    ...mapGetters({
      apiUrl: "apiUrl",
      counties: "locations",
      countyId: "countyId",
      officeId: "officeId",
      workstationId: "workstationId",
      hubConfiguration: "hubConfiguration"
    }),
    offices() {
      return this._.get(this.county, "offices") || [];
    },
    workstations() {
      return this._.get(this.office, "workstations") || [];
    },
    todayInvoice() {
      return parseInt(this.getTodaysInvoice());
    },
    installerPath() {
      return this.$store.getters.installerPath;
    },
    usernameAndPartner() {
      if (this.username === "" || this.username.includes("@") || !this.county) {
        return this.username;
      } else {
        return this.username + "@" + this.county.countyName.toLowerCase();
      }
    }
  },
  methods: {
    ...mapActions(["getLocations", "getHubConfiguration"]),
    async login() {
      if (!this.username || !this.password) {
        return;
      }
       

      const customErrors = {
        401: () => {
          this.authError = true;
        },
        404: () => {
          this.credentialError = true;
        }
      };
     this.$store.commit("drawerNo",this.drawerNo);
     this.$store.commit("invoiceNo",this.invoiceNo);

      try {
        this.isLoading = true;
        const json = await this.$api.login(
          {
            //                            cookie: true, // todo> enable to prevent xss
            countyId: parseInt(this.countyId),
            officeId: parseInt(this.officeId),
            drawerNo: parseInt(this.drawerNo),
            workstationId: parseInt(this.workstationId),
            invoiceNo:this.invoiceNo,
            username: this.usernameAndPartner,
            password: this.password
          },
          false,
          customErrors
        );

        const localStorageInfo = {
          countyId: parseInt(this.countyId),
          officeId: parseInt(this.officeId),
          siteNo: parseInt(this.office.siteNo),
          drawerNo: parseInt(this.$store.getters.drawerNo),
          workstationId: parseInt(this.workstationId),
          invoiceNo:this.$store.getters.invoiceNo
        };

        if (json.featureFlags) {
          Object.assign(localStorageInfo, { featureFlags: json.featureFlags });
         this.$store.dispatch("setFeatureFlags", json.featureFlags);
        }
        if (json.countyConfig) {
          Object.assign(localStorageInfo, { countyConfig: json.countyConfig });
         this.$store.dispatch("mergeCountyConfig", json.countyConfig);
        }
        if (json.officeConfig) {
          Object.assign(localStorageInfo, { officeConfig: json.officeConfig });
         this.$store.dispatch("mergeOfficeConfig", json.officeConfig);
        }

        localStorage.setItem("loginInfo", JSON.stringify(localStorageInfo));
       this.$store.commit("partnerName", json.partnerName);
        try {
          await this.doStarInit(json.token,this.$store);
        } catch (e) {
          this.$root.$emit(
            "push-alert",
            "Initialization Failed. Please Try Again.",
            { color: "error" }
          );
          this.logout();
          throw e;
        }

        localStorage.setItem("starApiToken", json.token);
       this.$emit("loginSuccessful");
        this.isLoggingIn = true;

        //wait for animation to finish before continue.
        setTimeout(() => {
         this.$router.push({ name: "Home" });
        }, 1000); //roll animation time is 1 sec
      } catch (e) {
        return;
      } finally {
        this.isLoading = false;
      }
      this.checkForSigWeb();
    },
    checkForUpdates() {
       
      this.$hubapp
        .checkForUpdates()
        .then(() => {
          this.$root.$emit(
            "push-alert",
            "STAR Hub Application Is Checking For Updates.",
            { color: "blue" }
          );
        })
        .catch(() => {
          this.$root.$emit(
            "push-alert",
            "STAR Hub Application is not running.",
            { color: "red" }
          );
        });
    },
    resetPassword: function () {
      this.$store.dispatch("setGlobalAlertState", {
        title: "Not Available",
        description:
          "Please contact the administator of this site for a new password",
        icon: "error"
      });
    },
    showPassword: function () {
      document.getElementById("password").type = "text";
    },
    hidePassword: function () {
      document.getElementById("password").type = "password";
    },
    async logout() {
      try {
        await this.$api.logout();
      } finally {
        this.$store.dispatch("setApiToken", "");
        this.$store.commit("invoiceNo", this.todayInvoice);
        this.invoiceNo = this.todayInvoice;
        localStorage.removeItem("starApiToken");
      }
    },
    checkForSigWeb() {
      // This is specifically for Topaz Signature Pads
      // Use the SigWebTablet.js functions to check for SigWeb install
      if (typeof IsSigWebInstalled !== "function") return;
      if (!IsSigWebInstalled()) {
        this.$hubapp.installSigWeb();
      }
    }
  },
  watch: {
    county(county) {
      this.$store.commit("countyId", this._.get(county, "countyID"));

      if (this.isCreated) {
        this.office = undefined;
        this.workstation = undefined;
      }
      if (this._.get(county, "offices.length") === 1)
        this.office = county.offices[0];
    },
    office(office) {
      this.$store.commit("officeId", this._.get(office, "officeID"));
      this.$store.commit("siteNo", this._.get(office, "siteNo"));

      if (this.isCreated) {
        this.workstation = undefined;
      }
      if (this._.get(office, "workstations.length") === 1)
        this.workstations = office.workstations[0];
    },
    workstation(workstation) {
      this.$store.commit(
        "workstationId",
        this._.get(workstation, "workstationID")
      );
    }
  },
  async created() {
    await this.getLocations();

    this.$store.commit("todaysInvoiceNo", this.todayInvoice);
    if (this.countyId === undefined) {
      await this.getHubConfiguration();
      if (this._.has(this.hubConfiguration, "location")) {
        ["countyId", "officeId", "workstationId"].forEach(id => {
          if (this.hubConfiguration.location[id])
            this.$store.commit(id, this.hubConfiguration.location[id]);
        });
      }
    }

    // initialize county/office/workstation from store
    if (this.countyId != undefined && Array.isArray(this.counties)) {
      this.county = this.counties.find(
        county => county.countyID === this.countyId
      );
      if (this.officeId != undefined) {
        this.office = this.county.offices.find(
          office => office.officeID === this.officeId
        );
        if (this.workstationId != undefined) {
          this.workstation = this.office.workstations.find(
            workstation => workstation.workstationID === this.workstationId
          );
        }
      }
    }

    this.$store.commit("countyId", this.county?.countyID);
    this.$store.commit("officeId", this.office?.officeID);
    this.$store.commit("workstationId", this.workstation?.workstationID);

    this.$nextTick(() => (this.isCreated = true));
  },
  async mounted() {
     

   this.$store.commit("userObject", {});
    this.$root.$emit("setLoading", false);

    const emptyConfig = require("@/assets/jsonScaffolds/MVConfigurationScaffold.json");
   this.applyCustomCSS(emptyConfig.customCSS);

    // this is so hitting this page actually logs you out of the api
    if (this.$store.getters.apiToken != "") {
      this.logout();
    }
  }
};
</script>

<style scoped lang="scss">
$star-color: #bdd6ff;

.copyright {
  font-size: 1.2rem;
  display: inline-block;
}

.bottom-logo {
  display: inline-block;
}

.right-container {
  max-height: 100vh;
  overflow: hidden;
}

.bottom-container {
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.transition-roller {
  height: 100vh;
  width: 0vw;
  position: absolute;
  background-color: #fdfdfd;
  z-index: 2;
  transition: width 1s;
}

.title-text {
  font-size: 2rem;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.main-row {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.login-card {
  display: flex !important;
  height: 100%;
  z-index: 1;
  text-align: center;
}

.right-login {
  background-color: #fafafa;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.star-five {
  margin: 50px 0;
  position: relative;
  display: block;
  color: $star-color;
  width: 0px;
  z-index: 0;
  height: 0px;
  border-right: 100px solid transparent;
  border-bottom: 70px solid $star-color;
  border-left: 100px solid transparent;
  transform: rotate(35deg) scale(12);
  margin-left: 90%;
  margin-top: 60%;
}
.star-five:before {
  border-bottom: 80px solid $star-color;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -45px;
  left: -65px;
  display: block;
  content: "";
  transform: rotate(-35deg);
}
.star-five:after {
  position: absolute;
  display: block;
  color: $star-color;
  top: 3px;
  left: -105px;
  width: 0px;
  height: 0px;
  border-right: 100px solid transparent;
  border-bottom: 70px solid $star-color;
  border-left: 100px solid transparent;
  transform: rotate(-70deg);
  content: "";
}

.panel {
  min-width: 500px;
}

.login {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.hub-links {
  margin-left: 15px;
}

#updates {
  position: relative;
  top: 10px;
}
</style>
