<template>
  <div>
    <v-app-bar dense fixed id="mainNavBar">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon
            v-on="on"
            color="black"
            class="clickable"
            @click="$emit('hamClick')"
          ></v-app-bar-nav-icon>
        </template>
        <span>"Ctrl + i" to open</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-toolbar-title
            id="main-logo"
            v-on="on"
            class="clickable light"
            @click="handleLogoClick"
          >
            <v-avatar>
              <v-img alt="main logo" :src="apiUrl + '/stars.png'" />
            </v-avatar>
          </v-toolbar-title>
        </template>
        <span>Home</span>
      </v-tooltip>

      <div class="simple" v-if="apiToken != '' && isFeatureEnabled('mvSearch')">
        <search v-model="simpleSearch"></search>
      </div>
      <div
        v-if="
          ['NewTransaction', 'Transaction', 'Details'].includes($route.name) &&
          !_.isEmpty(vehicle) &&
          !_.isEmpty(vehicle.makeCode) &&
          (vehicle.makeCode.length > 0 ||
            vehicle.modelCode.length > 0 ||
            vehicle.modelYear > 0)
        "
        class="nav-info"
      >
        {{ vehicle.makeCode.length > 0 ? vehicle.makeCode : "" }}
        {{
          vehicle.makeCode.length > 0 &&
          (vehicle.modelCode.length > 0 || vehicle.modelYear > 0)
            ? " / "
            : ""
        }}
        {{ vehicle.modelCode.length > 0 ? vehicle.modelCode : "" }}
        {{ vehicle.modelCode.length > 0 && vehicle.modelYear > 0 ? " / " : "" }}
        {{ vehicle.modelYear > 0 ? vehicle.modelYear : "" }}
      </div>

      <v-spacer></v-spacer>

      <div v-if="updateNotification" class="updateAlert">
        <v-badge
          icon="mdi-exclamation-thick"
          color="red-text"
          overlap
          offset-y="25"
          offset-x="20"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="window.location.reload(true)">
                <v-icon>mdi-reload-alert</v-icon>
              </v-btn>
            </template>
            <span>Update the site & Reload</span>
          </v-tooltip>
        </v-badge>
      </div>

      <v-menu
        v-if="apiToken != ''"
        min-width="25%"
        allow-overflow
        offset-y
        transition="slide-x"
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                class="no-transform"
                text
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon color="primary" class="light mr-2 user-account-text">
                  mdi-account
                </v-icon>
                {{ userInfo.fullname }}
              </v-btn>
            </template>
            <span v-if="workstationName && isFeatureEnabled('mvClerkInfo')">
              {{ workstationName }} / Drawer:{{ drawerNo }} / Invoice:{{
                invoiceNo
              }}
            </span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="5" v-if="userInfo.secondaryUserNames">
                  <b class="header-color">
                    {{ userInfo.secondaryUserNames[0].userName.split("@")[0] }}
                  </b>
                  <br />
                  <span class="ml-2">
                    @{{ userInfo.secondaryUserNames[0].userName.split("@")[1] }}
                  </span>
                </v-col>
                <v-col v-if="isFeatureEnabled('mvClerkInfo')" cols="7">
                  <b class="header-color">Office:</b>
                  {{ officeName }}<br />
                  <b class="header-color">Workstation:</b>
                  {{ workstationName }}<br />
                  <b class="header-color">Drawer:</b>
                  {{ drawerNo }}<br />
                  <b class="header-color">Invoice:</b>
                  {{ invoiceNo }}
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
          <v-list-item
            v-if="isFeatureEnabled('mvClerksGuide')"
            @click="
              () => {
                window.open('https://tnclerks.zendesk.com/hc/en-us');
              }
            "
          >
            <v-list-item-title>County Clerks Guide</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="isFeatureEnabled('mvSettings')"
            @click="$router.push({ name: 'UserInfo' })"
          >
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item @click="$router.push({ name: 'Login' })">
            <v-list-item-title>
              <span class="red-text">Log Out</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <advancedSearch
      v-if="isFeatureEnabled('mvSearch')"
      id="advancedMenu"
      :class="{
        noDisplay: !showAdvancedSearchComputed
      }"
      @close="hideAdvanced"
    />
    <v-overlay :value="showAdvancedSearchComputed" />

    <noResultsMenu
      v-if="showNoResults"
      :title="noResultsMenuTitle"
      :optionsList="noResultsMenuOptions"
      @close="hideModal"
      @optionSelected="noResultsMenuFunction"
    >
    </noResultsMenu>
  </div>
</template>

<script>
import navSearch from "@/components/nonPageComponents/search";
import searchFunctions from "@/mixins/searchFunctions.js";
import vehicleFunctions from "@/mixins/vehicleFunctions.js";
import noResultsMenu from "@/components/nonPageComponents/noResultsMenu";
import advancedSearch from "@/components/nonPageComponents/advancedSearch";
import { mapGetters } from "vuex";

export default {
  mixins: [searchFunctions, vehicleFunctions],
  components: {
    search: navSearch,
    noResultsMenu,
    advancedSearch
  },
  data() {
    return {
      window: window,
      menuVisible: false,
      simpleSearch: "",
      noResultsMenuTitle: ""
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userObject",
      apiUrl: "apiUrl",
      apiToken: "apiToken",
      showNoResults: "showNoResults",
      noResultsMenuOptions: "noResultsMenuOptions",
      showAdvancedSearchComputed: "showAdvancedSearch",
      vehicle: "vehicle",
      updateNotification: "updateNotification",
      workstationName: "workstationName",
      workstation: "workstation",
      drawerNo: "drawerNo",
      invoiceNo: "invoiceNo",
      isFeatureEnabled: "isFeatureEnabled",
      isAdvancedSearch: "isAdvancedSearch",
      vin: "vin",
      plateNo: "plateNo"
    }),
    officeName() {
      const county = this.$store.getters.locations.find(
        county => county.countyID === this.$store.getters.countyId
      );
      if (!county) return;
      const office = county.offices.find(
        office => office.officeID === this.$store.getters.officeId
      );

      return office.officeName;
    }
  },
  methods: {
    handleLogoClick() {
      if (this.$router.currentRoute.path == "/") window.location.reload(true);
      // this creates a work-around for the user if the router state gets stuck at '/'.
      else this.$router.push({ name: "Home" });
    },
    hideOnBodyClick() {
      this.menuVisible = false;
      this.hideAdvanced();
    },
    clickMethod(value) {
      value["func"]();
      this.toggleMenu();
    },
    logOut() {
      window.location.reload(true);
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/global-variables.scss";
.red-text {
  color: red;
}
.user-account-text {
  font-size: 2.5rem !important;
}
.header-color {
  color: var(--header-color);
}
.no-transform {
  text-transform: none !important;
}
#main-logo {
  margin-left: 20px;
  white-space: nowrap;
  overflow: visible;
}

.updateAlert {
  margin-right: 25px;
}

.v-btn {
  border: none;
}

#mainNavBar {
  background-color: var(--nav-background);
  z-index: 10;
}

#mainNavBar .welcome {
  white-space: nowrap;
}

#mainNavBar .logout {
  text-align: right;
}

#mainNavBar .simple {
  margin-left: 7%;
}

#mainNavBar .light {
  color: var(--text);
}

#mainNavBar .logout a {
  color: var(--text);
}

.nav-info {
  margin: 32px;
  color: var(--text);
}

#nav-view {
  height: 100vh;
}

#advancedMenu {
  transition: all 0.2s ease-in-out;
  line-height: 0;
  padding: 0 1em;
  color: transparent;
}

.modal.noDisplay {
  min-height: 0;
  height: 0;
  transition: all 0.5s ease-in-out;
  padding: 0 1em;
  color: transparent;
}

#mainNavBar {
  position: fixed;
  top: 0;
  height: $nav-height;
  color: var(--primary);
}

#appContent {
  height: 100vh;
  padding-top: $nav-height;
  min-height: 100vh;
  margin: 0;
}
</style>
