<template>
  <div>
    <h2>
      <v-icon color="primary" size="48px">mdi-star</v-icon>
      Bookmarks
    </h2>
    <div>
      <ul>
        <li
          v-for="(bookmark, index) in bookmarks"
          :key="index"
          @click="routeToLocation(bookmark.route)"
        >
          {{ bookmark.title }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bookmarks: [
        { title: "Inventory", route: "Inventory" },
        { title: "Deficients", route: "DeficientTransactions" },
        { title: "Online Renewals", route: "OnlineRenewals" },
        { title: "Control Numbers", route: "ControlNumbers" },
        { title: "Title Printing", route: "TitlePrinting" }
        // {title: 'Cash Check Report', route: 'CashCheck'}, //added this for demos only
      ]
    };
  },
  methods: {
    routeToLocation(routeName) {
      this.$router.push({ name: routeName });
    }
  }
};
</script>
