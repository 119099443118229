<template>
  <div id="bisAlert">
    <v-overlay absolute :value="show" />
    <v-dialog width="unset" v-model="show" :persistent="isPersistent">
      <v-card
        class="scan-close-modal"
        :min-width="minWidth"
        :max-width="maxWidth"
      >
        <div v-if="icon !== ''">
          <v-icon size="100px" :color="iconColorMap[icon]">{{
            iconMap[icon]
          }}</v-icon>
        </div>
        <v-card-title class="font-weight-bold justify-center">{{
          title
        }}</v-card-title>
        <v-card-text class="font-weight-500" v-if="description">
          {{ description }}
        </v-card-text>

        <template v-if="!['', null, undefined].includes(components)">
          <div
            v-for="component in components"
            :key="component"
            class="alert-components"
          >
            <div
              v-if="component.name === 'v-select'"
              v-bind:is="component.name"
              :items="component.items"
              v-model="component.items[0]"
              @change="updateComponentValue"
            ></div>
          </div>
        </template>

        <v-card-actions class="d-flex justify-space-around">
          <template v-if="['', null, undefined].includes(actions)">
            <v-btn @click="$store.dispatch('hideGlobalAlert')" color="primary"
              >Okay</v-btn
            >
          </template>
          <template v-else>
            <v-btn
              v-for="action in actions"
              :key="action.text"
              @click="action.handler"
              :color="action.color"
              class="alert-button"
            >
              {{ action.text }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      validator: val => ["error", "warning", "success", ""].includes(val),
      default: ""
    },
    value: { type: Boolean, required: true },
    title: { type: String, required: false },
    description: { type: String, required: false },
    isPersistent: { type: Boolean, default: true },
    maxWidth: { type: String, default: "40vw" },
    minWidth: { type: String, default: "30vw" },
    actions: { type: Array, required: false },
    components: { type: Array, required: false }
  },
  data() {
    return {
      iconMap: {
        error: "mdi-close-circle-outline",
        warning: "mdi-alert-circle-outline",
        success: "mdi-check-circle-outline"
      },
      iconColorMap: {
        error: "#cc3300",
        warning: "#ffcc00",
        success: "#99cc33"
      }
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    updateComponentValue(value) {
      this.$store.commit("alertComponentValue", value);
    }
  }
};
</script>
<style scoped>
.scan-close-modal {
  text-align: center;
  padding: 25px;
}
.alert-components {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.v-card ::v-deep .v-card__title {
  font-size: 30px !important;
  word-break: normal;
}

.v-card ::v-deep .v-card__text {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-size: 18px !important;
  color: #545454 !important;
  padding-top: 20px !important;
  white-space: pre-line;
}

.v-card__actions ::v-deep .v-btn {
  padding: 10px 30px !important;
  font-size: 16px !important;
}
#bisAlert {
  z-index: 3768;
}
</style>
