const state = {
  userInfo: {},
  loginSuccess: false
};

const getters = {
  getInfo: state => {
    return state.userInfo;
  }
};

const mutations = {
  updateUser: (state, payload) => {
    state.userInfo = payload;
    state.loginSuccess = true;
  }
};

const actions = {
  updateUser: (context, payload) => {
    context.commit("updateUser", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
