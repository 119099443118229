import { make } from "vuex-pathify";

const state = {
  featureFlags: {},
  countyConfig: {
    printRegistrationLaserCopy: true,
    hasWheelTaxCities: false,
    useCountyWheelTaxDecals: false,
    useCityWheelTaxDecals: false,
    promptForMLHTitleIssuance: false,
    autoAssignInventoryClasses: []
  }
};

const mutations = {
  ...make.mutations(state),
  MERGE_COUNTY_CONFIG(state, objToMerge) {
    state.countyConfig = Object.assign(state.countyConfig, objToMerge);
  }
};
// automatically create mutations (e.g. setLoading, setError)

const actions = {
  ...make.actions(state),
  mergeCountyConfig(state, objToMerge) {
    state.commit("MERGE_COUNTY_CONFIG", objToMerge);
  }
};

const getters = {
  ...make.getters(state),
  isFeatureEnabled: state => feature => {
    return (
      Object.prototype.hasOwnProperty.call(state.featureFlags, feature) &&
      state.featureFlags[feature] === true
    );
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
