var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"dense":"","fixed":"","id":"mainNavBar"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({staticClass:"clickable",attrs:{"color":"black"},on:{"click":function($event){return _vm.$emit('hamClick')}}},on))]}}])},[_c('span',[_vm._v("\"Ctrl + i\" to open")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-toolbar-title',_vm._g({staticClass:"clickable light",attrs:{"id":"main-logo"},on:{"click":_vm.handleLogoClick}},on),[_c('v-avatar',[_c('v-img',{attrs:{"alt":"main logo","src":_vm.apiUrl + '/stars.png'}})],1)],1)]}}])},[_c('span',[_vm._v("Home")])]),(_vm.apiToken != '' && _vm.isFeatureEnabled('mvSearch'))?_c('div',{staticClass:"simple"},[_c('search',{model:{value:(_vm.simpleSearch),callback:function ($$v) {_vm.simpleSearch=$$v},expression:"simpleSearch"}})],1):_vm._e(),(
        ['NewTransaction', 'Transaction', 'Details'].includes(_vm.$route.name) &&
        !_vm._.isEmpty(_vm.vehicle) &&
        !_vm._.isEmpty(_vm.vehicle.makeCode) &&
        (_vm.vehicle.makeCode.length > 0 ||
          _vm.vehicle.modelCode.length > 0 ||
          _vm.vehicle.modelYear > 0)
      )?_c('div',{staticClass:"nav-info"},[_vm._v(" "+_vm._s(_vm.vehicle.makeCode.length > 0 ? _vm.vehicle.makeCode : "")+" "+_vm._s(_vm.vehicle.makeCode.length > 0 && (_vm.vehicle.modelCode.length > 0 || _vm.vehicle.modelYear > 0) ? " / " : "")+" "+_vm._s(_vm.vehicle.modelCode.length > 0 ? _vm.vehicle.modelCode : "")+" "+_vm._s(_vm.vehicle.modelCode.length > 0 && _vm.vehicle.modelYear > 0 ? " / " : "")+" "+_vm._s(_vm.vehicle.modelYear > 0 ? _vm.vehicle.modelYear : "")+" ")]):_vm._e(),_c('v-spacer'),(_vm.updateNotification)?_c('div',{staticClass:"updateAlert"},[_c('v-badge',{attrs:{"icon":"mdi-exclamation-thick","color":"red-text","overlap":"","offset-y":"25","offset-x":"20"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.window.location.reload(true)}}},on),[_c('v-icon',[_vm._v("mdi-reload-alert")])],1)]}}],null,false,1997844320)},[_c('span',[_vm._v("Update the site & Reload")])])],1)],1):_vm._e(),(_vm.apiToken != '')?_c('v-menu',{attrs:{"min-width":"25%","allow-overflow":"","offset-y":"","transition":"slide-x"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var menu = ref.on;
      var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-transform",attrs:{"text":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"light mr-2 user-account-text",attrs:{"color":"primary"}},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(_vm.userInfo.fullname)+" ")],1)]}}],null,true)},[(_vm.workstationName && _vm.isFeatureEnabled('mvClerkInfo'))?_c('span',[_vm._v(" "+_vm._s(_vm.workstationName)+" / Drawer:"+_vm._s(_vm.drawerNo)+" / Invoice:"+_vm._s(_vm.invoiceNo)+" ")]):_vm._e()])]}}],null,false,4025964057)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-row',[(_vm.userInfo.secondaryUserNames)?_c('v-col',{attrs:{"cols":"5"}},[_c('b',{staticClass:"header-color"},[_vm._v(" "+_vm._s(_vm.userInfo.secondaryUserNames[0].userName.split("@")[0])+" ")]),_c('br'),_c('span',{staticClass:"ml-2"},[_vm._v(" @"+_vm._s(_vm.userInfo.secondaryUserNames[0].userName.split("@")[1])+" ")])]):_vm._e(),(_vm.isFeatureEnabled('mvClerkInfo'))?_c('v-col',{attrs:{"cols":"7"}},[_c('b',{staticClass:"header-color"},[_vm._v("Office:")]),_vm._v(" "+_vm._s(_vm.officeName)),_c('br'),_c('b',{staticClass:"header-color"},[_vm._v("Workstation:")]),_vm._v(" "+_vm._s(_vm.workstationName)),_c('br'),_c('b',{staticClass:"header-color"},[_vm._v("Drawer:")]),_vm._v(" "+_vm._s(_vm.drawerNo)),_c('br'),_c('b',{staticClass:"header-color"},[_vm._v("Invoice:")]),_vm._v(" "+_vm._s(_vm.invoiceNo)+" ")]):_vm._e()],1)],1)],1),_c('v-divider'),(_vm.isFeatureEnabled('mvClerksGuide'))?_c('v-list-item',{on:{"click":function () {
              _vm.window.open('https://tnclerks.zendesk.com/hc/en-us');
            }}},[_c('v-list-item-title',[_vm._v("County Clerks Guide")])],1):_vm._e(),(_vm.isFeatureEnabled('mvSettings'))?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'UserInfo' })}}},[_c('v-list-item-title',[_vm._v("Settings")])],1):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({ name: 'Login' })}}},[_c('v-list-item-title',[_c('span',{staticClass:"red-text"},[_vm._v("Log Out")])])],1)],1)],1):_vm._e()],1),(_vm.isFeatureEnabled('mvSearch'))?_c('advancedSearch',{class:{
      noDisplay: !_vm.showAdvancedSearchComputed
    },attrs:{"id":"advancedMenu"},on:{"close":_vm.hideAdvanced}}):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.showAdvancedSearchComputed}}),(_vm.showNoResults)?_c('noResultsMenu',{attrs:{"title":_vm.noResultsMenuTitle,"optionsList":_vm.noResultsMenuOptions},on:{"close":_vm.hideModal,"optionSelected":_vm.noResultsMenuFunction}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }