export default function ({ axios }) {
  return {
    /*
    Sends request to api for user login. Includes sensitive data.

    {
      countyId: int,
      drawerNo: int,
      invoiceNo: int,
      password: string,
      username: string,
      workstationId: int
    }
    */
    login(data, override = false, customErrorHandlers = {}) {
      return axios({
        method: "post",
        url: "/star/login" + (override ? "?override=true" : ""),
        data,
        customErrorHandlers
      });
    },
    /*
    A simple request that logs the user out. Nothing is sent and an empty string is returned.
    */
    logout(customErrorHandlers = {}) {
      return axios({
        method: "post",
        url: "/star/logout",
        customErrorHandlers
      });
    },
    /*
    Requests fair market value for vehicle by mileage and/or vin
    Returns

    {
      default: float,
      lower: float,
      upper: float
    }
    */
    async getFairMarketValue(vin, mileage, customErrorHandlers = {}) {
      return await axios({
        url:
          "star/fairmarketvalue/" +
          vin +
          (!["", null, undefined, 0].includes(mileage)
            ? "?mileage=" + mileage
            : ""),
        customErrorHandlers
      });
    },
    /*
    Returns completed vehicle object from VTRS
    Request is formatted as
    {
      vin: string,
      makeCode: string,
      modelYear: number
    }
    */
    async VTRSVehicleInquiry(vehicle, customErrorHandlers = {}) {
      return axios({
        method: "post",
        url: "/star/tandr/inquiry",
        data: { vehicle: vehicle },
        customErrorHandlers
      });
    },
    /*
    Randomizes owner information for display on undercover transactions
    */
    async randomCustomer(countyID, nameCode, customErrorHandlers = {}) {
      return await axios({
        url: "/star/tandr/randomcutomer/" + countyID + "/" + nameCode,
        customErrorHandlers
      });
    },
    /*
    Returns transaction data by transactionId and user assignment
    */
    async transactionSearch(transactionID, assign, customErrorHandlers = {}) {
      return await axios({
        url:
          "/star/search?id=" + transactionID + (assign ? "&assign=true" : ""),
        customErrorHandlers
      });
    },
    /*
    Rejects transaction by transactionId and reason for rejection
    Used for online renewals
    */
    async rejectTransaction(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/transaction/reject",
        data,
        customErrorHandlers
      });
    },

    /*
    gets batches For display
    */
    async getRenewalBatches(queryString = "", customErrorHandlers) {
      return await axios({
        url: "/star/renewal/batches" + queryString,
        customErrorHandlers
      });
    },

    /*
    Batches Renewals on OnlineRenewal page
    */
    async doRenewalBatch(data, customErrorHandlers = {}) {
      return await axios({
        method: "POST",
        url: "/star/renewal/batch",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns online renewals on online renewals page
    */
    async renewalSearch(queryOptions, customErrorHandlers = {}) {
      return await axios({
        url: "/star/renewal/search" + queryOptions,
        customErrorHandlers
      });
    },
    /*
    Requests the registration object of the record at the time of original issue to
    be sent along to hubapp for printing
    */
    async reprintRegistration(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/reprint/registration",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns previous transactions for the user up to 'limit'
    */
    async prevTransactions(limit, customErrorHandlers = {}) {
      return await axios({
        url: "/star/previoustransactions/" + limit,
        customErrorHandlers,
        logging: false
      });
    },
    /*
    Returns placard transaction details
    */
    async placardInquiry(placard, customErrorHandlers = {}) {
      return await axios({
        url: "/star/inquiry/placard/" + placard,
        customErrorHandlers
      });
    },
    /*
    Returns placard info from VTRS
    */
    async VTRSPlacardInquiry(placard, customErrorHandlers = {}) {
      return await axios({
        url: "/star/placard/inquiry/placardno/" + placard,
        customErrorHandlers
      });
    },
    /*
    Returns object for undercover transaction
    */
    async undercoverInquiry(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/undercover/inquiry",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns clerk, office, and workstation information for the given county
    */
    async getLocations(countyID = false, customErrorHandlers = {}) {
      return await axios({
        url:
          "/location/county/search" + (countyID ? "?countyID=" + countyID : ""),
        customErrorHandlers,
        logging: false
      });
    },
    /*
    Returns cities in a given county. Used to calculate city wheel tax.
    */
    async getCities(countyID = false, customErrorHandlers = {}) {
      return await axios({
        url:
          "/location/city/search" + (countyID ? "?countyID=" + countyID : ""),
        customErrorHandlers
      });
    },
    /*
    Uses bearer token to authorize current user.
    Object containing user information is returned.
    */
    async checkUserAuth(
      apiToken,
      baseURL = undefined,
      customErrorHandlers = {}
    ) {
      const options = {
        url: "/auth/user",
        headers: {
          authorization: `bearer ${apiToken}`
        },
        customErrorHandlers
      };
      if (baseURL) {
        options["baseURL"] = baseURL;
      }
      return await axios(options);
    },

    async extendUserPermissions(data, userId, customErrorHandlers = {}) {
      return await axios({
        method: "POST",
        url: "/auth/userextendedpermissions?id=" + userId,
        data,
        customErrorHandlers
      });
    },

    async resetPassword(data, customErrorHandlers = {}) {
      return await axios({
        method: "POST",
        url: "/auth/password-reset",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns an array of objects containing the authorized users of the given workstation
    */
    async getAuthUsers(customErrorHandlers = {}) {
      return await axios({
        url: "/auth/users",
        customErrorHandlers
      });
    },
    /*
    Returns an array of offices at a given county for inventory
    */
    async getOffices(countyID = false, customErrorHandlers = {}) {
      return await axios({
        url:
          "/location/office/search" + (countyID ? "?countyid=" + countyID : ""),
        customErrorHandlers
      });
    },
    /*
    Returns an object whose keys/values are plate statuses tied to an alpha key
    */
    async getPlateStatuses(customErrorHandlers = {}) {
      return await axios({
        url: "/lookup/PlateStatus",
        customErrorHandlers
      });
    },
    /*
    Returns an object whose keys/values are inventory range statuses tied to an alpha key
    */
    async getInventoryRangeStatus(customErrorHandlers = {}) {
      return await axios({
        url: "/lookup/InventoryRangeStatus",
        customErrorHandlers
      });
    },
    /*
    Returns an object whose keys/values are county plate statuses tied to an alpha key
    */
    async getCountyPlateStatuses(customErrorHandlers = {}) {
      return await axios({
        url: "/lookup/countyuse/PlateStatus",
        customErrorHandlers
      });
    },
    /*
    Returns the current version of STAR from internal documents
    */
    async getVersion(customErrorHandlers = {}) {
      return await axios({
        url: "/static/version.txt",
        customErrorHandlers
      });
    },
    /*
    Returns the local configuration file
    */
    async getConfig(customErrorHandlers = {}) {
      return await axios({
        url: "./static/config.json",
        customErrorHandlers
      });
    },
    /*
    Requests a 'Ptran No' using unique transaction data.
    This number is used as a unique identifier for Uniterm transaction
    */
    async getPTranNo(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/ptranno",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns HMAC values used to configure Monetra payment frame
    */
    async getHMACValues(origin, customErrorHandlers = {}) {
      return await axios({
        url: "/star/hmac?domain=" + origin,
        customErrorHandlers
      });
    },
    /*
    Refreshes the user's token to keep their session from expiring
    */
    async refreshAuth(customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/auth/refresh",
        customErrorHandlers
      });
    },
    /*
    Requests a transaction based on transactionRoute and body
    */
    async transaction(transactionRoute, data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star" + transactionRoute,
        data,
        customErrorHandlers
      });
    },
    /*
    Allows for more detailed searches. Is used for the advanced search
    */
    async inquirySearch(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/inquiry/search",
        data,
        customErrorHandlers
      });
    },
    /*
    Activated when an undercover transaction is cancelled. Puts in a request to undo changes made.
    */
    async undercoverUndo(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/undercover/undo",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns transaction data for placard reprinting and history by placard number
    */
    async plateSearch(plate, newest, customErrorHandlers = {}) {
      return await axios({
        url:
          "/star/search?plate=" + plate + (newest ? "&newest=" + newest : ""),
        customErrorHandlers
      });
    },

    async archiveSearch(url, method, data = {}, customErrorHandlers = {}) {
      return await axios({
        method,
        url: "/star" + url,
        customErrorHandlers,
        data
      });
    },

    async simpleSearch(url, method, data, customErrorHandlers = {}) {
      return await axios({
        method,
        url,
        data,
        customErrorHandlers
      });
    },

    /*
    Returns transaction data for reprinting and history by vin number
    */
    async vinSearch(vin, newest = false, customErrorHandlers = {}) {
      return await axios({
        url: "/star/search?vin=" + vin + (newest ? "&newest=" + newest : ""),
        customErrorHandlers
      });
    },
    /*
    Returns past transactions for a given plate by plateNo, plateClass, and issueYear
    */
    async plateHistory(
      plateNo,
      plateClass,
      issueYear,
      customErrorHandlers = {}
    ) {
      return await axios({
        url:
          "/star/inquiry/platehistory/" +
          plateNo +
          "/" +
          plateClass +
          "/" +
          issueYear,
        customErrorHandlers
      });
    },
    /*
    Creates an association between a titleNo and a controlNo
    */
    async submitControlNo(titleNo, controlNo, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/tandr/controlno/" + titleNo + "/" + controlNo,
        customErrorHandlers
      });
    },
    /*
    Creates associations between ranges of titleNos and controlNos
    */
    async submitControlNoRange(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/tandr/controlno/range",
        data,
        customErrorHandlers
      });
    },
    /*
    Removes the association between a titleNo and a controlNo
    Only requires the titleNo to remove the association
    */
    async removeAssociation(titleNo, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/tandr/controlno/remove/" + titleNo,
        customErrorHandlers
      });
    },
    /*
    Returns controlNo by the titleNo it is currently associated with
    */
    async getAssociatedControlNo(titleNo, customErrorHandlers = {}) {
      return await axios({
        url: "/star/tandr/controlno/search?titleno=" + titleNo,
        customErrorHandlers
      });
    },
    /*
    Returns titleNo by the controlNo it is currently associated with
    */
    async getAssociatedTitleNo(controlNo, customErrorHandlers = {}) {
      return await axios({
        url: "/star/tandr/controlno/search?controlno=" + controlNo,
        customErrorHandlers
      });
    },
    /*
    Submits supporting documentation for transactions
    */
    async submitDocuments(data, placardNo = false, customErrorHandlers = {}) {
      await axios({
        method: "post",
        url:
          "/star/tandr/documents" +
          (placardNo ? "?placardNo=" + placardNo : ""),
        data,
        customErrorHandlers
      });
    },
    /*
    Returns array of EZ dealer transactions
    */
    async getEZTagTransactions(customErrorHandlers = {}) {
      return await axios({
        url: "/star/eztag",
        customErrorHandlers
      });
    },
    /*
    Rejects EZ dealer transaction with given reason
    */
    async ezTagReject(data, customErrorHandlers = {}) {
      await axios({
        method: "post",
        url: "/star/eztag/reject",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns batch of transactions when drilling down into EZ Dealer grid
    */
    async batchTransactionSearch(transactionID, customErrorHandlers = {}) {
      await axios({
        url: "/star/batch/transaction/search/?transactionID=" + transactionID,
        customErrorHandlers
      });
    },
    /*
    Should return an array of incomplete reject reasons. Doesn't seem to return anything atm
    */
    async incompleteRejectReason(customErrorHandlers = {}) {
      return await axios({
        url: "/lookup/IncompleteRejectReason",
        customErrorHandlers
      });
    },
    /*
    Returns fees for a standard transaction
    */
    async getTandRFees(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/tandr/fees",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns fees for an undercover transaction
    */
    async getUndercoverFees(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/undercover/fees",
        data,
        customErrorHandlers
      });
    },
    /*
    Adds the pending transaction to the queue
    */
    async queueAssign(transactionID, customErrorHandlers = {}) {
      await axios({
        url: "/star/queue/assign/" + transactionID,
        customErrorHandlers
      });
    },
    /*
    Removes the pending transaction from the queue
    */
    async queueUnAssign(transactionID, customErrorHandlers = {}) {
      await axios({
        url: "/star/queue/unassign/" + transactionID,
        customErrorHandlers
      });
    },
    /*
    Gets the title for the current vehicle. If we get a 404 we throw an error related to the
    missing title.
    */
    async titlePrintCheckGet(vin, customErrorHandlers = {}) {
      return await axios({
        url: "/star/titleprintcheck/title/print/" + vin,
        customErrorHandlers
      });
    },
    /*
    Searches for titles to print by titleNo, vin, or invoiceNo
    */
    async titlePrintCheckSearch(endpoint, customErrorHandlers = {}) {
      return await axios({
        url: "/star/" + endpoint,
        customErrorHandlers
      });
    },
    /*
    Returns title PDFs for a given vin or vins
    */
    async requestTitlePDFs(endpoint, data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star" + endpoint,
        data,
        customErrorHandlers
      });
    },
    /*
    Returns user congigured settings for dashboard layout.
    */
    async dashboardSettings(id, token, customErrorHandlers = {}) {
      const options = {
        url: "/kv/dashboardSettings/" + id,
        customErrorHandlers,
        logging: false
      };
      if (token) {
        options.headers = { authorization: `bearer ${token} ` };
      }
      return await axios(options);
    },
    async postDashboardSettings(data, id, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/kv/dashboardSettings/" + id,
        data,
        customErrorHandlers
      });
    },
    /*
    Returns info for a particular plate by plateNo
    */
    async subRangePlateSearch(plateNo, customErrorHandlers = {}) {
      return await axios({
        url: "/star/inventorysubrange/platesearch/" + plateNo,
        customErrorHandlers
      });
    },
    /*Returns Array of subrange Ids */
    async subRangeSearch(queryParams, customErrorHandlers = {}) {
      return await axios({
        url: "/star/inventorysubrange/search" + queryParams,
        customErrorHandlers
      });
    },
    /*
    Returns an array of available (unused) plates within a given subrange
    */
    async subRangeAvailability(subRangeID, customErrorHandlers = {}) {
      return await axios({
        url: "/star/inventorysubrange/availableplates/" + subRangeID,
        customErrorHandlers
      });
    },
    /*
    Returns a sequence of plates in a given range regardless of availability
    */
    async subRangeSequence(subRangeID, customErrorHandlers = {}) {
      return await axios({
        url: "/star/inventorysubrange/platesequence/" + subRangeID,
        customErrorHandlers
      });
    },
    /*
    Sends a request to change the current status of a range
    */
    async changeRangeStatus(data, customErrorHandlers = {}) {
      await axios({
        method: "post",
        url: "/star/inventorysubrange",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns information for a given plate by the plateNo
    */
    async inventoryPlateSearch(plateNo, customErrorHandlers = {}) {
      return await axios({
        url: "/star/inventory/plates/" + plateNo,
        customErrorHandlers
      });
    },
    /*
    Returns the next available plate assigned to the user within a given class
    */
    async nextPlate(selectedAutoAssignClass, customErrorHandlers = {}) {
      return await axios({
        url: "/star/inventory/nextplate/" + selectedAutoAssignClass,
        customErrorHandlers
      });
    },
    /*
    Sends a request to change the current status of a given plate within a range
    */
    async changePlateStatus(
      subRangeID,
      plate,
      newStatus,
      customErrorHandlers = {}
    ) {
      return await axios({
        method: "post",
        url:
          "/star/inventory/" +
          subRangeID +
          "/" +
          plate +
          "/status/" +
          newStatus,
        customErrorHandlers
      });
    },
    /*
    Used to send or receive reassigned inventory
    */
    async processInventoryEndpoint(endpoint, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star" + endpoint,
        customErrorHandlers
      });
    },
    /*
    Returns an array of inventory requests
    */
    async processInventoryGet(endpoint, customErrorHandlers = {}) {
      return await axios({
        url: "/star" + endpoint,
        customErrorHandlers
      });
    },
    /*
    Saves current inventory level for current office
    */
    async saveInventoryLevel(data, customErrorHandlers = {}) {
      await axios({
        method: "post",
        url: "/star/inventory/inventorylevel",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns current inventory level for current office
    */
    async getInventoryLevel(officeID, customErrorHandlers = {}) {
      return await axios({
        url: "/star/inventory/inventorylevels/office/" + officeID,
        customErrorHandlers
      });
    },
    /*
    Saves an inventory order by plateClass
    */
    async saveInventoryOrder(plateClass, data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/inventory/order/" + plateClass,
        data,
        customErrorHandlers
      });
    },
    /*
    Requests the validation schema for the forms.
    Tells us which fields are required and which are not.
    */
    async getValidationSchema(url, customErrorHandlers = {}) {
      return await axios({
        url,
        customErrorHandlers,
        logging: false
      });
    },
    /*
    Updates the statues of a given title
    Currently used within history to void a current title
    */
    async updateTitleStatus(data, customErrorHandlers = {}) {
      await axios({
        method: "post",
        url: "/star/tandr/title/status",
        data,
        customErrorHandlers
      });
    },
    /*
    Reverses a transaction given a VTRSTransactionID
    Does not reverse any payments made within Uniterm or otherwise.
    */
    async undoTransaction(
      VTRSTransactionID,
      data,
      isPlacard,
      customErrorHandlers = {}
    ) {
      await axios({
        method: "post",
        url:
          "/star/" +
          (isPlacard ? "placard" : "tandr") +
          "/undo/transaction/" +
          VTRSTransactionID,
        data,
        customErrorHandlers
      });
    },
    /*
    Gets an image by documentLocatorNo
    */
    async getImage(documentLocatorNo, customErrorHandlers = {}) {
      return await axios({
        url: "star/gallery/document/" + documentLocatorNo,
        headers: { Accept: "image/tiff" },
        customErrorHandlers
      });
    },

    async getExistingImage(supportingDocumentID, customErrorHandlers = {}) {
      return await axios({
        url: "imager/document/" + supportingDocumentID,
        customErrorHandlers
      });
    },
    /*
    Returns the credit card fees for a given county by the amount charged
    */
    async getCreditCardFees(
      countyID,
      amount,
      inHouse = true,
      customErrorHandlers = {}
    ) {
      return await axios({
        url:
          "/star/tandr/creditcardfees/" +
          countyID +
          "?inHouse=" +
          inHouse +
          "&amount=" +
          amount,
        customErrorHandlers
      });
    },
    /*
    Used to populated the missing images list. 
    Returns an array of missing documents by invoiceNo
    */
    async missingDocuments(invoiceNo, customErrorHandlers = {}) {
      return await axios({
        url: "/missingdocuments/" + invoiceNo,
        customErrorHandlers
      });
    },
    /*
    Returns the monetraUser by the current api user
    */
    async monetraUser(data = {}, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/monetrauser",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns a list of vehicle makes
    */
    async getMakeList(make, customErrorHandlers = {}) {
      return await axios({
        url: "/star/makelist/" + make,
        customErrorHandlers
      });
    },
    /*
    Returns dealer plate fees by the transObj
    */
    async getDealerPlateFees(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/dealerplates/fees",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns dealer info by MVCNo
    */
    async getDealer(MVCNo, customErrorHandlers = {}) {
      return await axios({
        url: "/star/dealer?mvcno=" + MVCNo,
        customErrorHandlers
      });
    },
    /*
    Makes a request to save user configured theme settings to KV configurations
    */
    async saveThemeSettings(userID, data, customErrorHandlers = {}) {
      await axios({
        method: "post",
        url: "/kv/themeSettings/" + userID,
        data,
        customErrorHandlers
      });
    },
    /*
    Returns user configured theme settings from kv configurations
    */
    async getThemeSettings(userID, customErrorHandlers = {}, token) {
      const options = {
        url: "/kv/themeSettings/" + userID,
        customErrorHandlers,
        logging: false
      };
      if (token) {
        options.headers = { authorization: `bearer ${token}` };
      }
      return await axios(options);
    },
    /*
    Returns default zero fees from kv configurations for the county
    */
    async getDefaultZeroFees(customErrorHandlers = {}) {
      return await axios({
        url: "/star/kv/countyfees/defaultzerofees",
        customErrorHandlers
      });
    },
    /*
    Requests to update default zero fees to kv configuration for the county
    */
    async updateDefaultZeroFees(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/kv/countyfees/defaultzerofees",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns fee maintenance options for the given county
    */
    async getFeeMaintenanceOptions(countyID, customErrorHandlers = {}) {
      return await axios({
        url: "/star/feemaintenance/fee/options/" + countyID,
        customErrorHandlers
      });
    },
    /*
    Makes a request to update given fee behavior rule
    */
    async updateFeeMaintenanceBehaviorRule(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/feemaintenance/feebehaviorrule",
        data,
        customErrorHandlers
      });
    },
    /*
    Makes a request to update a given fee
    */
    async updateFee(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/feemaintenance/fee",
        data,
        customErrorHandlers
      });
    },
    /*
    Returns deficitient transaction by deficientID and countyID
    */
    async getDeficient(deficientID, countyID, customErrorHandlers = {}) {
      return await axios({
        url: "star/deficient/" + deficientID + "?countyID=" + countyID,
        customErrorHandlers
      });
    },
    /*
    Returns all deficient transactioncs for a given county by countyID
    */
    async getCountyDeficients(countyID, customErrorHandlers = {}) {
      return await axios({
        url: "/star/deficients?countyID=" + countyID,
        customErrorHandlers
      });
    },
    /*
    Returns a lien by given lienID
    */
    async searchLienByID(id, customErrorHandlers = {}) {
      return await axios({
        url: "/star/lienholder/" + id,
        customErrorHandlers
      });
    },
    /*
    Returns lienholders by given countyID and customer name
    */
    async searchLienHolders(countyID, customerName, customErrorHandlers = {}) {
      return await axios({
        url: "star/lienholder/list/" + countyID + "/" + customerName,
        customErrorHandlers
      });
    },
    /*
    Makes a request to update lienholders for a given customer
    */
    async saveLienholder(data, customErrorHandlers = {}) {
      return await axios({
        method: "post",
        url: "/star/lienholder",
        data,
        customErrorHandlers
      });
    },
    async reportList(customErrorHandlers = {}) {
      return await axios({
        method: "GET",
        url: "report/list",
        customErrorHandlers
      });
    },

    async runReport(selectedReport, data, customErrorHandlers = {}) {
      return await axios({
        method: "POST",
        url: "/report/" + selectedReport,
        data,
        customErrorHandlers
      });
    },
    /*
    A generic search query that returns results by given parameters
    */
    async search(route, customErrorHandlers = {}, method = "GET") {
      return await axios({
        method,
        url: "/star" + route,
        customErrorHandlers
      });
    },
    /*
    During star initialization, we have a number of general requests we have to make.
    This allows us to batch those requests and run them from a single function.
    */
    async initialize(endpoint, token, customErrorHandlers = {}) {
      return await axios({
        url: endpoint,
        headers: {
          authorization: `bearer ${token}`
        },
        customErrorHandlers,
        logging: false
      });
    }
  };
}
