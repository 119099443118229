<template>
  <div id="prevTrans">
    <div class="header">
      <h2>
        <v-icon color="primary" size="48px">mdi-history</v-icon>
        Previous Transactions
      </h2>
    </div>
    <div class="table">
      <prevTransactions :results="prevTrans" />
    </div>
  </div>
</template>

<script>
import prevTransactions from "@/components/nonPageComponents/previousTransactionsTable";
export default {
  data() {
    return {
      limit: 10,
      prevTrans: null
    };
  },
  components: {
    prevTransactions
  },
  async mounted() {
    this.prevTrans = await this.$api.prevTransactions(this.limit);
    Object.keys(this.prevTrans).forEach(key => {
      const transactionJSON = JSON.parse(this.prevTrans[key].transactionJSON);
      const vehicleObj = transactionJSON.vehicle;
      if (this._.has(vehicleObj, "newVIN")) {
        this.prevTrans[key].vin = vehicleObj.newVIN;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
$header-height: 45px;
#prevTrans {
  height: calc(100% - #{$header-height});
}

.header {
  height: $header-height;
}

.table {
  height: 100%;
}
.bootstrap {
  height: calc(100% - 15px);
}

::v-deep .bootstrap .b-table-sticky-header {
  max-height: 100%;
}
</style>
