import address from "./address";

export default function () {
  return {
    customerID: 0,
    customerType: "",
    mailingAddressID: null,
    physicalAddressID: 0,
    customerName: null,
    customerEMail: null,
    customerPhone: null,
    customerPhoneExt: null,
    customerStatusCode: "",
    customerStatusDate: null,
    contactName: null,
    contactPhone: null,
    contactPhoneExt: null,
    massMailInd: false,
    emailRenewalInd: 0,
    deafInd: 0,
    deafSpecNeeds: false,
    hearingSpecNeeds: false,
    intellectualSpecNeeds: false,
    developmentalSpecNeeds: false,
    medicalSpecNeeds: false,
    checkReturnCount: null,
    lastUpdateUserID: 0,
    individualCustomer: {},
    businessCustomer: null,
    mailingAddress: address(),
    physicalAddress: address(),
    ownershipRightCode: null,
    mailToInd: false,
    lesseeInd: null,
    inCareOf: null,
    stops: null
  };
}
