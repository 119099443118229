import vehicle from "./vehicle";
import transaction from "./transaction";
import title from "./title";
import registration from "./registration";
import ownership from "./ownership";
import customer from "./customer";
import lien from "./lien";
import countyData from "./countyData";

export default function () {
  return {
    vehicle: vehicle(),
    registration: registration(),
    title: title(),
    transaction: transaction(),
    owners: [customer()],
    liens: [lien()],
    lessor: customer(),
    ownership: ownership(),
    countyData: countyData()
  };
}
